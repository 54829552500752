import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChangePassword } from "../ChangePassword";
import { AccountCircle } from "@mui/icons-material";
import { Role } from "../interfaces/enums";

const NavBar = () => {
  const { logout } = useAuthContext();
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");
  const [modalPasswordOpen, setModalPasswordOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [formData, updateFormData] = React.useState({
    newPassword: "",
    repeatPassword: ""
  });

  const closeModal = () => {
    setModalPasswordOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeText = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [formData]);

  const renderNavbarContent = React.useCallback(() => {
    return user
      ? <Box>
            <Stack direction={"row"}>
              {
                user.roles?.includes(Role.ROLE_ADMIN)
                  ? <Box>
                      <Button variant="contained" color={"primary"} sx={{ m: 2 }}
                              onClick={() =>
                                history.push("/invoice")}>
                        {t("button.invoice")}
                      </Button>
                    </Box>
                  : <></>
              }
              {
                user.employeeId
                  ? <Box>
                      <Button variant="contained" color={"primary"} sx={{ m: 2 }}
                              onClick={() =>
                                history.push("/timetracking")}>
                        {t("button.timeTracking")}
                      </Button>
                    </Box>
                  : <></>
              }
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title={t("tooltip.openSettings")}>
                  <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                  >
                    {user.personImage !== undefined
                      ? <Avatar src={`data:image/png;base64,${user.personImage}`} sx={{ width: 40, height: 40 }}/>
                      : <AccountCircle/>
                    }
                  </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                  <MenuItem>
                    <b>{user.username}</b>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    setModalPasswordOpen(true);
                  }}>
                    {t("button.changePassword")}
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    {t("button.logOut")}
                  </MenuItem>
                </Menu>
              </Box>
              <ChangePassword close={closeModal} show={modalPasswordOpen}/>
          </Stack>
        </Box>
      : <></>;
  }, [user, modalPasswordOpen, handleChangeText, anchorEl]);

  return renderNavbarContent();
};
export default NavBar;
