import React from "react";
import axios from "axios";
import { Box, Button, CircularProgress, FormControlLabel, Stack, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserDto } from "./types";
import { listAllUsers } from "../../service/upsertAdminServices";
import { keyById } from "../../utils/mapUtils";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { UpdateAllowanceDays } from "./components/UpdateAllowanceDays";
import { emptyTableCell } from "../../utils/tableUtils";
import { isoToDisplayDateTime } from "../../utils/formatUtils";
import { resendEmail } from "../Persons/services/PersonServices";
import DoneIconComponent from "../../components/StyleComponents/DoneIconComponent";
import classNames from "classnames";

const UsersPage: React.FC = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
  const { t } = useTranslation("company-manager");
  const [userMap, setUserMap] = React.useState<Map<number, UserDto>>(new Map());
  const [userIdToUpdateAllowanceDays, setUserIdToUpdateAllowanceDays] = React.useState<number | undefined>();
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    loadAllUsers(false);
  }, []);

  const loadAllUsers = React.useCallback((includeInactive: boolean) => {
    setDataLoaded(false);
    listAllUsers(includeInactive, cancelTokenSource)
      .then((data: Array<UserDto>) => {
        const userMap1 = keyById<number, UserDto>(data);
        setUserMap(userMap1);
      })
      .finally(() => setDataLoaded(true));
  }, [cancelTokenSource]);

  const handleIncludeInactive = (event: any, checked: boolean) => {
    setIncludeInactive(checked);
    loadAllUsers(checked);
  };

  const onUpdateAllowanceDaysSuccess = React.useCallback((newAllowanceDays: number) => {
    if (userIdToUpdateAllowanceDays) {
      const modifiedUserMap = new Map(userMap);

      const userDto = modifiedUserMap.get(userIdToUpdateAllowanceDays);
      if (userDto) {
        userDto.maxAllowedDaysForHoliday = newAllowanceDays;
        modifiedUserMap.set(userDto.id, userDto);
      }

      setUserMap(modifiedUserMap);
      setUserIdToUpdateAllowanceDays(undefined);
    }
  }, [userIdToUpdateAllowanceDays]);

  const users = Array.from(userMap.values());
  const renderUserTable = (): JSX.Element => {
    const usersRowGetter = ({ index }: any) => {
      Object.assign(users[index], { index: index + 1 });

      return users[index];
    };

    const usersRowRenderer = ({ className, columns, index, key, style, rowData }: any) => {
      const isInactive = rowData.inactive === true;
      const rowClasses = classNames(className, {
        inactiveRow: isInactive
      });
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
              <div
                  {...a11yProps}
                  className={rowClasses}
                  key={key}
                  role='row'
                  style={style}
              >
                  {columns}
              </div>
      );
    };

    const statusCellRenderer = ({ cellData }: any) => {
      if (!cellData) {
        return <DoneIconComponent/>;
      }
      return emptyTableCell();
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const lastLoginDateTimeCellRenderer = ({ rowData }: any) => {
      if (rowData) {
        return <div>{isoToDisplayDateTime(rowData.lastLogin)}</div>;
      }
    };

    const resendLoginInvitation = (rowData: any) => {
      resendEmail(rowData.id, cancelTokenSource).then(() => loadAllUsers(includeInactive));
    };

    const actions = ({ rowData }: any) => {
      return (
          <Box display={"flex"} alignItems={"center"}>
              <Button
                  sx={{ m: 1 }}
                  variant={"outlined"}
                  onClick={() => resendLoginInvitation(rowData)}
                  disabled={rowData.lastLogin}>
                  {t("common.resendLoginInvitation")}
              </Button>
          </Box>
      );
    };

    return (
          <VirtualizedTable
              rowCount={users.length}
              rowGetter={usersRowGetter}
              rowRenderer={usersRowRenderer}
              columns={[
                {
                  width: 75,
                  label: "#",
                  dataKey: "index"
                },
                {
                  width: 200,
                  flexGrow: 1,
                  label: t("common.name"),
                  dataKey: "name"
                },
                {
                  width: 200,
                  flexGrow: 1,
                  label: t("common.surname"),
                  dataKey: "surname"
                },
                {
                  width: 300,
                  flexGrow: 1,
                  label: t("common.userName"),
                  dataKey: "username"
                },
                {
                  width: 200,
                  flexGrow: 1,
                  label: t("common.role"),
                  dataKey: "roles"
                },
                {
                  width: 100,
                  flexGrow: 1,
                  label: t("common.active"),
                  dataKey: "inactive",
                  cellRenderer: statusCellRenderer
                },
                {
                  width: 150,
                  flexGrow: 1,
                  label: t("tableHeader.allowedDays"),
                  dataKey: "maxAllowedDaysForHoliday"
                },
                {
                  width: 200,
                  label: t("tableHeader.lastLogin"),
                  dataKey: "lastLogin",
                  flexGrow: 1,
                  cellRenderer: (lastLoginDateTimeCellRenderer)
                },
                {
                  width: 130,
                  label: t("common.actions"),
                  dataKey: "id",
                  cellRenderer: (actions)
                }
              ]}
          />
    );
  };

  const rowHeight = 48;
  const tableHeight = (users.length * rowHeight) + 25;

  return (
        <Box>
            {dataLoaded
              ? <>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
            <h3><i>{t("common.users")}</i></h3>
              <FormControlLabel
                control={<Switch onChange={(e, check) => handleIncludeInactive(e, check)} checked={includeInactive}/>}
                label={t("common.inactive")} />
                </Stack>
            <Box style={{ height: tableHeight }}>
            {renderUserTable()}
            </Box>

            <UpdateAllowanceDays
                userId={userIdToUpdateAllowanceDays}
                oldAllowanceDays={userIdToUpdateAllowanceDays ? userMap.get(userIdToUpdateAllowanceDays)?.maxAllowedDaysForHoliday : undefined}
                onUpdateAllowanceDaysSuccess={onUpdateAllowanceDaysSuccess}
                onClose={() => setUserIdToUpdateAllowanceDays(undefined)}
            />
                </>
              : <CircularProgress />
            }
        </Box>
  );
};

export default UsersPage;
