import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { CompensateOvertimeDto, CreditOvertimeDto, UpsertOvertimeDto } from "../types";
import { Trimester } from "../../../components/interfaces/enums";

const baseUrl = "/company-manager-app/api/overtime";

export const getOvertime = (id: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getOvertimesForYear = (source: CancelTokenSource, employeeId: number, year: number) => {
  return axios
    .get(`${baseUrl}/all/by-year`, { cancelToken: source.token, params: { employeeId, year } })
    .then(response => response.data);
};

export const getOvertimesToCompensate = (source: CancelTokenSource, trimester: Trimester, year: number) => {
  return axios
    .get(`${baseUrl}/all/for-use`, { cancelToken: source.token, params: { trimester, year } })
    .then(response => response.data);
};

export const getOvertimesToCredit = (source: CancelTokenSource, trimester: Trimester, year: number) => {
  return axios
    .get(`${baseUrl}/all/for-credit`, { cancelToken: source.token, params: { trimester, year } })
    .then(response => response.data);
};

export const forwardOvertimes = (source: CancelTokenSource, year: number) => {
  return axios
    .get(`${baseUrl}/forward`, { cancelToken: source.token, params: { year } })
    .then(response => response.data);
};

export const saveCreditOvertimes = (source: CancelTokenSource, creditOvertimeDtos : Array<CreditOvertimeDto>) => {
  return axios
    .post(`${baseUrl}/add/credit`, creditOvertimeDtos, { cancelToken: source.token })
    .then(response => response.data);
};

export const saveCompensateOvertimes = (source: CancelTokenSource, compensateOvertimeDtos : Array<CompensateOvertimeDto>) => {
  return axios
    .post(`${baseUrl}/add/compensate`, compensateOvertimeDtos, { cancelToken: source.token })
    .then(response => response.data);
};

export const addOvertime = (overtimeDto: Partial<UpsertOvertimeDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, overtimeDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getThisTrimestarHours = (employeeId: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/this-trimestar-working-hours`, { cancelToken: source.token, params: { employeeId } })
    .then(response => response.data);
};
