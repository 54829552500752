import React, { useCallback } from "react";
import axios, { AxiosError } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { LoginUserDto, UserDto } from "../pages/Users/types";
import { AUTHORIZATION_KEY, getAuthToken, removeAuthToken, setAuthToken } from "../pages/Login/services/loginServices";
import { getUserData, loginUser, logOutUser } from "../pages/Users/services/userServices";
import { LoginDto } from "../components/interfaces/interface";
import * as Sentry from "@sentry/react";
import { setLanguage } from "../service/browserStorageServices";
import moment from "moment";
import i18n from "i18next";
import client from "../custom-axios/axios";

type LoginFunction = (login: LoginDto) => Promise<void>;

interface State {
  user?: Partial<LoginUserDto>,
  login: LoginFunction,
  logout: () => void,
  isAuthenticated: boolean,
}

type Props = JSX.ElementChildrenAttribute

const AuthContext = React.createContext<State>({
  login: async () => undefined,
  logout: () => undefined,
  isAuthenticated: false
});

export const AuthProvider = (props: Props) => {
  const authToken = React.useMemo(() => getAuthToken(), []);
  const history = useHistory();
  const location = useLocation();
  const cancelTokenSource = React.useMemo(axios.CancelToken.source, []);
  const publicRoutes = ["/login", "/forget-password", "/reset-password"];

  const [user, setUser] = React.useState<Partial<LoginUserDto> | undefined>();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(!!authToken);

  React.useEffect(() => {
    const pathname = location.pathname;
    if (authToken === null || authToken === undefined) {
      if (pathname !== "" && !publicRoutes.includes(location.pathname)) {
        history.push("/login");
      }
      return;
    } else if (pathname === "/") {
      if (user?.employeeId != null) {
        history.push("/timetracking");
      } else {
        history.push("/holiday");
      }
    }
    getUserData(cancelTokenSource)
      .then((data: Partial<UserDto>) => {
        setUser(data);
        Sentry.setUser({
          email: data.username
        });
      })
      .catch(() => {
        removeAuthToken();
        const pathname = location.pathname;
        if (pathname !== "" && pathname !== "/login") {
          history.push("/login");
        }
      });
  }, []);

  const updateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [setLanguage]);

  const login: LoginFunction = React.useCallback((login: LoginDto): Promise<void> => {
    return loginUser(login, cancelTokenSource)
      .then((response: any) => {
        const token = response.headers[AUTHORIZATION_KEY];
        setAuthToken(token);

        getUserData(cancelTokenSource).then((data) => {
          setUser(data);
          updateLanguage("en");
          if (!(data.disabled)) {
            setIsAuthenticated(true);
            if (data?.employeeId != null) {
              history.push("/timetracking");
            } else {
              history.push("/holiday");
            }
          } else {
            removeAuthToken();
            const pathname = location.pathname;
            if (pathname !== "" && pathname !== "/login") {
              history.push("/login");
            }
          }
        });
      });
  }, [cancelTokenSource, history]);

  const sessionExpiredHandler = (error: AxiosError) => {
    const { response } = error;

    if (response && response.status === 401) {
      removeAuthToken();
      history.push("/login");
    }

    return Promise.reject(error);
  };

  client.interceptors.response.use(response => response, sessionExpiredHandler);

  const logout = React.useCallback(() => {
    logOutUser(cancelTokenSource)
      .then(() => {
        removeAuthToken();
        setIsAuthenticated(false);
        Sentry.setUser(null);
        history.push("/login");
      });
  }, [cancelTokenSource, setIsAuthenticated]);

  React.useEffect(() => {
    if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
      history.push("/login");
    }
  }, [isAuthenticated, history]);

  const state = React.useMemo(() => {
    return {
      user,
      setUser,
      logout,
      login,
      isAuthenticated,
      setIsAuthenticated
    };
  }, [
    user, setUser,
    logout, login,
    isAuthenticated, setIsAuthenticated
  ]);

  return (
    <AuthContext.Provider value={state}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
