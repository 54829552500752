import React, { FC } from "react";

import { approve, decline } from "../../../service/upsertAdminServices";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { HolidayStatus, Role } from "../../../components/interfaces/enums";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ApproveDeclineHolidaysProps } from "../../../components/interfaces/interface";
import { Index } from "react-virtualized";
import { emptyTableCell } from "../../../utils/tableUtils";
import { useAuthContext } from "../../../context/AuthContext";
import { approveByTeamLeader, declineByTeamLeader } from "../services/HolidayServices";
import { Undefinable } from "../../../types";

const ApproveDeclineHolidays: FC<ApproveDeclineHolidaysProps> = (props): JSX.Element => {
  const { user } = useAuthContext();
  const [declineReasonModal, setDeclineReasonModal] = React.useState(false);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [status, setStatus] = React.useState <HolidayStatus>(HolidayStatus.WAITING_ON_APPROVAL);
  const [reasonForDeclining, setReasonForDeclining] = React.useState<string>("");
  const [idToDecline, setIdToDecline] = React.useState<Undefinable<number>>(undefined);
  const { t } = useTranslation("company-manager");

  const onDecline = React.useCallback(() => {
    if (user?.roles?.includes(Role.ROLE_ADMIN)) {
      decline(idToDecline, reasonForDeclining, cancelTokenSource)
        .then(() => declineActions());
    } else {
      declineByTeamLeader(idToDecline, reasonForDeclining, cancelTokenSource)
        .then(() => declineActions());
    }
  }, [idToDecline, reasonForDeclining, cancelTokenSource]);

  const onApprove = React.useCallback((id: number) => {
    if (user?.roles?.includes(Role.ROLE_ADMIN)) {
      approve(id, cancelTokenSource).then(() => props.loadHolidays(status));
    } else {
      approveByTeamLeader(id, cancelTokenSource).then(() => props.loadHolidays(status));
    }
  }, []);

  const declineActions = () => {
    setReasonForDeclining("");
    setIdToDecline(0);
    setDeclineReasonModal(false);
    props.loadHolidays(status);
  };
  const handleChangeSelectButtonHoliday = ({ target: { value } }: SelectChangeEvent) => {
    setStatus(value as HolidayStatus);
    props.loadHolidays(value as HolidayStatus);
  };
  const renderHolidaysTable = (): JSX.Element => {
    const ApproveDecline = ({ rowData }: any) => {
      return (
        <Box width={100}>
          <Button sx={{ mx: 1 }}
                  type="button"
                  variant={"contained"}
                  disabled={rowData.status === "APPROVED" ||
                    rowData.status === "DECLINED" || rowData.status === "PAST_HOLIDAY"}
                  endIcon={<CheckIcon/>}
                  color={"success"}
                  onClick={() => onApprove(rowData.id)}
          >
            {t("button.approve")}
          </Button>
          <Button sx={{ mx: 1 }}
                  type="button"
                  variant={"contained"}
                  disabled={rowData.status === "APPROVED" ||
                    rowData.status === "DECLINED" || rowData.status === "PAST_HOLIDAY"}
                  endIcon={<CloseIcon/>}
                  color={"error"}
                  onClick={() => {
                    setIdToDecline(rowData.id);
                    setDeclineReasonModal(true);
                  }}
          >
            {t("button.decline")}
          </Button>
        </Box>
      );
    };
    const translatedValues = ({ rowData }: any) => {
      return t("option." + rowData.status);
    };

    const holidaysRowGetter = ({ index }: Index) => {
      if (props.holidays) {
        Object.assign(props.holidays[index], { index: index + 1 });

        return props.holidays[index];
      }
      return emptyTableCell();
    };

    const holidaysRowRenderer = ({
      className,
      columns,
      index,
      key,
      style
    }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };
      if (props.holidays !== undefined && props.holidays[index].id === props.holidayRequestId) {
        return (
          <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={{ ...style, backgroundColor: "#e0f7e2" }}
          >
            {columns}
          </div>
        );
      } else {
        return (
          <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
          >
            {columns}
          </div>
        );
      }
    };
    return (
      <VirtualizedTable
        rowCount={props.holidays ? props.holidays.length : 0}
        rowGetter={holidaysRowGetter}
        rowRenderer={holidaysRowRenderer}
        columns={[
          {
            width: 50,
            label: "#",
            dataKey: "index"
          },
          {
            width: 200,
            label: t("tableHeader.requestedBy"),
            dataKey: "shortNameRequestedBy"
          },
          {
            width: 200,
            label: t("tableHeader.requestedOn"),
            dataKey: "requestedOn"
          },
          {
            width: 200,
            label: t("tableHeader.startingDate"),
            dataKey: "startingDate"
          },
          {
            width: 100,
            label: t("common.days"),
            dataKey: "days"
          },
          {
            width: 250,
            label: t("tableHeader.approvedBy"),
            dataKey: "shortNameApprovedBy"
          },
          {
            width: 250,
            label: t("tableHeader.approvedOn"),
            dataKey: "approvedOn"
          },
          {
            width: 200,
            label: t("tableHeader.type"),
            dataKey: "type"
          },
          {
            width: 200,
            flexGrow: 1,
            label: t("tableHeader.status"),
            dataKey: "status",
            cellRenderer: (translatedValues)
          },
          {
            width: 300,
            label: t("tableHeader.approveDecline"),
            dataKey: "id",
            cellRenderer: (ApproveDecline)
          }
        ]}
      />
    );
  };
  return (
    <Box>
      <h3><i>{t("header.holidaysThatAreWaitingOnApproval")}</i></h3>
      <form>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{
          mx: 3,
          my: 4
        }}>
          <Stack direction={"row"}>
            <FormControl>
              <InputLabel sx={{ alignSelf: "center" }}>Holiday Status</InputLabel>
              <Select id="status"
                      name="status"
                      value={status}
                      size={"small"}
                      label="HolidayStatus"
                      onChange={handleChangeSelectButtonHoliday}>

                <MenuItem value={HolidayStatus.WAITING_ON_APPROVAL}>

                  Waiting on approval
                </MenuItem>

                <MenuItem value={HolidayStatus.APPROVED}>

                  Approved
                </MenuItem>

                <MenuItem value={HolidayStatus.DECLINED}>

                  Declined
                </MenuItem>

                <MenuItem value={HolidayStatus.PAST_HOLIDAY}>

                  Past Holiday
                </MenuItem>

              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </form>
      <Box height={props.holidays ? props.holidays.length * 48 + 50 : 0}>
        {renderHolidaysTable()}
      </Box>
      <Dialog open={declineReasonModal}>
        <DialogTitle>{t("header.enterAReasonForHolidayDeclining")}
          <IconButton
            aria-label="close"
            onClick={() => setDeclineReasonModal(false)}
            sx={{
              position: "inherit",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Stack>
          <Stack>
            <TextField
              sx={{ m: 1 }}
              size={"small"}
              type="text"
              id="reasonForDeclining"
              name="reasonForDeclining"
              label={t("header.enterAReasonForHolidayDeclining")}
              required
              onChange={({ target: { value } }) => setReasonForDeclining(value)}
            />
          </Stack>
          <Stack direction={"row"} sx={{ m: 1 }} justifyContent={"center"}>
            <Button type="button"
                    sx={{ m: 1 }}
                    onClick={() => onDecline()}
            >
              {t("common.save")}
            </Button>
            <Button type="button"
                    sx={{ m: 1 }}
                    data-dismiss="modal"
                    onClick={() => setDeclineReasonModal(false)}
            >
              {t("common.close")}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Box>
  );
};
export default ApproveDeclineHolidays;
