import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { CompanyBankDto, CompanyBankTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/companybank";

export const addCompanyBank = (companyBankDto: Partial<CompanyBankDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, companyBankDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllCompanyBanks = (companyId: number, source: CancelTokenSource): Promise<Array<CompanyBankTableDto>> => {
  return axios
    .get(`${baseUrl}/all/${companyId}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getCompanyBank = (id: number, source: CancelTokenSource): Promise<CompanyBankDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editCompanyBank = (companyBankDto: Partial<CompanyBankDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, companyBankDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteCompanyBank = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
