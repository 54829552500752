import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { Alert, Box, Button, Collapse, Dialog, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { edit, getHolidayById } from "../services/HolidayServices";
import { HolidayDto, UpsertHolidayDto } from "../../../ts-types/api.types";
import { errorUtils } from "../../../utils/errorUtils";
import { useTranslation } from "react-i18next";

export interface Props {
  id?: number,
  onSave: (holidayDto: HolidayDto) => void,
  show: boolean,
  close: () => void
}
const EditHoliday = (props: Props) => {
  const cancelTokenSource = React.useMemo(axios.CancelToken.source, []);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation("company-manager");
  const [formData, updateFormData] = React.useState({
    id: props.id,
    reasonForDeclining: "",
    status: "WAITING_ON_APPROVAL",
    startingDate: moment().format("YYYY-MM-DD"),
    endingDate: moment().format("YYYY-MM-DD"),
    days: 0,
    type: "",
    requestedOn: "",
    approvedOn: "",
    requestedByEmployeeId: 0,
    approvedByEmployeeId: 0,
    shortNameRequestedBy: "",
    shortNameApprovedBy: ""
  });

  const handleChangeStartingDate = (newValue: null) => {
    updateFormData({ ...formData, startingDate: moment(newValue).format("YYYY-MM-DD") });
  };

  React.useEffect(() => {
    if (props.id) {
      getUpsertHoliday();
    }
  }, [props.id]);

  const getUpsertHoliday = React.useCallback(async () => {
    if (props.id) {
      getHolidayById(props.id, cancelTokenSource)
        .then((data: UpsertHolidayDto) => {
          updateFormData({
            id: props.id,
            startingDate: moment(data.startingDate).format("YYYY-MM-DD"),
            endingDate: moment(data.startingDate).format("YYYY-MM-DD"),
            days: data.days,
            type: data.type,
            requestedOn: data.requestedOn,
            approvedOn: data.approvedOn,
            requestedByEmployeeId: data.requestedByEmployeeId,
            approvedByEmployeeId: data.approvedByEmployeeId,
            shortNameRequestedBy: data.shortNameRequestedBy,
            shortNameApprovedBy: data.shortNameApprovedBy,
            reasonForDeclining: "",
            status: ""
          });
        });
    }
  }, [props.id]);

  const onFormSubmit = React.useCallback((e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.id) {
      edit(formData.id, formData, cancelTokenSource).then(r => { props.onSave(r); }).catch((e) => handleError(e.response.data));
    }
  }, [formData]);

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [updateFormData, formData]);

  const handleError = (error: any) => {
    if (error) {
      let errorMessage;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.NO_DAYS_LEFT, errorUtils.ENDING_DATE_IS_BEFORE_STARTING_DATE,
        errorUtils.HOLIDAYS_ARE_OVERLAPPED, errorUtils.SOME_DAYS_LEFT, errorUtils.ALREADY_DECLINED_HOLIDAY];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errorMessage = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errorMessage) {
        if (knownErrors.includes(errorCode)) {
          errorMessage = t(`error.${errorCode}`);
        } else {
          errorMessage = t("error.general");
        }
      }

      setErrorMessage(errorMessage);
    }
  };

  return (
      <Dialog
          open={props.show}
      >
          <DialogTitle>
              Edit holiday
                  <IconButton
                      aria-label="close"
                      onClick={props.close}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                      }}
                  >
                      <CloseIcon />
                  </IconButton>
          </DialogTitle>
          <Collapse in={!!errorMessage}>
              <Alert
                  severity={"error"}
                  action={
                      <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setErrorMessage(undefined);
                          }}
                      >
                          <CloseIcon fontSize="inherit" />
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
              >
                  {errorMessage}
              </Alert>
          </Collapse>
          <Box sx={{ p: 2, minWidth: 220 }}>
                <form onSubmit={onFormSubmit}>
                    <Stack direction={"column"}>
                        <Stack direction={"row"}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack direction={"column"} sx={{ mx: 1 }}>
                                    <DesktopDatePicker
                                        label="Starting date"
                                        inputFormat="DD/MM/YYYY"
                                        value={formData.startingDate}
                                        onChange={handleChangeStartingDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                                <Stack direction={"column"} sx={{ mx: 1 }}>
                                    <TextField
                                        sx={{ m: 1 }}
                                        size={"small"}
                                        type="number"
                                        value={formData.days}
                                        variant="outlined"
                                        id="days"
                                        name="days"
                                        label="Enter number of requested vacation days"
                                        required
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Stack>
                        <TextField sx={{ m: 1 }}
                                    size={"small"}
                                    type="text"
                                    variant="outlined"
                                    id="type"
                                    name="type"
                                    label="Enter type of holiday that you require"
                                    value={formData.type}
                                    required
                                    onChange={handleChange}
                        />
                        <Stack direction={"row"} justifyContent={"center"} sx={{ m: 1 }}>
                    <Button type="submit" sx={{ m: 1 }}>
                        Submit
                    </Button>
                    <Button sx={{ m: 1 }} onClick={props.close}>Back</Button>
                        </Stack>
                    </Stack>
                </form>
              </Box>
      </Dialog>
  );
};

export default EditHoliday;
