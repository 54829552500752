import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";

if (process.env.REACT_APP_USE_SENTRY && Number(process.env.REACT_APP_USE_SENTRY) === 1) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory())
      })
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_RATE)
  });
}
