import i18n from "i18next";
import XhrBackend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { getLanguage, setLanguage } from "./service/browserStorageServices";
import { supportedLanguages } from "./utils/localizationUtils";
import { loadLocaleResources } from "./pages/Users/services/userServices";
import { isDevEnv } from "./utils/appUtils";

// custom prefix added to the URL of private locale resources to mark them as private
const privatePrefix = "##priv##";

// Determine the default language
let defaultLanguage;
// prio 1: get the language from the URL params
const getUrlLang = (): string|undefined => {
  const urlQuery = window.location.search;
  if (urlQuery) {
    const urlParams = new URLSearchParams(urlQuery);
    const lang = urlParams.get("lang");

    if (lang && supportedLanguages.includes(lang)) {
      return lang;
    }
  }

  return undefined;
};
const urlLang = getUrlLang();
if (urlLang) {
  defaultLanguage = urlLang;
}

// prio 2.1: get the language from local storage, or
// prio 2.2: from the browser language
if (!defaultLanguage) {
  defaultLanguage = getLanguage();
}

// persist the language in the local storage
setLanguage(defaultLanguage);

i18n
  .use(XhrBackend)
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,

    fallbackLng: defaultLanguage,

    backend: {
    // for all available options read the backend's repository readme file
      loadPath: function (lngs: string[], namespaces: string[]) {
        if (lngs.length > 1 || namespaces.length > 1) {
          throw new Error("Multi-loading i18 resources not supported.");
        }

        const lang = lngs[0];
        const ns = namespaces[0];

        if (ns === "login") {
          return `${ns}-${lang}.json`;
        }

        return `${privatePrefix}${ns}-${lang}.json`;
      },

      parse: function (data: any) {
      // axios return a parsed object; no need to parse it manually
        return data;
      },

      ajax: function (urlStr: string, options: any, callback: any) {
        const privateResource = urlStr.startsWith(privatePrefix);
        const url = privateResource ? urlStr.substr(privatePrefix.length) : urlStr;
        loadLocaleResources(url, privateResource)
          .then((response: any) => {
            callback && callback(response.data, response);
          })
          .catch((error: any) => {
            callback && callback(null, error);
          });
      }

    },

    // have a common namespace used around the full app
    ns: ["default"],
    defaultNS: "default",

    debug: isDevEnv(),

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      useSuspense: true
    }
  });

export default i18n;
