import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styled from "styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeTrackingDto, TimeTrackingTableDto, WorkingTableDto } from "./types";
import {
  getAllActiveProjectTasksByProjectId,
  getAllProjectTasksByProjectId
} from "../ProjectTasks/services/ProjectTaskServices";
import { getAllProjectsWithTasks } from "../Projects/services/ProjectServices";
import {
  addTimeTracking,
  deleteTimeTracking,
  editTimeTracking,
  getAllTimeTrackingsByEmployee,
  getWorkingTable
} from "./services/TimeTrackingServices";
import Calendar from "../../components/Calendar";
import { useTranslation } from "react-i18next";
import { ProjectStatus, ProjectTaskType, ProjectType } from "../../ts-types/api.enums";
import { ProjectDto, ProjectTaskDto } from "../../ts-types/api.types";

const DatePickerClassInTimetrack = styled.div`
    .datePicker {
        width: 357px;
    }
`;

const InitialTimeTrackingForm: TimeTrackingDto = {
  date: "",
  duration: 0,
  employeeId: 0,
  projectId: 0,
  projectTaskId: 0,
  remark: ""
};

const InitialProjectDto: ProjectDto = {
  companyId: 0,
  id: 0,
  name: "",
  projectNumber: "",
  projectType: ProjectType.DEVELOPMENT,
  status: ProjectStatus.OPEN,
  active: false,
  stamp: ""
};

const InitialProjectTaskDto: ProjectTaskDto = {
  active: false,
  stamp: "",
  type: ProjectTaskType.WORKTRACKING,
  description: "",
  id: 0,
  projectId: 0,
  defaultVacationTrack: false
};

const InitialWorkingTableDto: WorkingTableDto = {
  remainingHoursInADay: "",
  remainingHoursInAMonth: "",
  remainingHoursInAWeek: "",
  workHoursInADay: "",
  workHoursInAMonth: "",
  workHoursInAWeek: "",
  workedHoursInADay: "",
  workedHoursInAMonth: "",
  workedHoursInAWeek: ""
};

const TimeTrackingPage = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [date, setDate] = React.useState<moment.Moment>(moment().clone());
  const [projects, setProjects] = React.useState<Array<ProjectDto>>([]);
  const [selectedProject, setSelectedProject] = React.useState<ProjectDto | null>(null);
  const [projectTasks, setProjectTasks] = React.useState<Array<ProjectTaskDto>>([]);
  const [selectedProjectTask, setSelectedProjectTask] = React.useState<ProjectTaskDto | null>(null);
  const [timeTrackingForm, setTimeTrackingForm] = React.useState<TimeTrackingDto>(InitialTimeTrackingForm);
  const [timeTrackings, setTimeTrackings] = React.useState<Array<TimeTrackingTableDto>>([]);
  const [timeTrackingId, setTimeTrackingId] = React.useState<number|undefined>(undefined);
  const { t } = useTranslation("company-manager");
  const [workingTableInfo, setWorkingTableInfo] = React.useState<WorkingTableDto>(InitialWorkingTableDto);

  React.useEffect(() => {
    loadProjects();
    loadTimeTrackings();
    loadWorkingTable(moment(date).format("YYYY-MM-DD"));
  }, []);

  const handleProjectChangeSelection = (newValue: ProjectDto | null) => {
    setSelectedProject(newValue);
    if (newValue && newValue.id) {
      loadProjectTasks(newValue.id);
    } else {
      setProjectTasks([]);
    }
    setSelectedProjectTask(null);
    if (newValue && newValue.id) {
      setTimeTrackingForm({
        ...timeTrackingForm,
        projectId: newValue.id
      });
    }
  };
  const handleProjectTaskChangeSelection = (newValue: ProjectTaskDto | null) => {
    setSelectedProjectTask(newValue);
    if (newValue && newValue.id) {
      setTimeTrackingForm({
        ...timeTrackingForm,
        projectTaskId: newValue.id
      });
    }
  };

  let minutes = "";
  let hours = "";
  let HMFormat = "";
  const durationInHM = (duration: number) => {
    if (parseInt(moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("HH")) < 10) {
      hours = moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("HH").slice(1, 2);
    } else {
      hours = moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("HH");
    }
    minutes = moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("mm");

    if (moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("mm") === "00") {
      HMFormat = `${hours}h`;
    } else if (moment.utc(moment.duration(duration, "hours").asMilliseconds()).format("HH") === "00") {
      HMFormat = `${minutes}m`;
    } else {
      HMFormat = `${hours}h ${minutes}m`;
    }
    return (
        <div>
          {HMFormat}
        </div>
    );
  };

  const loadWorkingTable = (r: string) => {
    getWorkingTable(r, cancelTokenSource)
      .then(r => setWorkingTableInfo(r));
  };

  const loadProjectTasks = (projectId: number) => {
    getAllActiveProjectTasksByProjectId(projectId, cancelTokenSource)
      .then(r => setProjectTasks(r));
  };

  const loadProjects = () => {
    getAllProjectsWithTasks(cancelTokenSource)
      .then(r => setProjects(r));
  };

  const loadTimeTrackings = async (selectedDate?: string) => {
    if (selectedDate) {
      await getAllTimeTrackingsByEmployee(moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
        cancelTokenSource)
        .then(r => setTimeTrackings(r));
    } else {
      await getAllTimeTrackingsByEmployee(
        moment(date).startOf("month").format("YYYY-MM-DD"),
        moment(date).endOf("month").format("YYYY-MM-DD"),
        cancelTokenSource)
        .then(r => setTimeTrackings(r));
    }
  };
  const handleDateChange = (response: moment.Moment) => {
    setDate(response);
    setTimeTrackingForm((prevState) => {
      return {
        ...prevState,
        date: moment(response).format("YYYY-MM-DD"),
        employeeId: 0
      };
    });
    loadTimeTrackings(moment(response).format("YYYY-MM-DD"));
    loadWorkingTable(moment(response).format("YYYY-MM-DD"));
  };

  const handleFormChange = (e: any) => {
    setTimeTrackingForm({
      ...timeTrackingForm,
      [e.target.name]: e.target.value
    });
  };

  const handleCopyAndEditButton = (timeTrack: TimeTrackingTableDto, from: string) => {
    const newTimeTrack: TimeTrackingDto = {
      date: timeTrack.date,
      remark: timeTrack.remark,
      duration: timeTrack.duration,
      projectId: timeTrack.projectId,
      projectTaskId: timeTrack.projectTaskId,
      employeeId: timeTrack.employeeId
    };
    if (from === "EDIT") {
      newTimeTrack.id = timeTrack.id;
      setTimeTrackingId(timeTrack.id);
    }
    projects.forEach((project) => {
      if (project.id === timeTrack.projectId) {
        setSelectedProject(project);
        getAllProjectTasksByProjectId(project.id, cancelTokenSource)
          .then(r => {
            setProjectTasks(r);
            r.forEach((projectTask) => {
              if (projectTask.id === timeTrack.projectTaskId) {
                setSelectedProjectTask(projectTask);
              }
            });
          });
      }
    });
    setTimeTrackingForm(newTimeTrack);
  };

  const handleDelete = (tt: TimeTrackingTableDto) => {
    if (tt.id) {
      deleteTimeTracking(tt.id, cancelTokenSource)
        .then(() => {
          setTimeTrackingForm({ ...timeTrackingForm, id: 0 });
          loadTimeTrackings(moment(date).format("YYYY-MM-DD"));
        });
    }
  };

  const saveTimeTracking = async (reset: boolean) => {
    if (timeTrackingId) {
      await editTimeTracking(timeTrackingForm, timeTrackingId, cancelTokenSource)
        .then(() => { loadTimeTrackings(); setTimeTrackingId(undefined); });
    } else {
      await addTimeTracking({ ...timeTrackingForm, date: date.format("YYYY-MM-DD") }, cancelTokenSource)
        .then(() => loadTimeTrackings());
    }
    if (reset) {
      setTimeTrackingForm(InitialTimeTrackingForm);
      await loadTimeTrackingForm();
      setSelectedProject(InitialProjectDto);
      setSelectedProjectTask(InitialProjectTaskDto);
    }
  };

  const renderWorkedTable = React.useCallback(() => {
    return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              </TableCell>
              <TableCell>
                Avail
              </TableCell>
              <TableCell>
                Worked
              </TableCell>
              <TableCell>
                /\
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                Day
              </TableCell>
              <TableCell>
                {workingTableInfo.workHoursInADay}
              </TableCell>
              <TableCell>
                {workingTableInfo.workedHoursInADay}
              </TableCell>
              <TableCell>
                {workingTableInfo.remainingHoursInADay}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Week
              </TableCell>
              <TableCell>
                {workingTableInfo.workHoursInAWeek}
              </TableCell>
              <TableCell>
                {workingTableInfo.workedHoursInAWeek}
              </TableCell>
              <TableCell>
                {workingTableInfo.remainingHoursInAWeek}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Month
              </TableCell>
              <TableCell>
                {workingTableInfo.workHoursInAMonth}
              </TableCell>
              <TableCell>
                {workingTableInfo.workedHoursInAMonth}
              </TableCell>
              <TableCell>
                {workingTableInfo.remainingHoursInAMonth}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
    );
  }, [workingTableInfo]);

  const loadTimeTrackingForm = () => {
    const fullDay = 8;
    const halfDay = 4;
    return (
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ width: 357, m: 2, mx: "9px" }}>
            <DatePickerClassInTimetrack>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("common.date")}
                  inputFormat="DD.MM.YYYY"
                  value={date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
              />
            </DatePickerClassInTimetrack>
          </Box>
          </LocalizationProvider>
            <Autocomplete
                disablePortal
                options={projects}
                getOptionLabel={(option: ProjectDto) => option === InitialProjectDto ? "" : `${option.projectNumber} ${option.name}`}
                value={selectedProject}
                onChange={(event: any, newValue: ProjectDto) => {
                  handleProjectChangeSelection(newValue);
                }}
                sx={{ width: 357, m: 2, mx: "9px" }}
                renderInput={(params) =>
                    <TextField {...params} label={t("common.projects")}/>}
            />
            <Autocomplete
                disablePortal
                options={projectTasks}
                getOptionLabel={(option: ProjectTaskDto) => option.description}
                value={selectedProjectTask}
                onChange={(event: any, newValue: ProjectTaskDto) => {
                  handleProjectTaskChangeSelection(newValue);
                }}
                sx={{ width: 357, m: 2, mx: "9px" }}
                renderInput={(params) =>
                    <TextField {...params} label={t("legend.tasks")}/>}
            />
            <Box justifyContent={"center"} alignContent={"center"} sx={{ ml: 1 }}>
              <TextField
                  sx={{ width: 100, m: 1, ml: 0 }}
                  name={"duration"}
                  label={t("common.duration")}
                  value={timeTrackingForm.duration}
                  type={"number"}
                  InputProps={{ inputProps: { min: 0, max: 24 } }}
                  helperText={(timeTrackingForm.duration < 0 || timeTrackingForm.duration > 24) && "Invalid number" }
                  onChange={handleFormChange}/>
              <Button
                  variant={"outlined"}
                  sx={{ width: 113, m: 1, height: 56 }}
                  onClick={() => setTimeTrackingForm({ ...timeTrackingForm, duration: halfDay })}>
                {t("button.halfDay")}
              </Button>
              <Button
                  variant={"outlined"}
                  sx={{ width: 113, m: 1, height: 56 }}
                  onClick={() => setTimeTrackingForm({ ...timeTrackingForm, duration: fullDay })}>
                {t("button.fullDay")}
              </Button>
            </Box>
          <Box sx={{ width: 362, mx: "7px", mt: 1 }} justifySelf={"center"}>
            <fieldset style={{
              borderColor: "silver",
              borderRadius: 5,
              padding: 0,
              borderStyle: "solid",
              borderWidth: 1
            }}>
              <legend style={{ marginLeft: 9, fontSize: 15, paddingInline: 4 }}>Remarks</legend>
              <TextareaAutosize
                  name={"remark"}
                  minRows={3}
                  value={timeTrackingForm.remark}
                  onChange={handleFormChange}
                  style={{ width: 356, border: "none", outline: "none", height: 100 }}
              />
            </fieldset>
          </Box>
            <Box>
              <Button
                  variant={"outlined"}
                  sx={{ m: 1 }}
                  disabled={selectedProject === null || selectedProjectTask === null ||
                    (timeTrackingForm.duration < 0.1 || timeTrackingForm.duration > 24)}
                  onClick={() => {
                    if (selectedProject !== null && selectedProjectTask !== null && (timeTrackingForm.duration > 0 && timeTrackingForm.duration < 25)) {
                      saveTimeTracking(true);
                    }
                  }
                  }>
                {t("common.save")}
              </Button>
              <Button
                  variant={"outlined"}
                  sx={{ m: 1 }}
                  disabled={selectedProject === null || selectedProjectTask === null ||
                    (timeTrackingForm.duration < 0.1 || timeTrackingForm.duration > 24)}
                  onClick={() => {
                    if (selectedProject !== null && selectedProjectTask !== null && (timeTrackingForm.duration > 0 && timeTrackingForm.duration < 24.1)) {
                      saveTimeTracking(false);
                    }
                  }
                  }>
                {t("button.saveAndAddNew")}
              </Button>
              <Button
                  variant={"outlined"}
                  sx={{ m: 1 }}
                  onClick={() => {
                    setTimeTrackingForm(InitialTimeTrackingForm);
                    setSelectedProject(InitialProjectDto);
                    setSelectedProjectTask(InitialProjectTaskDto);
                  }}>
                {t("button.clear")}
              </Button>
            </Box>
        </div>
    );
  };
  const timeTrackingTable = React.useMemo(() => {
    return (
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 400, width: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("common.project")} <br/>
                  <Box sx={{ color: "gray" }}>{t("common.description")}</Box>
                </TableCell>
                <TableCell>{t("common.duration")} <br/>
                  <Box sx={{ color: "gray" }}>{t("header.task")}</Box>
                </TableCell>
                <TableCell>{t("header.remarks")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timeTrackings.map((tt) => {
                if (tt.date === date.format("YYYY-MM-DD")) {
                  return (
                      <TableRow key={tt.id} sx={{ height: 20 }}>
                        <TableCell sx={{ display: "flex", flexDirection: "row", padding: "5px" }}>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <IconButton
                                size={"small"}
                                onClick={() => handleCopyAndEditButton(tt, "EDIT")}>
                              <EditIcon fontSize={"small"}/>
                            </IconButton>
                            <IconButton
                                size={"small"}
                                onClick={() => handleCopyAndEditButton(tt, "COPY")}>
                              <ContentCopyIcon fontSize={"small"}/>
                            </IconButton>
                            <IconButton onClick={() => handleDelete(tt)}>
                              <DeleteIcon fontSize={"small"}/>
                            </IconButton>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                          {tt.projectNumber} <br/>
                          <Box sx={{ color: "gray" }}>{tt.projectName}</Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: "5px" }}>
                          {durationInHM(tt.duration)}
                          <Box sx={{ color: "gray" }}>{tt.description}</Box>
                        </TableCell>
                        <TableCell sx={{ p: "5px" }}>
                          {tt.remark}
                        </TableCell>
                      </TableRow>
                  );
                } else {
                  return <> </>;
                }
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
    );
  }, [timeTrackings]);
  return (
      <Box sx={{ width: "100%" }}>
      <Grid
          container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
          direction="row"
          justifyContent="space-evenly"
          marginTop={"20px"}>
        <Box display={"flex"}
             flexDirection={"column"}
             alignItems={"center"}>
          <Calendar handleDateChange={handleDateChange} selectedDate={date} timeTrackings={timeTrackings} />
          {renderWorkedTable()}
        </Box>
        <Box display={"flex"}
             flexDirection={"column"}
             alignItems={"center"}>
          <h3 style={{ margin: 0 }}>{t("header.timetrackEntry")}</h3>
          {loadTimeTrackingForm()}
        </Box>
        <Box display={"flex"}
             flexDirection={"column"}
             alignItems={"center"}>
          <h3 style={{ margin: 0 }}>{date.format("MMMM Do YYYY, dddd")}</h3>
          {timeTrackingTable}
        </Box>
      </Grid>
      </Box>
  );
};
export default TimeTrackingPage;
