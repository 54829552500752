import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField
} from "@mui/material";
import React, { FC, useCallback } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import axios from "axios";
import { ReworkingReportDateKey, ReworkingReportDto } from "../types";
import { AdminProps } from "../../../components/interfaces/interface";
import {
  getTimeTrackingReport,
  getTimeTrackingReportExportUrl,
  getWorkingReportByDay
} from "../services/TimeTrackingServices";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { getAllProjects } from "../../Projects/services/ProjectServices";
import VirtualizedForTimeTrackingTable from "../../../components/VirtualizedTable/VirtualizedTableForTimeTrackingTable";
import classNames from "classnames";
import { WorkingReportsView } from "../../../components/interfaces/enums";
import { ProjectTableDto, WorkingTableByDayDto, TimeTrackingReportDto } from "../../../ts-types/api.types";

const InitialDateStartOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");

const InitialReportDateKey: ReworkingReportDateKey = {
  dateTo: "",
  dateFrom: InitialDateStartOfMonth
};

const OvertimeDiv = styled("div")`
  display: flex;
  width: 200px;
  .overtime{
    color: green;
  }
  .undertime{
    color: red;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface SortOptions {
  date: {value: string, label: string}
  shortName: {value: string, label: string}
  projectNumber: {value: string, label: string}
}

const TimeTrackingTable : FC<AdminProps> = (): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [formDateChange, setFormDateChange] = React.useState<ReworkingReportDateKey>(InitialReportDateKey);
  const [timeTrackings, setTimeTrackings] = React.useState<Array<TimeTrackingReportDto>>([]);
  const [timeTrackingsByDay, setTimeTrackingsByDay] = React.useState<WorkingTableByDayDto>({
    rows: [],
    shortNames: []
  });
  const [projects, setProjects] = React.useState<Array<ProjectTableDto>>([]);
  const [sort, setSort] = React.useState<string[]>(["date"]);
  const { t } = useTranslation("company-manager");
  const [form, setForm] = React.useState<ReworkingReportDto>({
    unAccounted: false,
    sort,
    showClosed: false,
    dateFrom: InitialDateStartOfMonth,
    dateTo: "",
    employeeShortName: "",
    projectName: ""
  });
  const [selectedView, setSelectedView] = React.useState<WorkingReportsView>(WorkingReportsView.VIEW_BY_RECORD);

  const sortOptions: SortOptions = React.useMemo(() => {
    return {
      date: {
        value: "date",
        label: t("common.date")
      },
      shortName: {
        value: "shortName",
        label: t("common.shortName")
      },
      projectNumber: {
        value: "projectNumber",
        label: t("common.projectNumber")
      }
    };
  }, [t]);

  React.useEffect(() => {
    loadProjects();
  }, []);

  const handleShowClosed = () => {
    if (!form.showClosed) {
      setForm({ ...form, showClosed: true });
    } else {
      setForm({ ...form, showClosed: false });
    }
  };

  const handleViewChange = () => {
    if (selectedView === WorkingReportsView.VIEW_BY_DAY) {
      setSelectedView(WorkingReportsView.VIEW_BY_RECORD);
    } else {
      setSelectedView(WorkingReportsView.VIEW_BY_DAY);
    }
  };

  const exportTimeTrackingReport = () => {
    setTimeout(() => {
      getTimeTrackingReportExportUrl(
        form.dateFrom,
        form.dateTo,
        form.employeeShortName,
        form.projectName,
        form.showClosed,
        sort).then();
    }, 500);
  };

  const handlePreview = () => {
    loadTimeTrackings();
  };

  const loadTimeTrackings = () => {
    getTimeTrackingReport({ ...form, sort }, cancelTokenSource)
      .then(r => {
        setTimeTrackings(r.timeTrackingReportDtos);
      });
    getWorkingReportByDay({ ...form, sort }, cancelTokenSource)
      .then(r => {
        setTimeTrackingsByDay(r);
      });
  };

  const loadProjects = React.useCallback(() => {
    getAllProjects(cancelTokenSource, "")
      .then(r => setProjects(r));
  }, [projects]);

  const handleFormChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleDateChange = (dateKey: keyof ReworkingReportDateKey) => (value: string) => {
    setForm({
      ...form,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
    setFormDateChange({
      ...formDateChange,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
  };

  const changeToLastMonth = () => {
    const today = moment().clone();
    today.subtract(1, "month");
    setFormDateChange({
      dateFrom: moment(today).clone().startOf("month").format("YYYY-MM-DD"),
      dateTo: moment(today).clone().endOf("month").format("YYYY-MM-DD")
    });
    setForm({
      ...form,
      dateFrom: moment(today).clone().startOf("month").format("YYYY-MM-DD"),
      dateTo: moment(today).clone().endOf("month").format("YYYY-MM-DD")
    });
  };

  const handleSelectionChange = (event: SelectChangeEvent<typeof sort>) => {
    const {
      target: { value }
    } = event;
    setSort(typeof value === "string" ? value.split(",") : value);
  };

  const renderReportTable = (): JSX.Element => {
    const durationCells = ({ rowData }: any) => {
      return (
          <div>{rowData.duration}h</div>
      );
    };

    const timeTrackReportsRowGetter = React.useCallback(({ index }: any) => {
      Object.assign(timeTrackings[index], { index: index + 1 });

      return timeTrackings[index];
    }, [timeTrackings]);

    const timeTrackReportsRowRenderer = React.useCallback(({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    }, [timeTrackings]);

    return (
        <VirtualizedTable
            height={920}
            rowCount={timeTrackings.length}
            rowGetter={timeTrackReportsRowGetter}
            rowRenderer={timeTrackReportsRowRenderer}
            columns={[
              {
                width: 150,
                label: t("tableHeader.projectNo"),
                dataKey: "projectNumber"
              },
              {
                width: 250,
                label: t("tableHeader.projectName"),
                dataKey: "projectName"
              },
              {
                width: 250,
                label: t("tableHeader.projectTaskDescription"),
                dataKey: "projectTaskDesc"
              },
              {
                width: 60,
                label: t("tableHeader.sn"),
                dataKey: "shortName"
              },
              {
                width: 200,
                label: t("common.date"),
                dataKey: "date"
              },
              {
                width: 100,
                label: t("common.duration"),
                dataKey: "duration",
                cellRenderer: (durationCells)
              },
              {
                width: 400,
                flexGrow: 1,
                label: t("tableHeader.remark"),
                dataKey: "remark"
              }
            ]}
        />
    );
  };

  const renderReportByDayTable = useCallback((): JSX.Element => {
    const employeeCell = (shortName: string) => (data: any) => {
      const { rowData } = data;

      if (rowData.data[shortName]) {
        const workedHours = rowData.data[shortName].workedHours;
        const overtimeHours = rowData.data[shortName].overtimeHours;
        return <OvertimeDiv>
          <Box sx={{ width: "60px", display: "flex", justifyContent: "space-around" }}>
            {workedHours !== 0 ? workedHours : ""}
          </Box>
          <Box sx={{ width: "60px", display: "flex", justifyContent: "space-around" }} paddingRight={2}>
            {overtimeHours === 0
              ? <></>
              : <div className={classNames(
                { overtime: overtimeHours > 0 },
                { undertime: overtimeHours < 0 }
              )}>{overtimeHours > 0 ? `+${overtimeHours}` : overtimeHours}</div>}
          </Box>
        </OvertimeDiv>;
      }

      return "";
    };

    const dateHeader = () => {
      return (
          <Box display={"flex"} alignItems={"end"} justifyContent={"left"} height={41}>
            {t("common.fromDate")}
          </Box>
      );
    };

    const employeeHeader = (text: string) => {
      return (
          <Stack direction={"column"}>
            <Box display={"flex"} justifyContent={"left"} paddingLeft={1}>
              {text}
            </Box>
            <Box flexGrow={1}>
              <hr/>
            </Box>
            <Stack direction={"row"} display={"flex"} width={120}>
              <Box display={"flex"} justifyContent={"space-around"} width={60}>
                [h]
              </Box>
              <Box display={"flex"} paddingRight={2} justifyContent={"space-around"} width={60}>
                ot
              </Box>
            </Stack>
          </Stack>
      );
    };

    const employeeShortNames = timeTrackingsByDay.shortNames;
    const columns: any = [
      {
        width: 120,
        label: dateHeader(),
        dataKey: "date"
      }
    ];

    if (employeeShortNames && employeeShortNames.length) {
      employeeShortNames.forEach((shortName) => {
        columns.push({
          width: 120,
          label: employeeHeader(shortName),
          dataKey: "#",
          cellRenderer: (employeeCell(shortName))
        });
      });
    }
    columns.push({
      width: 120,
      label: employeeHeader("Total"),
      dataKey: "#",
      cellRenderer: (employeeCell("Total"))
    });

    const timeTrackReportsByDayRowGetter = React.useCallback(({ index }: any) => {
      Object.assign(timeTrackingsByDay.rows[index], { index: index + 1 });

      return timeTrackingsByDay.rows[index];
    }, [timeTrackingsByDay.rows]);

    const timeTrackReportsByDayRowRenderer = React.useCallback(({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    }, [timeTrackingsByDay.rows]);

    return (
      timeTrackingsByDay.shortNames
        ? <VirtualizedForTimeTrackingTable
            height={920}
            headerHeight={45}
            width={(timeTrackingsByDay.shortNames.length + 2) * 120}
            rowCount={timeTrackingsByDay.rows && timeTrackingsByDay.rows.length ? timeTrackingsByDay.rows.length : 0}
            rowGetter={timeTrackReportsByDayRowGetter}
            rowRenderer={timeTrackReportsByDayRowRenderer}
            columns={columns}
        />
        : <h3>{t("common.noRecordsFound")}</h3>
    );
  }, [timeTrackingsByDay]);

  const rowHeight = 48;
  let tableHeight = (timeTrackings.length * rowHeight) + 25;
  if (tableHeight > 1000) {
    tableHeight = 1000;
  }

  return (
      <div>
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            <TextField
                sx={{ width: 259, m: 2 }}
                name={"projectName"}
                label={t("common.project")}
                value={form?.projectName}
                onChange={handleFormChange}/>
            <TextField
                sx={{ width: 259, m: 2 }}
                name={"employeeShortName"}
                label={t("legend.employeeShortName")}
                value={form?.employeeShortName}
                onChange={handleFormChange}/>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ m: 2 }}>
                <DesktopDatePicker
                    className="datePicker"
                    label={t("legend.from")}
                    inputFormat="DD/MM/YYYY"
                    value={formDateChange.dateFrom}
                    onChange={handleDateChange("dateFrom")}
                    renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ m: 2 }}>
                <DesktopDatePicker
                    className="datePicker"
                    label={t("legend.to")}
                    inputFormat="DD/MM/YYYY"
                    value={formDateChange.dateTo}
                    onChange={handleDateChange("dateTo")}
                    renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
            <Button
                size={"small"}
                sx={{ m: 2 }}
                variant={"outlined"}
                type={"button"}
                onClick={changeToLastMonth}>
              {t("button.lastMonth")}
            </Button>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"}>
              <Box sx={{ alignContent: "center", display: "flex" }}>
                <FormControl sx={{ m: 2, width: 400, height: 56 }}>
                  <InputLabel id="demo-multiple-chip-label">{t("legend.sort")}</InputLabel>
                  <Select
                      sx={{ height: 56 }}
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={sort}
                      onChange={handleSelectionChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Sort" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value: keyof SortOptions) => (
                                <Chip key={value} label={sortOptions[value].label} />
                            ))}
                          </Box>
                      )}
                  >
                    {Object.keys(sortOptions).map((key: keyof SortOptions) => (
                        <MenuItem key={sortOptions[key].value} value={sortOptions[key].value}>
                          {sortOptions[key].label}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <InputLabel sx={{ width: 250, m: 2 }}>{t("legend.selectView")}</InputLabel>
                    <Select
                      sx={{ width: 250, m: 2 }}
                      type="text"
                      label={t("legend.selectView")}
                      value={selectedView}
                      onChange={handleViewChange}>
                    <MenuItem key={1} value={WorkingReportsView.VIEW_BY_RECORD}>
                      {t("option.byRecord")}
                    </MenuItem>
                    <MenuItem key={2} value={WorkingReportsView.VIEW_BY_DAY}>
                      {t("option.byDay")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <FormControlLabel control={<Checkbox
                    checked={form.showClosed}
                    onChange={handleShowClosed}
                    inputProps={{ "aria-label": "controlled" }}/>}
                                  label={t("button.showClosedProjects")} />
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Box sx={{ alignContent: "center", display: "flex" }}>
                <Button
                    sx={{ height: 56, width: 107, margin: 2 }}
                    size={"small"}
                    variant={"outlined"}
                    type={"button"}
                    onClick={handlePreview}>
                  {t("button.preview")}
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, alignContent: "center", display: "flex" }}>
                <Button
                    variant={"outlined"}
                    sx={{ height: 56, width: 107, margin: 2 }}
                    onClick={() => exportTimeTrackingReport()}>
                  {t("button.export")}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        {selectedView === WorkingReportsView.VIEW_BY_RECORD
          ? <Box style={{ height: tableHeight }}>
              {renderReportTable()}
            </Box>
          : <Box style={{ height: tableHeight, width: "100%" }}>
              {timeTrackingsByDay ? renderReportByDayTable() : <> </> }
            </Box>}
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button onClick={() => exportTimeTrackingReport()}>
            {t("button.export")}
          </Button>
        </Box>
      </div>
  );
};
export default TimeTrackingTable;
