import * as React from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BadgeIcon from "@mui/icons-material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { Stack, Toolbar, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Navbar from "./Navbar";
import Home from "../App/Home";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../interfaces/enums";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({
  theme,
  open
}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({
    theme,
    open
  }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme)
    })
  })
);

const CollapsibleNavBar = (): JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(() => {
    return localStorage.getItem("drawerOpen") === "true";
  });
  const { user } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation("company-manager");
  const [selectedItem, setSelectedItem] = React.useState<number>();
  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("drawerOpen", "true");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("drawerOpen", "false");
  };

  const renderSelectedValue = React.useCallback((key: number) => {
    setSelectedItem(key);
    if (user?.roles?.includes(Role.ROLE_ADMIN)) {
      switch (key) {
        case 1:
          return history.push("/holiday");
        case 2:
          return history.push("/user");
        case 3:
          return history.push("/person");
        case 4:
          return history.push("/employee");
        case 5:
          return history.push("/company");
        case 6:
          return history.push("/project");
        case 7:
          return history.push("/working-report");
        case 8:
          return history.push("/vacation-report");
        case 9:
          return history.push("/public-vacation");
        case 10:
          return history.push("/accounted-working-report");
      }
    } else if (user?.roles?.includes(Role.ROLE_EMPLOYEE)) {
      switch (key) {
        case 1:
          return history.push("/holiday");
        case 2:
          return history.push("/person");
        case 3:
          return history.push("/employee");
        case 4:
          return history.push("/company");
        case 5:
          return history.push("/project");
        case 6:
          return history.push("/working-report");
        case 7:
          return history.push("/vacation-report");
        case 8:
          return history.push("/public-vacation");
      }
    }
  }, [user, selectedItem]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline/>
      <AppBar position="fixed" open={open} color={"transparent"} sx={{ bgcolor: "#b0bec5" }}>
        <Toolbar>
          <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 1, width: "100%" }}>
          <Stack direction={"row"}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" })
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src={"CoMa_Logo.png"}
               alt={"Company Manager Logo"}
               width={70}
               height={70}/>
          </Stack>
            <Navbar/>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{ marginTop: "7px" }}>
        <DrawerHeader sx={{ marginTop: "13px" }}>
          <IconButton onClick={handleDrawerClose} style={{ color: open ? "black" : "transparent" }}>
            {theme.direction === "rtl" ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <List>
          {(user?.roles?.includes(Role.ROLE_ADMIN)
            ? [t("option.holidays"),
                t("option.users"),
                t("option.persons"),
                t("common.employees"),
                t("common.companies"),
                t("common.projects"),
                t("option.reworkingReports"),
                t("common.vacationReports"),
                t("common.publicVacations"),
                t("common.accountedWorkingReports")]
            : [t("option.holidays"),
                t("option.persons"),
                t("common.employees"),
                t("common.companies"),
                t("common.projects"),
                t("option.reworkingReports"),
                t("common.vacationReports"),
                t("common.publicVacations")]).map((text, index) => (
            <ListItem key={text}
                      disablePadding
                      sx={{ display: "block" }}
                      onClick={() => renderSelectedValue(index + 1)
            }>
              <ListItemButton
                title={text}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center"
                  }}
                >
                  {user?.roles?.includes(Role.ROLE_ADMIN)
                    ? <>
                  {index === 0 && <HolidayVillageIcon/>}
                  {index === 1 && <AccountCircleIcon/>}
                  {index === 2 && <PersonIcon/>}
                  {index === 3 && <BadgeIcon/>}
                  {index === 4 && <ApartmentIcon/>}
                  {index === 5 && <AccountTreeIcon/>}
                  {index === 6 && <AssessmentIcon/>}
                  {index === 7 && <EventRepeatIcon/>}
                  {index === 8 && <CalendarMonthIcon/>}
                  {index === 9 && <SummarizeIcon/>}
                  </>
                    : <>
                      {index === 0 && <HolidayVillageIcon/>}
                      {index === 1 && <PersonIcon/>}
                      {index === 2 && <BadgeIcon/>}
                      {index === 3 && <ApartmentIcon/>}
                      {index === 4 && <AccountTreeIcon/>}
                      {index === 5 && <AssessmentIcon/>}
                      {index === 6 && <EventRepeatIcon/>}
                      {index === 7 && <CalendarMonthIcon/>}
                    </>}

                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant="body2" style={{ fontWeight: selectedItem === index + 1 ? "800" : "normal" }}>
                    {text}
                  </Typography>
                } sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider/>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Home/>
      </Box>
    </Box>
  );
};
export default CollapsibleNavBar;
