import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddEmployeeVacationType, EmployeeVacationType } from "../../../components/interfaces/enums";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { AddEmployeeVacationDto } from "../../Employees/types";
import axios from "axios";
import { AddUpsertEmployeeVacationDto, UpsertEmployeeVacationDto } from "../types";
import moment from "moment";
import { addEmployeeVacation, getAllForEmployeeVacation } from "../services/VacationReportServices";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const InitialAddUpsertEmployeeVacationDto: AddUpsertEmployeeVacationDto = {
  creditDays: undefined,
  employeeId: 0,
  forwardDays: undefined,
  creditRemark: "",
  type: EmployeeVacationType.CREDIT
};

const AddEmployeeVacation = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const [form, setForm] = React.useState<Array<AddUpsertEmployeeVacationDto>>([]);
  const [selectedType, setSelectedType] = React.useState<AddEmployeeVacationType>(AddEmployeeVacationType.Credit);
  const [employees, setEmployees] = React.useState<Array<AddEmployeeVacationDto>>([]);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    loadEmployees();
  }, []);

  const loadEmployees = () => {
    setDataLoaded(false);
    getAllForEmployeeVacation(cancelTokenSource)
      .then(r => {
        setEmployees(r);
        createFormData(r);
      })
      .finally(() => setDataLoaded(true));
  };

  const createFormData = (r: AddEmployeeVacationDto[]) => {
    const newList = form;
    r.forEach((employee) => {
      newList.push({ ...InitialAddUpsertEmployeeVacationDto, employeeId: employee.id });
    });
    setForm(newList);
  };

  const handleFormChange = (e: any, index: number) => {
    const newList: Array<AddUpsertEmployeeVacationDto> = [...form];
    let newObj: any = { ...newList[index] };
    newObj = ({ ...newObj, [e.target.name]: e.target.value });
    newList[index] = newObj;
    setForm(newList);
  };

  const handleSubmit = (rowIndex: number) => {
    const lastYear = moment().subtract(1, "year").format("yyyy");
    const forwardDays = form[rowIndex].forwardDays;
    const newForwardObj: UpsertEmployeeVacationDto = {
      type: EmployeeVacationType.FORWARD,
      employeeId: form[rowIndex].employeeId,
      remark: `Forwarded ${forwardDays} from ${lastYear}`,
      days: forwardDays || 0
    };
    const creditDays = form[rowIndex].creditDays;
    const newCreditObj: UpsertEmployeeVacationDto = {
      type: EmployeeVacationType.CREDIT,
      employeeId: form[rowIndex].employeeId,
      remark: form[rowIndex].creditRemark,
      days: creditDays || 0
    };
    switch (selectedType) {
      case AddEmployeeVacationType.Forward:
        addEmployeeVacation(newForwardObj, cancelTokenSource)
          .then(() => loadEmployees());
        break;
      case AddEmployeeVacationType.Credit:
        addEmployeeVacation(newCreditObj, cancelTokenSource)
          .then(() => loadEmployees());
        break;
      case AddEmployeeVacationType.ForwardCredit:
        addEmployeeVacation(newForwardObj, cancelTokenSource)
          .then(() => {
            addEmployeeVacation(newCreditObj, cancelTokenSource);
            loadEmployees();
          })
        ;
        break;
    }
  };

  const handleChange = (e: any) => {
    setSelectedType(e.target.value);
    loadEmployees();
  };

  const nonForwardHeader = () => {
    return (
        <Box>
          {t("common.nonForwardedDays")}
          <IconButton onClick={fillCreditFieldsFromNonForwardedDays}>
            <TrendingFlatIcon/>
          </IconButton>
        </Box>
    );
  };

  const setNewFormValues = (newList: AddUpsertEmployeeVacationDto[]) => {
    const emp = [...employees];
    setEmployees([]);
    setForm(newList);
    setEmployees(emp);
  };

  const fillCreditFieldsFromNonForwardedDays = () => {
    const newList = form;
    employees.forEach((employee, index) => {
      const newObj = newList[index];
      newObj.forwardDays = employee.lastYearCredits;
    });
    setNewFormValues(newList);
  };

  const workTimeHeader = () => {
    return (
        <Box>
          {t("common.workTimeCredits")}
          <IconButton onClick={fillCreditFieldsFromWorktime}>
            <TrendingFlatIcon/>
          </IconButton>
        </Box>
    );
  };

  const fillCreditFieldsFromWorktime = () => {
    const newList = form;
    employees.forEach((employee, index) => {
      const newObj = newList[index];
      newObj.creditDays = employee.workTimeCredits;
    });
    setNewFormValues(newList);
  };

  const renderEmployees = (): JSX.Element => {
    const submit = ({ rowIndex }: any) => {
      return (
          <>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                disabled={state?.role === "ROLE_EMPLOYEE"}
                onClick={() => handleSubmit(rowIndex)}>
              {t("common.save")}
            </Button>
          </>
      );
    };

    const description = ({ rowIndex }: any) => {
      return (
          <TextField
              size={"small"}
              sx={{ width: 300, m: 1 }}
              name={"creditRemark"}
              value={form[rowIndex].creditRemark}
              onChange={e => handleFormChange(e, rowIndex)}
          />
      );
    };

    const forwardDays = ({ rowIndex }: any) => {
      return (
          <TextField
              size={"small"}
              sx={{ width: 100, m: 1 }}
              name={"forwardDays"}
              value={form[rowIndex].forwardDays}
              onChange={e => handleFormChange(e, rowIndex)}
          />
      );
    };

    const creditDays = ({ rowIndex }: any) => {
      return (
          <TextField
              size={"small"}
              sx={{ width: 100, m: 1 }}
              name={"creditDays"}
              value={form[rowIndex].creditDays}
              onChange={e => handleFormChange(e, rowIndex)}
          />
      );
    };

    const employeeRowGetter = ({ index }: any) => {
      Object.assign(employees[index], { index: index + 1 });
      return employees[index];
    };

    const employeeRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const columns: any = [
      {
        width: 100,
        label: t("common.name"),
        dataKey: "shortName"
      },
      {
        width: 300,
        label: t("header.currentCredits"),
        dataKey: "currentCredits"
      }
    ];
    if (selectedType === AddEmployeeVacationType.Credit || selectedType === AddEmployeeVacationType.ForwardCredit) {
      columns.push({
        width: 300,
        label: workTimeHeader(),
        dataKey: "workTimeCredits"
      },
      {
        width: 300,
        label: t("common.credits"),
        dataKey: "id",
        cellRenderer: (creditDays)
      },
      {
        width: 300,
        flexGrow: 1,
        label: t("common.description"),
        dataKey: "id",
        cellRenderer: (description)
      });
    } if (selectedType === AddEmployeeVacationType.Forward ||
        selectedType === AddEmployeeVacationType.ForwardCredit) {
      columns.push({
        width: 300,
        label: nonForwardHeader(),
        dataKey: "lastYearCredits"
      },
      {
        width: 300,
        flexGrow: 1,
        label: t("common.forwardDays"),
        cellRenderer: (forwardDays),
        dataKey: "id"
      });
    }
    columns.push({
      width: 300,
      label: t("common.actions"),
      dataKey: "id",
      cellRenderer: (submit)
    });

    return (
        <VirtualizedTable
            height={800}
            rowCount={employees.length}
            rowGetter={employeeRowGetter}
            rowRenderer={employeeRowRenderer}
            columns={columns}
        />
    );
  };

  return (
      <>
        <h3>{t("header.addEmployeeVacation")}</h3>
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup
              value={selectedType}
              onChange={handleChange}
          >
            <FormControlLabel
                value={AddEmployeeVacationType.Credit}
                control={<Radio />}
                label={t("radio.credit")} />
            <FormControlLabel
                value={AddEmployeeVacationType.Forward}
                control={<Radio />}
                label={t("radio.forward")} />
            <FormControlLabel
                value={AddEmployeeVacationType.ForwardCredit}
                control={<Radio />}
                label={t("radio.forwardAndCredit")}
            />
          </RadioGroup>
        </FormControl>
        <Box>
        {dataLoaded
          ? <Box height={800}>
          {renderEmployees()}
        </Box>
          : <>
              </>}
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
                onClick={() => history.push(
                  "/vacation-report",
                  { employeeVacationSelectedEmployee: state.employeeId }
                )}>
              {t("common.back")}
            </Button>
          </Box>
        </Box>
      </>
  );
};
export default AddEmployeeVacation;
