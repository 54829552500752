/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-07-11 09:40:43.

export enum AddressType {
    BILLING_ADDRESS = "BILLING_ADDRESS",
    POST_ADDRESS = "POST_ADDRESS",
}

export enum CivilStatus {
    UNKNOWN = "UNKNOWN",
    SINGLE = "SINGLE",
    MARRIED = "MARRIED",
    WIDOWED = "WIDOWED",
    DIVORCED = "DIVORCED",
    SEPARATED = "SEPARATED",
    PARTNERED = "PARTNERED",
}

export enum RelationType {
    RESPONSIBLE_FOR = "RESPONSIBLE_FOR",
    KNOWS = "KNOWS",
    CONTACT_PERSON = "CONTACT_PERSON",
}

export enum VacationType {
    FORWARD = "FORWARD",
    CREDIT = "CREDIT",
    USED = "USED",
}

export enum ErrorCodes {
    SHORT_NAME_NOT_VALID = "SHORT_NAME_NOT_VALID",
    HOLIDAYS_ARE_OVERLAPPED = "HOLIDAYS_ARE_OVERLAPPED",
    NO_DAYS_LEFT = "NO_DAYS_LEFT",
    ENDING_DATE_IS_BEFORE_STARTING_DATE = "ENDING_DATE_IS_BEFORE_STARTING_DATE",
    CANT_EDIT = "CANT_EDIT",
    HOLIDAY_NOT_FOUND = "HOLIDAY_NOT_FOUND",
    COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
    INCORRECT_CREDENTIALS = "INCORRECT_CREDENTIALS",
    USERNAME_ALREADY_EXISTS = "USERNAME_ALREADY_EXISTS",
    NAME_ALREADY_EXISTS = "NAME_ALREADY_EXISTS",
    HOLIDAY_STATUS_IS_NOT_WAITING_TO_BE_APPROVED = "HOLIDAY_STATUS_IS_NOT_WAITING_TO_BE_APPROVED",
    PERSON_NOT_FOUND = "PERSON_NOT_FOUND",
    PROJECT_TASK_NOT_FOUND = "PROJECT_TASK_NOT_FOUND",
    DEFAULT_PROJECT_TASK_NOT_FOUND = "DEFAULT_PROJECT_TASK_NOT_FOUND",
    PROJECT_PERSON_NOT_FOUND = "PROJECT_PERSON_NOT_FOUND",
    COMPANY_PERSON_NOT_FOUND = "COMPANY_PERSON_NOT_FOUND",
    PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
    TIME_TRACKING_NOT_FOUND = "TIME_TRACKING_NOT_FOUND",
    WORKTIME_NOT_FOUND = "WORKTIME_NOT_FOUND",
    EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
    EMPLOYEE_VACATION_NOT_FOUND = "EMPLOYEE_VACATION_NOT_FOUND",
    EMPLOYEE_NO_WORKTIME = "EMPLOYEE_NO_WORKTIME",
    PERSON_WORKTIME_NOT_FOUND = "PERSON_WORKTIME_NOT_FOUND",
    OVERTIME_NOT_FOUND = "OVERTIME_NOT_FOUND",
    PUBLIC_VACATION_NOT_FOUND = "PUBLIC_VACATION_NOT_FOUND",
    NOT_VALID_DURATION = "NOT_VALID_DURATION",
    USER_NOT_FOUND = "USER_NOT_FOUND",
    ACCOUNTED_TIME_TRACKING_NOT_FOUND = "ACCOUNTED_TIME_TRACKING_NOT_FOUND",
    PASSWORDS_DONT_MATCH = "PASSWORDS_DONT_MATCH",
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    IS_ACCOUNTED = "IS_ACCOUNTED",
    PROJECT_NUMBER_ALREADY_EXISTS = "PROJECT_NUMBER_ALREADY_EXISTS",
    INVALID_EMAIL = "INVALID_EMAIL",
    INVALID_PATTERN = "INVALID_PATTERN",
    NAME_FIELD_IS_EMPTY = "NAME_FIELD_IS_EMPTY",
    EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
    COMPANY_ADDRESS_NOT_FOUND = "COMPANY_ADDRESS_NOT_FOUND",
    COMPANY_BANK_NOT_FOUND = "COMPANY_BANK_NOT_FOUND",
    SOME_DAYS_LEFT = "SOME_DAYS_LEFT",
    ALREADY_DECLINED_HOLIDAY = "ALREADY_DECLINED_HOLIDAY",
    USER_DISABLED = "USER_DISABLED",
    IMAGE_TOO_LARGE = "IMAGE_TOO_LARGE",
    IMAGE_NOT_FOUND = "IMAGE_NOT_FOUND",
    HOLIDAY_IN_THE_PAST = "HOLIDAY_IN_THE_PAST",
    NOT_ENOUGH_DAYS = "NOT_ENOUGH_DAYS",
    INVALID_WORKING_DATE = "INVALID_WORKING_DATE",
}

export enum BillCurrency {
    EUR = "EUR",
    MKD = "MKD",
    CHF = "CHF",
}

export enum FileArchiveSourceType {
    INVOICE = "INVOICE",
}

export enum Color {
    LIGHTGREEN = "LIGHTGREEN",
    WHITE = "WHITE",
    LIGHTBLUE = "LIGHTBLUE",
    LIGHTYELLOW = "LIGHTYELLOW",
}

export enum Status {
    WAITING_ON_APPROVAL = "WAITING_ON_APPROVAL",
    PAST_HOLIDAY = "PAST_HOLIDAY",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export enum CompensationType {
    VACATION = "VACATION",
    PAYMENT = "PAYMENT",
}

export enum OvertimeType {
    FORWARD = "FORWARD",
    CREDIT = "CREDIT",
    USED = "USED",
}

export enum Trimester {
    T1 = "T1",
    T2 = "T2",
    T3 = "T3",
    T4 = "T4",
}

export enum ProjectStatus {
    OPEN = "OPEN",
    RUNNING = "RUNNING",
    PERMANENT = "PERMANENT",
    CLOSED = "CLOSED",
}

export enum ProjectTableStatusFilter {
    ALL = "ALL",
    OPEN = "OPEN",
    RUNNING = "RUNNING",
    OPEN_AND_RUNNING = "OPEN_AND_RUNNING",
}

export enum ProjectType {
    MAINTENANCE = "MAINTENANCE",
    DEVELOPMENT = "DEVELOPMENT",
}

export enum FunctionInProject {
    PROJECT_LEAD = "PROJECT_LEAD",
    LEAD_DEVELOPER = "LEAD_DEVELOPER",
    TEAM_MEMBER = "TEAM_MEMBER",
    DEVELOPER = "DEVELOPER",
    OTHER = "OTHER",
}

export enum ProjectTaskType {
    WORKTRACKING = "WORKTRACKING",
    VACATIONTRACKING = "VACATIONTRACKING",
}

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_EMPLOYEE = "ROLE_EMPLOYEE",
}

export enum JobTitle {
    P1 = "P1",
    P2 = "P2",
    M1 = "M1",
    M2 = "M2",
    S1 = "S1",
    S2 = "S2",
}
