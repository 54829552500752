import React from "react";
import LoginForm from "./components/LoginForm";
import { Box, Stack } from "@mui/material";

export const LoginPage = () => {
  return (
        <Box alignSelf={"center"} justifySelf={"center"}>
            <Stack direction={"column"} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                <Box display={"flex"} justifyContent={"center"}>
                    <h1>Company Manager</h1>
                </Box>
                <Box sx={{ m: 2 }}>
                    <LoginForm/>
                </Box>
            </Stack>
        </Box>
  );
};

export default LoginPage;
