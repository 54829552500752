import React, { FC } from "react";
import { AdminProps } from "../../components/interfaces/interface";
import { UpsertEmployeeVacationDto } from "./types";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { getAllEmployeePersonsTable } from "../Employees/services/EmployeeServices";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  deleteEmployeeVacation,
  getRecordsByYear,
  getThisYearCredits,
  getThisYearRecords
} from "./services/VacationReportServices";
import { Role, StateFrom } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { OvertimeDto } from "../Overtimes/types";
import { forwardOvertimes, getOvertimesForYear, getThisTrimestarHours } from "../Overtimes/services/OvertimeServices";
import { useAuthContext } from "../../context/AuthContext";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { EmployeeTableDto } from "../../ts-types/api.types";

const VacationReportsPage: FC<AdminProps> = (props): JSX.Element => {
  const history = useHistory();
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeTableDto | null>();
  const [employees, setEmployees] = React.useState<Array<EmployeeTableDto>>([]);
  const [vacationReports, setVacationReports] = React.useState<Array<UpsertEmployeeVacationDto>>([]);
  const { t } = useTranslation("company-manager");
  const { user } = useAuthContext();
  const [thisYearCredits, setThisYearCredits] = React.useState<number>();
  const [thisTrimestarHours, setTrimestarHours] = React.useState<number>();
  const [overtimes, setOvertime] = React.useState<Array<OvertimeDto>>([]);
  const [selectedYear, setSelectedYear] = React.useState<string>(moment().format("YYYY"));
  const { state } = useLocation<any>();

  React.useEffect(() => {
    if (state?.employeeVacationSelectedEmployee) {
      loadData(state.employeeVacationSelectedEmployee);
      loadOvertimes(state.employeeVacationSelectedEmployee);
    } else {
      loadData(user?.employeeId);
      loadOvertimes(user?.employeeId);
    }
    loadWorkingHours();
  }, []);
  const loadData = (employeeId: number | undefined) => {
    loadEmployees(employeeId);
    getThisYearRecords(cancelTokenSource, employeeId)
      .then(r => setVacationReports(r));
    getThisYearCredits(cancelTokenSource, employeeId)
      .then(setThisYearCredits);
  };

  const loadEmployees = (employeeId: number | undefined) => {
    let stateEmployeeId = 0;
    if (state !== undefined && state.employeeVacationSelectedEmployee !== undefined) {
      stateEmployeeId = state.employeeVacationSelectedEmployee;
    }
    getAllEmployeePersonsTable(false, cancelTokenSource)
      .then(r => {
        setEmployees(r);
        let newSelectedEmployee: EmployeeTableDto = r[0];
        if (employeeId == null) {
          getThisYearCredits(cancelTokenSource, r[0].id)
            .then(setThisYearCredits);
        }
        r.forEach((employee) => {
          if (employee.id === employeeId) {
            if (stateEmployeeId !== 0 && stateEmployeeId === employee.id) {
              newSelectedEmployee = employee;
            } else if (employee.id === user?.employeeId) {
              newSelectedEmployee = employee;
            }
          }
          setSelectedEmployee(newSelectedEmployee);
        });
      });
  };

  const handleDateChange = (date: Date) => {
    setSelectedYear(moment(date).clone().format("YYYY-MM-DD"));
    const year = parseInt(moment(date).clone().format("YYYY"));
    if (!isNaN(year) && user?.employeeId && selectedEmployee) {
      getRecordsByYear(cancelTokenSource, year, selectedEmployee.id)
        .then(r => setVacationReports(r));
      getOvertimesForYear(cancelTokenSource, selectedEmployee.id, parseInt(moment(date).clone().format("YYYY")))
        .then(r => setOvertime(r));
    }
  };

  const loadOvertimes = (employeeId: number | undefined) => {
    if (employeeId) {
      getOvertimesForYear(cancelTokenSource, employeeId, parseInt(moment(selectedYear).clone().format("YYYY")))
        .then(r => setOvertime(r));
    }
  };

  const loadWorkingHours = () => {
    getThisTrimestarHours(0, cancelTokenSource)
      .then(r => setTrimestarHours(r));
  };

  const handleEmployeeChange = (newValue: EmployeeTableDto) => {
    if (newValue.id != null) {
      setSelectedEmployee(newValue);
      getRecordsByYear(cancelTokenSource, parseInt(moment(selectedYear).format("YYYY")), newValue.id)
        .then(r => setVacationReports(r));
      getThisYearCredits(cancelTokenSource, newValue.id)
        .then(setThisYearCredits);
      getOvertimesForYear(
        cancelTokenSource,
        newValue.id,
        parseInt(moment(selectedYear).clone().format("YYYY-MM-DD"))
      )
        .then(r => setOvertime(r));
    }
  };

  const handleDelete = (id :number) => {
    deleteEmployeeVacation(id, cancelTokenSource)
      .then(() => setVacationReports(vacationReports.filter(vacationReport => {
        return vacationReport.id !== id;
      })));
  };

  const renderVacations = (): JSX.Element => {
    const days = ({ rowData }: any) => {
      return (
          <>{rowData.days}</>
      );
    };
    const actions = ({ rowData }: any) => {
      const vacationId = rowData.id;
      return (
          <Box width={100}>
             <Button
                    sx={{ m: 1 }}
                    variant={"outlined"}
                    disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                    onClick={() =>
                      history.push("/edit-employee-vacation",
                        {
                          employeeId: selectedEmployee ? selectedEmployee.id : 0,
                          id: vacationId,
                          view: props.view,
                          search: props.search,
                          from: StateFrom.EDIT
                        })}>
                  {t("common.edit")}
                </Button>
            <YNPopper
                message={t("common.delete")}
                color={"error"}
                variant={"contained"}
                icon={<DeleteIcon/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => handleDelete(rowData.id)}/>
          </Box>
      );
    };

    const vacationsRowGetter = ({ index }: any) => {
      Object.assign(vacationReports[index], { index: index + 1 });
      return vacationReports[index];
    };

    const vacationsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };
      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const columns: any = [
      {
        width: 100,
        label: "#",
        dataKey: "index"
      },
      {
        width: 300,
        label: t("common.date"),
        dataKey: "creationDate"
      },
      {
        width: 200,
        label: t("common.days"),
        dataKey: "days",
        cellRenderer: (days)
      },
      {
        width: 400,
        label: t("common.description"),
        dataKey: "remark"
      },
      {
        width: 150,
        flexGrow: 1,
        label: t("common.type"),
        dataKey: "type"
      }
    ];

    columns.push({
      width: 225,
      label: t("common.actions"),
      dataKey: "id",
      cellRenderer: (actions)
    });

    return (
        <VirtualizedTable
            rowCount={vacationReports.length}
            rowGetter={vacationsRowGetter}
            rowRenderer={vacationsRowRenderer}
            columns={columns}
        />
    );
  };

  const renderOverview = (): JSX.Element => {
    const durationCells = ({ rowData }: any) => {
      return (
          <>
            <div>
              {rowData.hours}h
            </div>
          </>
      );
    };
    // const actions = ({ rowData }: any) => {
    //   return (
    //       <>
    //         <Button
    //             sx={{ m: 1 }}
    //             variant={"outlined"}
    //             disabled={props?.role === "ROLE_EMPLOYEE"}
    //             >
    //           {t("common.edit")}
    //         </Button>
    //         <YNPopper
    //             message={t("common.delete")}
    //             color={"error"}
    //             variant={"contained"}
    //             icon={<DeleteIcon/>}
    //             disabled={props.role === "ROLE_EMPLOYEE"}
    //             onConfirm={() => handleDelete(rowData.id)}/>
    //       </>
    //   );
    // };

    const overtimeRowGetter = ({ index }: any) => {
      Object.assign(overtimes[index], { index: index + 1 });
      return overtimes[index];
    };

    const overtimeRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const columns: any = [
      {
        width: 100,
        label: "#",
        dataKey: "index"
      },
      {
        width: 300,
        label: t("common.date"),
        dataKey: "creationDate"
      },
      {
        width: 200,
        label: t("common.duration"),
        dataKey: "hours",
        cellRenderer: (durationCells)
      },
      {
        width: 400,
        label: t("common.remark"),
        dataKey: "remark"
      },
      {
        width: 150,
        flexGrow: 1,
        label: t("common.type"),
        dataKey: "type"
      }
    ];

    // if (props.role === "ROLE_ADMIN") {
    //   columns.push({
    //     width: 400,
    //     flexGrow: 1,
    //     label: t("common.actions"),
    //     dataKey: "id",
    //     cellRenderer: (actions)
    //   });
    // }
    return (
        <VirtualizedTable
            rowCount={overtimes.length}
            rowGetter={overtimeRowGetter}
            rowRenderer={overtimeRowRenderer}
            columns={columns}
        />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((vacationReports.length * rowHeight) + 25, maxTableHeight);
  const overviewTableHeight = Math.min((overtimes.length * rowHeight) + 25, maxTableHeight);
  const userRoles = user?.roles;
  return (
      <Box>
        <>
          <Stack direction={"column"}>
            <h3><i>{t("common.vacationReports")}</i></h3>
            <Box>
              <Stack direction={"row"} sx={{ display: "flex", justifyContent: "space-between" }}>
                {selectedEmployee
                  ? <Box sx={{ display: "flex", flexDirection: "row", fontSize: 15, alignItems: "center" }}>
                  <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={employees}
                      getOptionLabel={(option: EmployeeTableDto) => `${option.firstName + " " + option.lastName}`}
                      value={selectedEmployee || null}
                      onChange={(event: any, newValue: EmployeeTableDto) => {
                        handleEmployeeChange(newValue);
                      }}
                      sx={{ width: 357, m: 2, mx: "9px" }}
                      renderInput={(params) =>
                          <TextField {...params} label={t("common.employees")}/>}
                  />
                    <Box marginX={4} width={130}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          className="datePicker"
                          label={t("common.year")}
                          inputFormat="YYYY"
                          value={selectedYear}
                          openTo="year"
                          views={["year"]}
                          onChange={handleDateChange}
                          renderInput={(params) => <TextField {...params}/>}
                        />
                      </LocalizationProvider>
                    </Box>
                  <Box sx={{ ml: 2 }}>
                    {t("text.availableDays")} {thisYearCredits}
                  </Box>
                </Box>
                  : <> </>}
                <Box>
                  {userRoles?.includes(Role.ROLE_ADMIN)
                    ? <Button
                          variant={"contained"}
                          onClick={() =>
                            history.push("/add-employee-vacation", {
                              employeeId: selectedEmployee ? selectedEmployee.id : 0,
                              search: props.search,
                              view: props.view,
                              from: StateFrom.ADD
                            })}>
                        {t("button.addVacation")}
                      </Button>
                    : <></> }

                </Box>
              </Stack>
            </Box>
            <Box style={{ height: tableHeight }}>
              {renderVacations()}
            </Box>
          </Stack>

           <Stack direction={"column"}>
             <Stack direction={"row"} alignItems={"center"} margin={2} justifyContent={"space-between"}>
               <Stack direction={"row"} alignItems={"center"}>
            <h3><i>{t("common.overview")}</i></h3>
               </Stack>
               <Box>
                 <Button
                     sx={{ marginX: 2 }}
                     variant={"contained"}
                     disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                     onClick={() => forwardOvertimes(cancelTokenSource, parseInt(moment(selectedYear).clone().format("YYYY")))}>
                   {t("button.transferOvertimes")}
                 </Button>
                 <Button
                     variant={"contained"}
                     disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                     onClick={() =>
                       history.push("/overtime", {
                         search: props.search,
                         view: props.view,
                         from: StateFrom.ADD,
                         employeeId: selectedEmployee ? selectedEmployee.id : 0
                       })}>
                   {t("button.addOvertime")}
                 </Button>
               </Box>
             </Stack>
            <Box style={{ height: overviewTableHeight }}>
              {renderOverview()}
            </Box>
              <Stack direction={"row"} style={{
                width: 487,
                display: "flex",
                justifyContent: "space-between",
                fontSize: 15,
                borderBottom: "solid 1px #ebeef2",
                fontWeight: 3.5,
                height: 44.5,
                borderTop: "solid 1px #ebeef2",
                marginTop: 3
              }}>
                <Box style={{ height: 44.5, alignItems: "center", display: "flex" }}>
                  {t("common.totalOvertimeHours")}
                </Box>
                <Box style={{ height: 44.5, alignItems: "center", display: "flex" }}>
                   {thisTrimestarHours}
                </Box>
              </Stack>
           </Stack>
        </>
      </Box>
  );
};
export default VacationReportsPage;
