import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import axios from "axios";
import { UpsertPublicVacationDto } from "../types";
import { StateFrom } from "../../../components/interfaces/enums";
import {
  addPublicVacation,
  deletePublicVacation,
  editPublicVacation,
  getPublicVacation
} from "../services/PublicVacationServices";
import { Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import styled from "styled-components";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";

const DatePickerClass = styled.div`
    .datePicker {
        width: 250px;
    }
`;

const AddEditPublicVacation = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const [form, setForm] = React.useState<UpsertPublicVacationDto>({
    date: moment().format("YYYY-MM-DD"),
    description: ""
  });

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.ADD:
        setMessage(t("header.addPublicVacation"));
        break;
      case StateFrom.EDIT:
        setMessage(t("header.editPublicVacation"));
        getPublicVacation(state.publicVacationId, cancelTokenSource)
          .then(r => setForm(r));
        break;
    }
  }, []);

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addPublicVacation({ ...form, date: moment(form.date).format("YYYY-MM-DD") }, cancelTokenSource)
          .then(() => history.push("/public-vacation"));
        break;
      case StateFrom.EDIT:
        editPublicVacation(form, state.publicVacationId, cancelTokenSource)
          .then(() => history.push("/public-vacation"));
    }
  };

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDateChange = (response: moment.Moment) => {
    setForm({ ...form, date: moment(response).format("YYYY-MM-DD") });
  };

  return (
      <Box>
      <h1>{message}</h1>
      <FormControl>
        <Stack direction={"row"}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePickerClass style={{ display: "flex", alignItems: "center" }}>
          <DesktopDatePicker
              className="datePicker"
              label={t("common.date")}
              inputFormat="DD/MM/YYYY"
              value={form.date}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
          />
          </DatePickerClass>
        </LocalizationProvider>
      <TextField
          name={"description"}
          sx={{ width: 250, m: 2 }}
          label={t("common.description")}
          value={form.description}
          onChange={handleChange}/>
        </Stack>
  <Stack direction={"row"} justifyContent={"right"}>
    <Button
        sx={{ m: 1 }}
        variant={"outlined"}
        type={"submit"}
        disabled={state?.from === StateFrom.DETAILS}
        onClick={() => onFormSubmit()}>
      {t("common.save")}
    </Button>
    <Box sx={{ m: 1 }}>
      {state.from !== StateFrom.ADD
        ? <YNPopper
              message={t("common.delete")}
              color={"error"}
              variant={"contained"}
              icon={<DeleteIcon/>}
              disabled={state.role === "ROLE_EMPLOYEE" || state.from === StateFrom.DETAILS}
              onConfirm={() => {
                deletePublicVacation(state.publicVacationId, cancelTokenSource)
                  .then(() => history.push("/public-vacation"));
              }}/>
        : <> </>}
    </Box>
    <Button sx={{ m: 1 }}
            variant={"outlined"}
            onClick={() => history.push("/public-vacation")}>
      {t("common.back")}
    </Button>
  </Stack>
      </FormControl>
      </Box>
  );
};
export default AddEditPublicVacation;
