import { styled } from "@mui/material";

const CalendarRoot = styled("div")`
  .selected-container {
    background-color: #b0bec5 !important;
  }
  .not-in-month {
    color: rgb(150,150,150);
  }
  .calendar-cells {
    padding: 3px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .today {
    border: solid;
    border-radius: 30px;
    display: flex;
    width: 35px;
    justify-content: center;
  }
  .weekend {
    background: rgb(210,210,210);
  }
  .day {
    display: flex;
    justify-content: flex-end;
  }
  .timetrack {
    display: flex;
    justify-content: center;
    color: white;
    background-color: green;
    border: none;
    border-radius: 10px;
    height: 25px;
    align-items: center;
  }
  .timetrackbellow {
    display: flex;
    justify-content: center;
    color: white;
    background-color: lightgreen;
    border: none;
    border-radius: 10px;
    height: 25px;
    align-items: center;
  }
  .timetrackabove {
    display: flex;
    justify-content: center;
    color: white;
    background-color: red;
    border: none;
    border-radius: 10px;
    height: 25px;
    align-items: center;
  }
  .weekDay {
    .calendar-cells {
       border-top: 1px solid silver;
       border-left: 1px solid silver;
       border-right: none;
       border-bottom: none;
     }
    .calendar-cells:nth-child(6){
      border-left: none;
    }
    &:last-child{
      .calendar-cells {
        border-bottom: 1px solid silver;
      }
    }
    &:first-child {
      .calendar-cells:first-child{
        border-top-left-radius: 20px;
      }
      .calendar-cells:nth-child(7){
        border-top-right-radius: 20px;
      }
    }
    &:last-child {
      .calendar-cells:first-child{
        border-bottom-left-radius: 20px;
      }
      .calendar-cells:last-child{
        border-bottom-right-radius: 20px;
      }
    }
  }
`;

const SelectedContainer = styled("div")`
  background-color: #b0bec5 !important;
`;

const CalendarStyled = Object.assign(CalendarRoot, { SelectedContainer });

export default CalendarStyled;
