import {
  Autocomplete,
  Box, Button, Checkbox, DialogContent, FormControl,
  IconButton, InputAdornment,
  InputLabel, MenuItem, Modal, OutlinedInput,
  Select,
  Stack,
  styled,
  TextareaAutosize,
  TextField
} from "@mui/material";
import React, { ChangeEvent } from "react";
import {
  BillDto,
  BillExportDto,
  CompanyAddressTableDto,
  CompanyBankTableDto, CompanyDto,
  ProjectTableDto, RequestBillsDto
} from "../../ts-types/api.types";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getAllCompanyBanks } from "../CompanyBank/services/CompanyBankServices";
import { getAllCompanyAddressForCompany } from "../CompanyAddress/services/CompanyAddressServices";
import { getAllCompanies, getProjectsForParticularCompany } from "../Companies/services/CompanyServices";
import { AddressType, BillCurrency } from "../../ts-types/api.enums";
import * as _ from "lodash";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReworkingReportDateKey } from "../TimeTrackings/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment/moment";
import { getBillsFromAccountedTimeTracks } from "../AccountedWorkingReports/services/AccountedWorkingReportServices";
import { downloadPreviewBillPdf } from "./services/InvoiceServices";

const Body = styled("div")`
display: flex;
  flex-direction: column;
  textarea {
    font-size: 15px;
  }
`;

const boxInModal = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 834,
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0
};

const buttonInModal = {
  variant: "contained",
  mt: 2,
  width: "222px"
};

const ThisTable = styled("table")`
  font-size: 15px;
  border-spacing: 0px;
  .tableRow {
    td {
      width: 50%;
    }
    tr {
      margin: 0px;
      height: 15px;

      td:nth-of-type(1) {
        text-align: right;
      }
    }
  }
  td {
    padding-inline: 5px;
  }
  textarea {
    font-size: 15px;
  }
  .cityAreaSize {
    width: 130px;
  }
  .zipAreaSize {
    width: 60px;
  }
`;

const BillToTable = styled("table")`
  font-size: 15px;
  border-spacing: 0px;
  td {
    width: 25%;
    padding-inline: 5px;
  }
`;

const BillsTable = styled("table")`
  width: 100%;
  font-size: 15px;
  border-spacing: 0px;
  td {
    padding-inline: 5px;
    border: 1px solid silver;
  }
  thead {
    font-size: 20px;
  }
`;

const InitialCompanyAddress: CompanyAddressTableDto = {
  addressType: AddressType.BILLING_ADDRESS,
  city: "",
  companyId: 0,
  country: "",
  deleted: false,
  email: "",
  isDefaultAddress: false,
  nameOne: "",
  nameTwo: "",
  streetName: "",
  telephone: "",
  zip: "",
  id: 0
};

const InitialCompanyBank: CompanyBankTableDto = {
  accountNo: "",
  bank: "",
  bankAddress: "",
  companyId: 0,
  defaultBank: false,
  deleted: false,
  ibanCode: "",
  id: 0,
  swift: ""
};

const InitialBillDto: BillDto = {
  description: "", index: 0, total: 0, unitPrice: 0, units: 0
};

const ExportInvoice = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation("company-manager");
  const [projects, setProjects] = React.useState<Array<ProjectTableDto>>([]);
  const [companies, setCompanies] = React.useState<Array<CompanyDto>>([]);
  const [companyBanks, setCompanyBanks] = React.useState<Array<CompanyBankTableDto>>([]);
  const [companyAddresses, setCompanyAddresses] = React.useState<Array<CompanyAddressTableDto>>([]);
  const [senderCompanyAddresses, setSenderCompanyAddresses] = React.useState<Array<CompanyAddressTableDto>>([]);
  const [selectedSenderCompanyBank, setSelectedSenderCompanyBank] = React.useState<Partial<CompanyBankTableDto>>({ });
  const [selectedCompanyAddress, setSelectedCompanyAddress] = React.useState<Partial<CompanyAddressTableDto>>({ });
  const [selectedSenderCompany, setSelectedSenderCompany] = React.useState<CompanyDto>();
  const [selectedReceiverCompany, setSelectedReceiverCompany] = React.useState<CompanyDto>();
  const [selectedSenderCompanyAddress, setSelectedSenderCompanyAddress] = React.useState<Partial<CompanyAddressTableDto>>({ });
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<Blob | MediaSource>(new Blob());
  const [showViewerPassword, setShowViewerPassword] = React.useState(false);
  const [showOwnerPassword, setShowOwnerPassword] = React.useState(false);
  const [requestBillsDto, setRequestBillsDto] = React.useState<RequestBillsDto>({
    dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    projects: [],
    dateTo: moment().endOf("month").format("YYYY-MM-DD")
  });
  const [embedUrl, setEmbedUrl] = React.useState<string|undefined>(undefined);
  const [form, setForm] = React.useState<BillExportDto>({
    ownerPassword: "",
    viewerPassword: "",
    invoiceFrom: "",
    billCurrency: BillCurrency.EUR,
    bills: [],
    companyAddress: InitialCompanyAddress,
    companyBank: InitialCompanyBank,
    companyId: 0,
    invoiceBy: "",
    invoiceDate: "",
    invoiceNumber: "",
    paymentTerms: "",
    rebatePercentage: 0,
    senderCompanyAddress: InitialCompanyAddress,
    servicePeriod: "",
    vatPercentage: 0
  });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  React.useEffect(() => {
    loadCompanies();
  }, []);

  React.useEffect(() => {
    const blobUrl = URL.createObjectURL(file);
    setEmbedUrl(blobUrl);

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [file]);

  const loadProjects = (company: CompanyDto) => {
    getProjectsForParticularCompany(company.id, cancelTokenSource)
      .then(r => {
        setProjects(r);
      });
  };

  const loadCompanies = () => {
    getAllCompanies("", cancelTokenSource)
      .then(r => setCompanies(r));
  };

  const loadCompanyBanks = (company: CompanyDto) => {
    getAllCompanyBanks(company.id, cancelTokenSource)
      .then(r => {
        if (r.length !== 0) {
          setCompanyBanks(r);
          setSelectedSenderCompanyBank(r[0]);
          getAllCompanyAddressForCompany(company.id, cancelTokenSource)
            .then(r1 => {
              if (r1.length !== 0) {
                setSenderCompanyAddresses(r1);
                setSelectedSenderCompanyAddress(r1[0]);
                setForm({ ...form, senderCompanyAddress: r1[0], companyBank: r[0], invoiceFrom: r1[0].city });
              }
            });
        }
      });
  };

  const loadCompanyAddresses = (company: CompanyDto) => {
    getAllCompanyAddressForCompany(company.id, cancelTokenSource)
      .then(r => {
        setCompanyAddresses(r);
        setSelectedCompanyAddress(r[0]);
        setForm({ ...form, companyAddress: r[0] });
      });
  };

  const addNewBillRecord = () => {
    const newBills = [...form.bills];

    newBills.push({ ...InitialBillDto, index: newBills.length });

    setForm({ ...form, bills: newBills });
  };

  const handleFormChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCurrencyChange = (e: any) => {
    setForm({ ...form, billCurrency: e.target.value });
  };

  const handleSenderCompanyFormChange = (e: any) => {
    setForm({ ...form, senderCompanyAddress: { ...form.senderCompanyAddress, [e.target.name]: e.target.value } });
  };

  const handleReceiverCompanyFormChange = (e: any) => {
    setForm({ ...form, companyAddress: { ...form.companyAddress, [e.target.name]: e.target.value } });
  };

  const handleBillsFormChange = ({ target: { name, value } }: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newFormData = { ...form };

    _.set(
      newFormData,
        `bills[${index}][${name}]`,
        value
    );

    setForm(newFormData);
  };

  const handleDateChange = (dateKey: keyof ReworkingReportDateKey) => (value: string) => {
    setRequestBillsDto({
      ...requestBillsDto,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
  };

  const handlePreview = () => {
    downloadPreviewBillPdf(form, cancelTokenSource, true)
      .then(r => {
        const file = new Blob(
          [r],
          { type: "application/pdf" });
        setFile(file);
      });
    openModal();
  };

  const onClickGetBills = () => {
    getBillsFromAccountedTimeTracks(requestBillsDto, cancelTokenSource)
      .then(r => setForm({ ...form, bills: r }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleClickShowViewerPassword = () => {
    setShowViewerPassword(!showViewerPassword);
  };

  const handleClickShowOwnerPassword = () => {
    setShowOwnerPassword(!showOwnerPassword);
  };

  const handleSenderCompanyBankFormChange = (e: any) => {
    setForm({ ...form, companyBank: { ...form.companyBank, [e.target.name]: e.target.value } });
  };

  const deleteBillRecord = (index: number) => {
    const newBills = [...form.bills];
    if (index > -1) {
      newBills.splice(index, 1);
    }
    setForm({ ...form, bills: newBills });
  };

  return (
      <div className={Body}>
        <Stack direction={"row"}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ m: 2 }}>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("legend.from")}
                  inputFormat="DD/MM/YYYY"
                  value={requestBillsDto.dateFrom}
                  onChange={handleDateChange("dateFrom")}
                  renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ m: 2 }}>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("legend.to")}
                  inputFormat="DD/MM/YYYY"
                  value={requestBillsDto.dateTo}
                  onChange={handleDateChange("dateTo")}
                  renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </Stack>
        <h2 style={{ margin: 0 }}>{t("header.senderCompany")}</h2>
        <Stack direction={"row"}>
          <Autocomplete
              disablePortal
              options={companies}
              getOptionLabel={(option: CompanyDto) => option.name}
              value={selectedSenderCompany}
              onChange={(event: any, newValue: CompanyDto) => {
                setSelectedSenderCompany(newValue);
                loadCompanyBanks(newValue);
              }}
              sx={{ width: 357, m: 1, mx: "9px" }}
              renderInput={(params) =>
                  <TextField {...params} label={t("common.companies")}/>}
          />
          <Autocomplete
              disablePortal
              options={senderCompanyAddresses}
              getOptionLabel={(option: CompanyAddressTableDto) => option !== undefined && option.streetName
                ? option.streetName
                : ""}
              value={selectedSenderCompanyAddress}
              onChange={(event: any, newValue: CompanyAddressTableDto) => {
                setSelectedSenderCompanyAddress(newValue);
                setForm({ ...form, senderCompanyAddress: newValue });
              }}
              sx={{ width: 357, m: 1, mx: "9px" }}
              renderInput={(params) =>
                  <TextField {...params} label={t("legend.companyAddresses")}/>}
          />
          <Autocomplete
              disablePortal
              options={companyBanks}
              getOptionLabel={(option: CompanyBankTableDto) => option !== undefined && option.bank
                ? option.bank
                : ""}
              value={selectedSenderCompanyBank}
              onChange={(event: any, newValue: CompanyBankTableDto) => {
                setSelectedSenderCompanyBank(newValue);
                setForm({ ...form, companyBank: newValue });
              }}
              sx={{ width: 357, m: 1, mx: "9px" }}
              renderInput={(params) =>
                  <TextField {...params} label={t("legend.companyBanks")}/>}
          />
          <FormControl>
            <InputLabel sx={{ alignSelf: "center", m: 1, ml: 1.45 }}>{t("legend.currency")}</InputLabel>
            <Select
                size={"medium"}
                sx={{ m: 1, ml: 1.45, width: "100px" }}
                label={t("legend.currency")}
                value={form.billCurrency}
                onChange={handleCurrencyChange}>
              <MenuItem key={1} value={BillCurrency.EUR}>
                {t("option.EUR")}
              </MenuItem>
              <MenuItem key={2} value={BillCurrency.CHF}>
                {t("option.CHF")}
              </MenuItem>
              <MenuItem key={3} value={BillCurrency.MKD}>
                {t("option.MKD")}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <h2 style={{ margin: 0 }}>{t("header.billTo")}</h2>
        <Stack direction={"row"}>
          <Autocomplete
              disablePortal
              options={companies}
              getOptionLabel={(option: CompanyDto) => option.name}
              value={selectedReceiverCompany}
              onChange={(event: any, newValue: CompanyDto) => {
                setSelectedReceiverCompany(newValue);
                loadCompanyAddresses(newValue);
                loadProjects(newValue);
              }}
              sx={{ width: 357, m: 1, mx: "9px" }}
              renderInput={(params) =>
                  <TextField {...params} label={t("common.companies")}/>}
          />
        <Autocomplete
            disablePortal
            options={companyAddresses}
            getOptionLabel={(option: CompanyAddressTableDto) => option !== undefined && option.streetName
              ? option.streetName
              : ""}
            value={selectedCompanyAddress}
            onChange={(event: any, newValue: CompanyAddressTableDto) => {
              setSelectedCompanyAddress(newValue);
              setForm({ ...form, companyAddress: newValue });
            }}
            sx={{ width: 357, m: 1, mx: "9px" }}
            renderInput={(params) =>
                <TextField {...params} label={t("legend.companyAddresses")}/>}
        />
          <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={projects}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                setRequestBillsDto({ ...requestBillsDto, projects: newValue });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                  </li>
              )}
              sx={{ width: 357, m: 1, mx: "9px" }}
              renderInput={(params) => (
                  <TextField {...params} label={t("common.projects")} />
              )}
          />
          <Button onClick={onClickGetBills}>
            {t("common.getBills")}
          </Button>
        </Stack>
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} width={"820px"} padding={"55px"} flexDirection={"column"}>
          <Box sx={{ border: "1px solid silver", borderBottom: "5px solid #1d333d" }}>
          <Stack direction={"row"} sx={{ height: "70px", backgroundColor: "#1d333d", width: "100%" }}>
            <img width={"30%"} src={"CodelogiqLogo.png"} alt={"Codelogiq Logo"}/>
            <Box sx={{
              fontSize: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }} color={"#ffffff"}>
              INVOICE
            </Box>
          </Stack>
          <Box sx={{ display: "flex", height: "70px", alignItems: "center", borderBottom: "1px solid silver", paddingLeft: 3 }}>
            {form?.senderCompanyAddress.nameOne}
          </Box>
          <ThisTable style={{ borderBottom: "5px solid #1d333d", width: "100%" }}>
            <tr>
              <td width={"33%"} style={{ borderRight: "1px solid silver" }}>
                <Stack>
                  <b>Make payment to:</b>
                  <div><TextareaAutosize
                      name={"nameOne"}
                      maxRows={1}
                      value={form.senderCompanyAddress.nameOne}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                  <div><TextareaAutosize
                      name={"streetName"}
                      maxRows={1}
                      value={form.senderCompanyAddress.streetName}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: 60 }}><TextareaAutosize
                        className={"zipAreaSize"}
                      name={"zip"}
                      maxRows={1}
                      value={form.senderCompanyAddress.zip}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div><TextareaAutosize
                      className={"cityAreaSize"}
                      name={"city"}
                      maxRows={1}
                      value={form.senderCompanyAddress.city}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                  <div><TextareaAutosize
                      name={"country"}
                      maxRows={1}
                      value={form.senderCompanyAddress.country}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                  <br/>
                  <div><TextareaAutosize
                      name={"telephone"}
                      maxRows={1}
                      value={form.senderCompanyAddress.telephone}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                  <div><TextareaAutosize
                      name={"email"}
                      maxRows={1}
                      value={form.senderCompanyAddress.email}
                      onChange={handleSenderCompanyFormChange}
                      style={{ border: "none", outline: "none", resize: "none" }}
                  /></div>
                </Stack>
              </td>
              <td className={"tableRow"} width={"67%"}>
                <tr style={{ width: "100%" }}>
                  <td width={"40%"}>Account No.:</td>
                  <td width={"60%"}><TextareaAutosize
                      name={"accountNo"}
                      maxRows={1}
                      value={form.companyBank.accountNo}
                      onChange={handleSenderCompanyBankFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "250px" }}
                  /></td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td width={"40%"}>IBAN Code:</td>
                  <td width={"60%"}><TextareaAutosize
                      name={"ibanCode"}
                      maxRows={1}
                      value={form.companyBank.ibanCode}
                      onChange={handleSenderCompanyBankFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "250px" }}
                  /></td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td width={"40%"}>Bank:</td>
                  <td width={"60%"}><TextareaAutosize
                      name={"bank"}
                      maxRows={1}
                      value={form.companyBank.bank}
                      onChange={handleSenderCompanyBankFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "250px" }}
                  /></td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td width={"40%"}>Bank Address:</td>
                  <td style={{ width: "60%" }}><TextareaAutosize
                      name={"bankAddress"}
                      maxRows={2}
                      value={form.companyBank.bankAddress}
                      onChange={handleSenderCompanyBankFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "250px" }}
                  /></td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td></td>
                  <td></td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td width={"40%"}>BIC (SWIFT)</td>
                  <td width={"60%"}><TextareaAutosize
                      name={"swift"}
                      maxRows={1}
                      value={form.companyBank.swift}
                      onChange={handleSenderCompanyBankFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "250px" }}
                  /></td>
                </tr>
              </td>
            </tr>
          </ThisTable>
            <Box>
              <BillToTable>
                <tr>
                  <td width={"25%"} style={{ borderRight: "1px solid silver" }}>
                    <Stack sx={{ textAlign: "right", width: "100%" }}>
                      <Box>Invoice Number:</Box>
                      <Box>Invoice Date:</Box>
                      <Box>Service Period:</Box>
                    </Stack>
                  </td>
                  <td width={"25%"} style={{ borderRight: "1px solid silver" }}>
                    <Stack>
                    <TextareaAutosize
                        name={"invoiceNumber"}
                        maxRows={1}
                        value={form.invoiceNumber}
                        onChange={handleFormChange}
                        style={{ border: "none", outline: "none", resize: "none" }}/>
                      <TextareaAutosize
                          name={"invoiceDate"}
                          maxRows={1}
                          value={form.invoiceDate}
                          onChange={handleFormChange}
                          style={{ border: "none", outline: "none", resize: "none" }}/>
                      <TextareaAutosize
                          name={"servicePeriod"}
                          maxRows={1}
                          value={form.servicePeriod}
                          onChange={handleFormChange}
                          style={{ border: "none", outline: "none", resize: "none" }}/>
                    </Stack>
                  </td>
                  <td style={{
                    textAlign: "right",
                    alignItems: "top",
                    width: "25%",
                    borderRight: "1px solid silver",
                    verticalAlign: 0
                  }}>
                    <b>Bill To:</b>
                  </td>
                  <td width={"25%"}>
                    <TextareaAutosize
                        name={"nameOne"}
                        maxRows={1}
                        value={form.companyAddress.nameOne}
                        onChange={handleReceiverCompanyFormChange}
                        style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/>
                    <TextareaAutosize
                        name={"nameTwo"}
                        maxRows={1}
                        value={form.companyAddress.nameTwo}
                        onChange={handleReceiverCompanyFormChange}
                        style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/>
                    <TextareaAutosize
                        name={"streetName"}
                        maxRows={1}
                        value={form.companyAddress.streetName}
                        onChange={handleReceiverCompanyFormChange}
                        style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/>
                    <Stack direction={"row"}>
                      <TextareaAutosize
                          name={"zip"}
                          maxRows={1}
                          value={form.companyAddress.zip}
                          onChange={handleReceiverCompanyFormChange}
                          style={{ border: "none", outline: "none", resize: "none", width: "60px" }}/>
                      <TextareaAutosize
                          name={"city"}
                          maxRows={1}
                          value={form.companyAddress.city}
                          onChange={handleReceiverCompanyFormChange}
                          style={{ border: "none", outline: "none", resize: "none" }}/>
                    </Stack>
                  </td>
                </tr>
              </BillToTable>
            </Box>
        </Box>
          <Box sx={{ marginTop: "10px", width: "100%", border: "1px solid silver" }}>
            <BillsTable>
              <thead>
              <tr style={{ backgroundColor: "#1d333d", color: "white", width: "100%" }}>
                <td>Description</td>
                <td style={{ fontSize: "15px" }}>
                  <Stack direction={"column"}>
                    <Box textAlign={"center"}> UNITS</Box>
                    <Box textAlign={"center"}>(person days)</Box>
                  </Stack>
                </td>
                <td style={{ fontSize: "15px" }}>
                  <Stack direction={"column"}>
                    <Box textAlign={"center"}>UNIT PRICE</Box>
                    <Box textAlign={"center"}>({t("billCurrency." + form.billCurrency)})</Box>
                  </Stack>
                </td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              {form.bills.map((bill, index) => {
                return (
                <tr key={index}>
                  <td style={{ width: "53%" }}><TextareaAutosize
                      name={"description"}
                      maxRows={4}
                      value={form.bills[index].description}
                      onChange={e => handleBillsFormChange(e, index)}
                      style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/></td>
                  <td width={"20%"}><TextareaAutosize
                      name={"units"}
                      maxRows={1}
                      defaultValue={undefined}
                      value={form.bills[index].units}
                      onChange={e => handleBillsFormChange(e, index)}
                      style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/></td>
                  <td width={"20%"}><TextareaAutosize
                      name={"unitPrice"}
                      maxRows={1}
                      value={form.bills[index].unitPrice}
                      defaultValue={undefined}
                      onChange={e => handleBillsFormChange(e, index)}
                      style={{ border: "none", outline: "none", resize: "none", width: "100%" }}/></td>
                  <td width={"7%"}><IconButton onClick={() => deleteBillRecord(index)}>
                    <RemoveIcon/>
                  </IconButton></td>
                </tr>
                );
              })}
              </tbody>
            </BillsTable>
            <Box sx={{ display: "flex", justifyContent: "end", width: "100%", paddingRight: "6px" }}>
              <table style={{ fontSize: "15px", marginRight: "5px", borderSpacing: 0 }}>
                <tr>
                  <td style={{
                    textAlign: "right",
                    border: "1px solid silver",
                    padding: "5px",
                    backgroundColor: "#1d333d",
                    color: "white"
                  }}>Rebate:</td>
                  <td style={{ border: "1px solid silver", padding: "5px" }}>
                    <Box display={"flex"} alignItems={"center"}>
                    <TextareaAutosize
                      name={"rebatePercentage"}
                      maxRows={1}
                      value={form.rebatePercentage}
                      defaultValue={undefined}
                      onChange={handleFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "30px" }}/>
                      %
                    </Box>
                    </td>
                </tr>
                <tr>
                  <td style={{
                    textAlign: "right",
                    border: "1px solid silver",
                    padding: "5px",
                    backgroundColor: "#1d333d",
                    color: "white"
                  }}>VAT:</td>
                  <td style={{ border: "1px solid silver", padding: "5px" }}>
                    <Box display={"flex"} alignItems={"center"}>
                    <TextareaAutosize
                      name={"vatPercentage"}
                      maxRows={1}
                      value={form.vatPercentage}
                      defaultValue={undefined}
                      onChange={handleFormChange}
                      style={{ border: "none", outline: "none", resize: "none", width: "30px" }}/>
                      %
                    </Box>
                    </td>
                </tr>
              </table>
              <IconButton onClick={addNewBillRecord}><AddIcon/></IconButton>
            </Box>
          </Box>
          <Box sx={{ marginTop: "10px", width: "100%" }}>
            <table style={{ width: "100%", fontSize: "15px" }}>
              <tr>
                <td style={{ width: "50%", border: "1px solid silver", paddingLeft: "5px" }}>
                  Payment terms: <TextareaAutosize
                    name={"paymentTerms"}
                    maxRows={2}
                    value={form.paymentTerms}
                    defaultValue={undefined}
                    onChange={handleFormChange}
                    style={{ border: "none", outline: "none", resize: "none" }}/>
                </td>
                <td style={{ width: "50%", border: "1px solid silver", borderLeft: "none", textAlign: "center" }}>
                  <Stack direction={"column"}>
                    <Box>Invoiced by:</Box>
                    <TextareaAutosize
                        name={"invoiceBy"}
                        maxRows={1}
                        value={form.invoiceBy}
                        defaultValue={undefined}
                        onChange={handleFormChange}
                        style={{ border: "none", outline: "none", resize: "none", textAlign: "center" }}/>
                    <Box display={"flex"} alignItems={"center"}>
                      <TextareaAutosize
                          name={"invoiceFrom"}
                          maxRows={1}
                          value={form.invoiceFrom}
                          defaultValue={undefined}
                          onChange={handleFormChange}
                          style={{ border: "none", outline: "none", resize: "none", fontSize: "15px", textAlign: "right" }}/>
                      , {form.invoiceDate}
                    </Box>
                  </Stack>
                </td>
              </tr>
            </table>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "right", paddingTop: "10px" }}>
            <Button variant={"contained"} onClick={() => handlePreview()}>
              {t("button.preview")}
            </Button>
          </Box>
        </Box>
        </Box>
        <Box>
          <Modal open={modalOpen}>
            <Box sx={boxInModal}>
              <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 0 }}>
                <Stack direction={"row"} width={"100%"}>
                  <Box>
                    <embed
                        src={embedUrl}
                        width="600px"
                        height="830px"
                        type="application/pdf"
                    />
                  </Box>
                  <Box display={"flex"}
                       justifyContent={"center"}
                       alignItems={"center"}
                       flexDirection={"column"}
                       paddingLeft={"25px"}>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">{t("label.viewerPassword")}</InputLabel>
                      <OutlinedInput
                          autoComplete={"off"}
                          id="outlined-adornment-password"
                          type={showViewerPassword ? "text" : "password"}
                          name={"viewerPassword"}
                          onChange={handleFormChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowViewerPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                {showViewerPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label={t("label.viewerPassword")}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">{t("label.ownerPassword")}</InputLabel>
                      <OutlinedInput
                          autoComplete={"off"}
                          id="outlined-adornment-password"
                          type={showOwnerPassword ? "text" : "password"}
                          name={"ownerPassword"}
                          onChange={handleFormChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowOwnerPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                {showOwnerPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label={t("label.ownerPassword")}
                      />
                    </FormControl>
                    <Button sx={buttonInModal}
                            variant={"contained"}
                            onClick={() => downloadPreviewBillPdf(form, cancelTokenSource, false)}
                            color={"success"}>
                      {t("common.download")}
                    </Button>
                    <Button sx={buttonInModal}
                            variant={"contained"}
                            color={"error"}
                    onClick={() => {
                      setForm({ ...form, ownerPassword: "", viewerPassword: "" });
                      setModalOpen(false);
                    }}>
                      {t("common.cancel")}
                    </Button>
                  </Box>
                </Stack>
              </DialogContent>
            </Box>
          </Modal>
        </Box>
      </div>
  );
};
export default ExportInvoice;
