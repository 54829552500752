import React, { FC } from "react";
import axios from "axios";
import AddHoliday from "./AddHoliday";
import EditHoliday from "./EditHoliday";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from "@mui/material";
import { HolidayProps } from "../../../components/interfaces/interface";
import { HolidayStatus } from "../../../components/interfaces/enums";
import { deleteById, getAllHolidays, getAllHolidaysForTeamLeader } from "../services/HolidayServices";
import YNPopper from "../../../components/Poppers/YNPopper";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { HolidayDto } from "../../../ts-types/api.types";
import { useTranslation } from "react-i18next";
import ApproveDeclineHolidays from "./ApproveDeclineHolidays";
import { useAuthContext } from "../../../context/AuthContext";
import { getEmployeeByPersonId } from "../../Employees/services/EmployeeServices";
import { useHistory } from "react-router-dom";

const HolidayList: FC<HolidayProps> = (props): JSX.Element => {
  const { user } = useAuthContext();
  const cancelTokenSource = React.useMemo(axios.CancelToken.source, []);
  const [holidays, setHolidays] = React.useState<Array<HolidayDto>>([]);
  const [status, setStatus] = React.useState <HolidayStatus>(HolidayStatus.ALL);
  const [openAddHolidayModal, setOpenAddHolidayModal] = React.useState<boolean>(false);
  const [openEditHolidayModal, setOpenEditHolidayModal] = React.useState<boolean>(false);
  const [selectedId, setSelectedId] = React.useState<number>();
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [teamLeaderHolidays, setTeamLeaderHolidays] = React.useState<Array<HolidayDto>>();
  const [isTeamLeader, setIsTeamLeader] = React.useState<boolean>(false);
  const { t } = useTranslation("company-manager");
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const holidayRequestId = queryParams.get("holiday_request_id");

  React.useEffect(() => {
    loadHolidays();
    getAllHolidaysForTeamLead(HolidayStatus.WAITING_ON_APPROVAL);
    checkIsTeamLeader();
  }, []);

  const handleChangeSelectButtonHoliday = (e: SelectChangeEvent) => {
    setStatus(e.target.value as HolidayStatus);
  };

  const onClickEdit = React.useCallback((id: number) => {
    setSelectedId(id);
    setOpenEditHolidayModal(true);
  }, []);

  const loadHolidays = React.useCallback(() => {
    setDataLoaded(false);
    getAllHolidays(cancelTokenSource)
      .then((data: Array<HolidayDto>) => {
        setHolidays(data);
      })
      .finally(() => setDataLoaded(true));
  }, [cancelTokenSource]);

  const deleteHoliday = React.useCallback((id: number) => {
    deleteById(id, cancelTokenSource)
      .then(() => {
        loadHolidays();
        props.onChange(HolidayStatus.WAITING_ON_APPROVAL);
      });
  }, [cancelTokenSource]);

  const onHolidaySave = React.useCallback((holidayDto: HolidayDto) => {
    setHolidays([holidayDto, ...holidays]);
    setOpenAddHolidayModal(false);
    props.onChange(HolidayStatus.WAITING_ON_APPROVAL);
  }, [holidays]);

  const onHolidayEdit = React.useCallback((modifiedHolidayDto: HolidayDto) => {
    const newHolidays = [...holidays];
    const foundIndex = newHolidays.findIndex(holidayDto => holidayDto.id === modifiedHolidayDto.id);

    if (foundIndex !== -1) {
      newHolidays[foundIndex] = modifiedHolidayDto;
    }
    setHolidays(newHolidays);
    setOpenEditHolidayModal(false);
    props.onChange(HolidayStatus.WAITING_ON_APPROVAL);
  }, [holidays]);

  const getAllHolidaysForTeamLead = React.useCallback((status: HolidayStatus) => {
    getAllHolidaysForTeamLeader(status, cancelTokenSource)
      .then((data: Array<HolidayDto>) => {
        setTeamLeaderHolidays(data);
      });
  }, [teamLeaderHolidays]);

  const checkIsTeamLeader = React.useCallback(() => {
    getEmployeeByPersonId(user?.personId, cancelTokenSource)
      .then((r) => {
        if (r.teamLeader) {
          setIsTeamLeader(true);
        }
      });
  }, []);

  const renderHolidaysTable = (): JSX.Element => {
    let newHolidayMap = holidays;
    if (status !== HolidayStatus.ALL) {
      newHolidayMap = newHolidayMap.filter(r => r.status === status);
    }
    const ModifyDelete = ({ rowData }: any) => {
      return (
              <Box width={100}>
                  <Button
                      disabled={rowData.status === "APPROVED" ||
                          rowData.status === "DECLINED" || rowData.status === "PAST_HOLIDAY"}
                      variant={"contained"}
                      sx={{ mx: 1 }}
                      onClick={() => onClickEdit(rowData.id)}>
                      {t("common.edit")}
                  </Button>
                  <YNPopper
                      onConfirm={() => deleteHoliday(rowData.id)}
                      message={t("common.delete")}
                      disabled={rowData.status === "APPROVED" ||
                          rowData.status === "DECLINED" || rowData.status === "PAST_HOLIDAY"}
                      icon={<DeleteIcon/>}
                      color={"error"}
                      variant={"contained"}
                  />
              </Box>
      );
    };

    const translatedValues = ({ rowData } : any) => {
      return t("option." + rowData.status);
    };

    const holidaysRowGetter = ({ index }: any) => {
      Object.assign(newHolidayMap[index], { index: index + 1 });

      return newHolidayMap[index];
    };

    const holidaysRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
              <div
                  {...a11yProps}
                  className={className}
                  key={key}
                  role='row'
                  style={style}
              >
                  {columns}
              </div>
      );
    };
    return (
          <VirtualizedTable
              rowCount={newHolidayMap.length}
              rowGetter={holidaysRowGetter}
              rowRenderer={holidaysRowRenderer}
              columns={[
                {
                  width: 50,
                  label: "#",
                  dataKey: "index"
                },
                {
                  width: 200,
                  label: t("tableHeader.requestedBy"),
                  dataKey: "shortNameRequestedBy"
                },
                {
                  width: 200,
                  label: t("tableHeader.requestedOn"),
                  dataKey: "requestedOn"
                },
                {
                  width: 200,
                  label: t("tableHeader.startingDate"),
                  dataKey: "startingDate"
                },
                {
                  width: 100,
                  label: t("common.days"),
                  dataKey: "days"
                },
                {
                  width: 250,
                  label: t("tableHeader.approvedBy"),
                  dataKey: "shortNameApprovedBy"
                },
                {
                  width: 250,
                  label: t("tableHeader.approvedOn"),
                  dataKey: "approvedOn"
                },
                {
                  width: 200,
                  label: t("tableHeader.type"),
                  dataKey: "type"
                },
                {
                  width: 200,
                  flexGrow: 1,
                  label: t("tableHeader.status"),
                  dataKey: "status",
                  cellRenderer: (translatedValues)
                },
                {
                  width: 300,
                  label: t("common.actions"),
                  dataKey: "id",
                  cellRenderer: (ModifyDelete)
                }
              ]}
          />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((holidays.length * rowHeight) + 25, maxTableHeight);
  return (
        <>
            {isTeamLeader &&
                <ApproveDeclineHolidays holidays = {teamLeaderHolidays}
                                        holidayRequestId={holidayRequestId ? parseInt(holidayRequestId, 10) : 0}
                                        loadHolidays={(holidayStatus) => getAllHolidaysForTeamLead(holidayStatus)}/>}
            {dataLoaded
              ? <>
            <form>
                <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3, my: 4 }} >
                    <Stack direction={"row"}>
                        <FormControl>
                        <InputLabel sx={{ alignSelf: "center" }}>Holiday Status</InputLabel>
                        <Select id="status"
                                name="status"
                                value={status}
                                size={"small"}
                                label="HolidayStatus"
                                onChange={handleChangeSelectButtonHoliday}>

                            <MenuItem value={HolidayStatus.ALL}>
                                No status selected
                            </MenuItem>

                            <MenuItem value={HolidayStatus.WAITING_ON_APPROVAL}>

                                Waiting on approval
                            </MenuItem>

                            <MenuItem value={HolidayStatus.APPROVED}>

                                Approved
                            </MenuItem>

                            <MenuItem value={HolidayStatus.DECLINED}>

                                Declined
                            </MenuItem>

                            <MenuItem value={HolidayStatus.PAST_HOLIDAY}>

                                Past Holiday
                            </MenuItem>

                        </Select>
                        </FormControl>
                    </Stack>
                    <Button variant={"contained"} size={"small"}
                            onClick={() => setOpenAddHolidayModal(true)}>
                        Add new holiday</Button>
                </Stack>
            </form>
            <Box style={{ height: tableHeight }}>
                {renderHolidaysTable()}
            </Box>

            <EditHoliday
                onSave={onHolidayEdit}
                show={openEditHolidayModal}
                close={() => setOpenEditHolidayModal(false)}
                id={selectedId}/>
            <AddHoliday
                onSave={onHolidaySave}
                show={openAddHolidayModal}
                close={() => setOpenAddHolidayModal(false)}/>
                </>
              : <CircularProgress />
            }
        </>
  );
};
export default HolidayList;
