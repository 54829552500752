import React, { useCallback } from "react";
import axios from "axios";
import { getHolidaysTable } from "./services/HolidayServices";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  FormControlLabel, FormLabel, Radio,
  RadioGroup,
  Stack,
  TableContainer,
  TextField,
  Tooltip
} from "@mui/material";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { EmployeeHolidayTableByWeek } from "../../ts-types/api.types";
import { DateKey } from "./types";

enum Calendar {
  YEAR= "year",
  MONTH= "month",
  DATE = "date"
}

const HolidaysReportTable = () => {
  const InitiaDateKey: DateKey = {
    dateTo: moment().format("YYYY-MM-DD"),
    dateFrom: moment().format("YYYY-MM-DD")
  };

  const [employeeHolidays, setEmployeeHolidays] = React.useState<EmployeeHolidayTableByWeek>({
    employeeHolidayData: [],
    mondays: []
  });
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [formDateChange, setFormDateChange] = React.useState<DateKey>(InitiaDateKey);
  const [filterBy, setFilterBy] = React.useState<Calendar>(Calendar.YEAR);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadHolidays();
  }, []);

  const loadHolidays = () => {
    getHolidaysTable(moment(formDateChange.dateFrom).format("YYYY-01-01"), cancelTokenSource, moment(formDateChange.dateTo).format("YYYY-12-28"))
      .then((data) => {
        setEmployeeHolidays(data);
      });
  };
  const handleDateChange = (dateKey: keyof DateKey) => (date: Date) => {
    setFormDateChange({
      ...formDateChange,
      [dateKey]: moment(date).format("YYYY-MM-DD")
    });
    let formatDateFrom = "YYYY-MM-DD";
    let formatDateTo = "YYYY-MM-DD";
    let dateFrom = moment(date).format(formatDateFrom);
    let dateTo = moment(date).format(formatDateTo);
    switch (filterBy) {
      case Calendar.YEAR:
      {
        formatDateFrom = "YYYY-01-01";
        formatDateTo = "YYYY-12-28";
        break;
      }
      case Calendar.MONTH:
      {
        formatDateFrom = "YYYY-MM-01";
        formatDateTo = "YYYY-MM-28";
        break;
      }
      case Calendar.DATE:
      {
        formatDateFrom = "YYYY-MM-DD";
        formatDateTo = "YYYY-MM-DD";
        dateFrom = formDateChange.dateFrom;
        dateTo = formDateChange.dateTo;
        break;
      }
    }
    getHolidaysTable(moment(dateFrom)
      .format(formatDateFrom), cancelTokenSource, moment(dateTo).format(formatDateTo))
      .then(r => setEmployeeHolidays(r));
  };
  const filterData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy(event.target.value as Calendar);
  };

  const renderEmployeeHolidayTable = useCallback((): JSX.Element => {
    const mondays = employeeHolidays.mondays.sort();
    const dateHeader = (text: string) => {
      return (
        text === "TOTAL"
          ? <Stack direction={"column"}>
                <Box sx={{ display: "flex", justifyContent: "left", paddingLeft: 1, fontSize: 10 }}>
                  {t("common.total")}
                </Box>
              </Stack>
          : <Stack direction={"column"}>
                <Box sx={{ display: "flex", justifyContent: "left", paddingLeft: 1, fontSize: 10 }}>
                  {text}
                </Box>
              </Stack>
      );
    };

    const dateCell = (monday: string) => (data: any) => {
      const { rowData } = data;

      if (rowData.data[monday]) {
        const days = rowData.data[monday].requestedVacationDays;
        const color = rowData.data[monday].color;
        return (
            <Tooltip title={ color !== "WHITE" && t(`common.${color}`)}>
              <Box sx={{ width: "40px", display: "flex", justifyContent: "space-around", backgroundColor: `${color}` }}>
                {days}
              </Box>
            </Tooltip>

        );
      }
      return "";
    };
    const employeeHeader = () => {
      return (
          <Box display={"flex"} alignItems={"end"} justifyContent={"left"} fontSize={12}>
            {t("common.name")}
          </Box>
      );
    };
    const HeaderWithTooltip = ({ label, tooltip } : any) => {
      return (
          <div>
            {
              <Tooltip title={label} placement="top" componentsProps={{
                tooltip: {
                  sx: {
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid black",
                    borderRadius: "0"
                  }
                }
              }}>
                <span data-tip={tooltip}>{label}</span>
              </Tooltip>
            }
          </div>
      );
    };

    const CellWithToolTip = ({ rowData }: any) => {
      return (
        <div>
          {
            <Tooltip title={rowData.fullName} placement="top" componentsProps={{
              tooltip: {
                sx: {
                  color: "black",
                  backgroundColor: "white",
                  border: "1px solid black",
                  borderRadius: "0"
                }
              }
            }}>
              <span>{rowData.shortName}</span>
            </Tooltip>
          }
        </div>
      );
    };

    const columns: any = [
      {
        width: 50, label: employeeHeader(), dataKey: "shortName", cellRenderer: (CellWithToolTip)

      }, {
        width: 60, label: t("text.availableDays"), dataKey: "availableDays"
      }];

    if (mondays && mondays.length) {
      mondays.forEach((monday) => {
        columns.push({
          width: 50,
          label: dateHeader(monday),
          dataKey: "#",
          cellRenderer: (dateCell(monday)),
          headerRenderer: (HeaderWithTooltip)
        });
      });
    }

    const employeeHolidayRowGetter = ({ index }: any) => {
      Object.assign(employeeHolidays.employeeHolidayData[index], { index: index + 1 });

      return employeeHolidays.employeeHolidayData[index];
    };

    const employeeHolidayRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
              <div
                  {...a11yProps}
                  className={className}
                  key={key}
                  role='row'
                  style={style}
              >
                  {columns}
              </div>
      );
    };

    return (
          <VirtualizedTable
              rowCount={employeeHolidays.employeeHolidayData.length}
              rowGetter={employeeHolidayRowGetter}
              width={(employeeHolidays.mondays.length + 2) * 50}
              rowRenderer={employeeHolidayRowRenderer}
              columns={columns}>

          </VirtualizedTable>);
  }, [employeeHolidays]);

  const rowHeight = 48;
  const tableHeight = (employeeHolidays.employeeHolidayData.length * rowHeight) + 50;
  return (<Box>

    <Stack direction={"row"} alignItems={"center"} mb={4}>
      <h3><i>{t("option.holidays")}</i></h3>
      <FormControl sx= {{ paddingLeft: 5 }}>
        <FormLabel id="demo-radio-buttons-group-label">{t("common.filterBy")}</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={filterBy}
          name="radio-buttons-group"
          row={true}
          onChange={filterData}
        >
          <FormControlLabel value={Calendar.YEAR} control={<Radio />} label={t("common.year")} />
          <FormControlLabel value={Calendar.MONTH} control={<Radio />} label={t("common.month")} />
          <FormControlLabel value={Calendar.DATE} control={<Radio />} label={t("common.date")}/>
        </RadioGroup>
      </FormControl>
      {filterBy !== Calendar.DATE
        ? <Box marginX={4} width={130}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              className="datePicker"
              label={t("common.year")}
              inputFormat={filterBy === Calendar.YEAR ? "YYYY" : "MM/YYYY" }
              value={formDateChange.dateFrom}
              views={filterBy === Calendar.YEAR ? [Calendar.YEAR] : [Calendar.YEAR, Calendar.MONTH]}
              onChange={handleDateChange("dateFrom")}
              renderInput={(params) => <TextField {...params}/>}
            />
          </LocalizationProvider>
        </Box>
        : <Stack direction={"row"}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ m: 2, width: 150, marginX: 4 }}>
              <DesktopDatePicker
                className="datePicker"
                label={t("legend.from")}
                inputFormat="DD/MM/YYYY"
                value={formDateChange.dateFrom}
                onChange={handleDateChange("dateFrom")}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ m: 2, width: 150, marginX: 4 }}>
                <DesktopDatePicker
                  className="datePicker"
                  label={t("legend.to")}
                  inputFormat="DD/MM/YYYY"
                  value={formDateChange.dateTo}
                  onChange={handleDateChange("dateTo")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
        </Stack>
      }

    </Stack>
    <TableContainer
        sx={{
          maxWidth: 3000,
          height: tableHeight
        }}
        style={{ overflowY: "hidden" }}
    >
      {renderEmployeeHolidayTable()}
    </TableContainer>
  </Box>);
};
export default HolidaysReportTable;
