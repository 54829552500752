import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App/App";
import "react-virtualized/styles.css";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "./i18n";
import "./sentry";
import "./styles.css";
import i18n from "i18next";
import { supportedLanguages } from "./utils/localizationUtils";
import { Box } from "@mui/material";

/**
 * Set dummy locale namespace to prevent unnecessary calls for fetching resources.
 */
supportedLanguages.forEach((lang: string) => {
  i18n.addResourceBundle(lang, "default", {
    key: "value"
  }, true, true);
});

const Loader = () => (
  <Box sx={{ margin: "2rem auto", color: "#777777" }}>Loading...</Box>
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Loader />}>
        <CssBaseline/>
        <App/>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
