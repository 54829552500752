import React from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthContext } from "../../context/AuthContext";
import { deleteProjectTask, getAllProjectTasksByProjectId } from "./services/ProjectTaskServices";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { emptyTableCell } from "../../utils/tableUtils";
import DoneIconComponent from "../../components/StyleComponents/DoneIconComponent";
import { ProjectTaskDto } from "../../ts-types/api.types";
import { ProjectTaskProps } from "./types";

const ProjectTaskPage = (props:ProjectTaskProps): JSX.Element => {
  const { user } = useAuthContext();
  const { state } = useLocation<any>();
  const history = useHistory();
  const { t } = useTranslation("company-manager");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [projectTasks, setProjectTasks] = React.useState<Array<ProjectTaskDto>>([]);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    loadProjectTasks();
  }, []);

  const loadProjectTasks = React.useCallback(async () => {
    setDataLoaded(false);
    try {
      const response = await getAllProjectTasksByProjectId(props.projectId, cancelTokenSource);
      setProjectTasks(response);
    } finally {
      setDataLoaded(true);
    }
  }, [projectTasks, state.search]);

  const renderProjectTaskTable = () => {
    const actions = ({ rowData }: any) => {
      const projectTaskId = rowData.id;
      return (
          <Box width={100}>
            <Button
                sx={{ my: 1, mr: 1 }}
                variant={"outlined"}
                onClick={() =>
                  history.push("/projecttask", {
                    projectTaskId,
                    view: state.view,
                    search: state.search,
                    from: StateFrom.DETAILS,
                    projectId: props.projectId
                  })}>
              {t("common.details")}
            </Button>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/projecttask", {
                    projectTaskId,
                    view: state.view,
                    search: state.search,
                    from: StateFrom.EDIT,
                    projectId: props.projectId
                  })}>
              {t("common.edit")}
            </Button>
            <YNPopper
                message={t("common.delete")}
                color={"error"}
                variant={"contained"}
                icon={<DeleteIcon/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => {
                  deleteProjectTask(projectTaskId, cancelTokenSource)
                    .then(loadProjectTasks);
                }}/>
          </Box>
      );
    };
    const projectTasksRowGetter = ({ index }: any) => {
      Object.assign(projectTasks[index], { index: index + 1 });
      return projectTasks[index];
    };

    const projectTasksRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const statusCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <DoneIconComponent/>;
      }
      return emptyTableCell();
    };

    return (
        <VirtualizedTable
            rowCount={projectTasks.length}
            rowGetter={projectTasksRowGetter}
            rowRenderer={projectTasksRowRenderer}
            columns={[
              {
                width: 100,
                label: "#",
                dataKey: "index"
              },
              {
                width: 500,
                label: t("common.description"),
                dataKey: "description"
              },
              {
                width: 350,
                label: t("legend.type"),
                dataKey: "type"
              },
              {
                width: 150,
                flexGrow: 1,
                label: t("tableHeader.status"),
                dataKey: "active",
                cellRenderer: (statusCellRenderer)
              },
              {
                width: 325,
                label: t("common.actions"),
                dataKey: "id",
                cellRenderer: (actions)
              }
            ]}
        />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((projectTasks.length * rowHeight) + 25, maxTableHeight);

  return (
      <Box>
        {dataLoaded
          ? <>
              <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3><i>{t("header.projectTasks")}</i></h3>
                <Stack alignSelf={"center"}>
                  <Button
                      variant={"contained"}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onClick={() =>
                        history.push("/projecttask", {
                          search: state.search,
                          view: state.view,
                          from: StateFrom.ADD,
                          projectId: props.projectId
                        })}>
                    {t("button.addProjectTask")}
                  </Button>
                </Stack>
              </Stack>
              <Box style={{ height: tableHeight }}>
                {renderProjectTaskTable()}
              </Box>
            </>
          : <CircularProgress />
        }
      </Box>
  );
};
export default ProjectTaskPage;
