import React, { useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Alert,
  Box,
  Button, Collapse,
  FormControl, IconButton, InputLabel, MenuItem, Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { StateFrom } from "../../../components/interfaces/enums";
import { CompanyDto } from "../types";
import {
  addCompany,
  disableCompany,
  editCompany, enableCompany,
  getCompany,
  getProjectsForParticularCompany
} from "../services/CompanyServices";
import { getAllCompanyPersons } from "../../CompanyPerson/services/CompanyPersonServices";
import CompanyProjectTable from "./CompanyProjectTable";
import { useTranslation } from "react-i18next";
import { Check, ExpandMore } from "@mui/icons-material";
import CompanyPersonTable from "../../CompanyPerson/CompanyPersonPage";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyAddressTable from "../../CompanyAddress/CompanyAddressPage";
import CompanyBankTable from "../../CompanyBank/CompanyBankPage";
import { getAllCompanyBanks } from "../../CompanyBank/services/CompanyBankServices";
import { getAllCompanyAddressForCompany } from "../../CompanyAddress/services/CompanyAddressServices";
import Countries from "../../../components/interfaces/Countries.json";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";
import {
  CompanyAddressTableDto,
  CompanyBankTableDto,
  CompanyPersonTableDto,
  CompanyProjectTableDto
} from "../../../ts-types/api.types";

const InitialCompanyDto: CompanyDto = {
  name: "",
  shortName: "",
  address: "",
  city: "",
  isoCode: "",
  email: "",
  phoneOffice: "",
  zip: "",
  url: "",
  active: true
};

const AddEditCompany = () => {
  const [message, setMessage] = React.useState<string>("");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { state } = useLocation<any>();
  const [company, setCompany] = React.useState<CompanyDto>(InitialCompanyDto);
  const [companyAddresses, setCompanyAddresses] = React.useState<Array<CompanyAddressTableDto>>([]);
  const [companyBanks, setCompanyBanks] = React.useState<Array<CompanyBankTableDto>>([]);
  const [companyPersons, setCompanyPersons] = React.useState<Array<CompanyPersonTableDto>>([]);
  const [companyProjects, setCompanyProjects] = React.useState<Array<CompanyProjectTableDto>>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        loadCompanyAndCompanyPersons();
        setMessage(t("header.editCompany"));
        break;
      case StateFrom.DETAILS:
        loadCompanyAndCompanyPersons();
        setMessage(t("header.companyDetails"));
        break;
      case StateFrom.ADD:
        setMessage(t("header.addCompany"));
        break;
    }
  }, []);

  const handleChange = (e: any) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value
    });
  };

  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.SHORT_NAME_NOT_VALID];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }

      setErrorMessage(errMsgs);
    }
  };

  const loadCompanyAndCompanyPersons = React.useCallback(() => {
    getCompany(state.id, cancelTokenSource)
      .then(r => {
        setCompany(r);
      });
    getAllCompanyPersons(state.id, cancelTokenSource)
      .then(r => {
        setCompanyPersons(r);
      });
    getProjectsForParticularCompany(state.id, cancelTokenSource)
      .then(r => {
        setCompanyProjects(r);
      });
    getAllCompanyBanks(state.id, cancelTokenSource)
      .then(r => {
        setCompanyBanks(r);
      });
    getAllCompanyAddressForCompany(state.id, cancelTokenSource)
      .then(r => {
        setCompanyAddresses(r);
      });
  }, [company, companyProjects, companyPersons, companyBanks, companyAddresses]);

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addCompany(company, cancelTokenSource)
          .then(() => history.push("/company"))
          .catch((e) => handleError(e.response.data));
        break;
      case StateFrom.EDIT:
        editCompany(company, state.id, cancelTokenSource)
          .then(() => history.push("/company"))
          .catch((e) => handleError(e.response.data));
        break;
    }
  };

  return (
      <>
        <h2>{message}</h2>
        <Collapse in={!!errorMessage}>
          <Alert
              severity={"error"}
              action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorMessage(undefined);
                    }}
                >
                  <CloseIcon fontSize="inherit"/>
                </IconButton>
              }
              sx={{ mb: 2 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
      <FormControl>
        <Box display={"flex"} flexDirection = {"row"} >
        <Stack direction={"column"}>
          <Stack direction={"row"}>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"name"}
            value={company.name}
            label={t("common.name")}
            onChange={handleChange}/>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"shortName"}
            value={company.shortName}
            label={t("common.shortName")}
            onChange={handleChange}/>
         <FormControl>
          <InputLabel sx={{ width: 250, m: 2 }} id="selectCountry">
            Country
          </InputLabel>
          <Select
              size={"small"}
              sx={{ minWidth: 220, m: 1, ml: 2, py: 1 }}
              labelId="selectCountry"
              id="selectCountry"
              name="isoCode"
              label={t("header.country")}
              value={company.isoCode ? company.isoCode : "//"}
              onChange={handleChange}>
            {Countries.map((country, index) => {
              return (<MenuItem key={index}
                                value={country.isoCode}>
                    {country.name} ({country.dialCode})
                  </MenuItem>);
            })}
          </Select>
         </FormControl>
        </Stack>
          <Stack direction={"row"}>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"zip"}
            value={company.zip}
            label={t("legend.zip")}
            onChange={handleChange}/>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"city"}
            value={company.city}
            label={t("common.city")}
            onChange={handleChange}/>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"address"}
            value={company.address}
            label={t("common.address")}
            onChange={handleChange}/>
          </Stack>
            <Stack direction={"row"}>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"phoneOffice"}
            value={company.phoneOffice}
            label={t("common.phoneOffice")}
            onChange={handleChange}/>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"email"}
            value={company.email}
            label={t("common.email")}
            onChange={handleChange}/>
        <TextField
            sx={{ m: 1, mx: 2 }}
            name={"url"}
            value={company.url}
            label={t("common.url")}
            onChange={handleChange}/>
            </Stack>
          <Stack direction={"row"} justifyContent={"right"}>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                type={"submit"}
                disabled={state?.from === StateFrom.DETAILS}
                onClick={onFormSubmit}>
              {t("common.save")}
            </Button>
            <Box sx={{ m: 1 }}>
              {state.from !== StateFrom.ADD
                ? (company.active
                    ? <YNPopper
                      message={t("common.disable")}
                      color={"error"}
                      variant={"contained"}
                      icon={<DeleteIcon/>}
                      disabled={state.role === "ROLE_EMPLOYEE" || state?.from === StateFrom.DETAILS}
                      onConfirm={() => {
                        disableCompany(state.id, cancelTokenSource);
                        history.push("/company");
                      }}/>
                    : <YNPopper
                      message={t("common.enable")}
                      color={"success"}
                      variant={"contained"}
                      icon={<Check/>}
                      disabled={state.role === "ROLE_EMPLOYEE" || state?.from === StateFrom.DETAILS}
                      onConfirm={() => {
                        enableCompany(state.id, cancelTokenSource);
                        history.push("/company");
                      }}/>
                  )
                : <> </>}
            </Box>
            <Button sx={{ m: 1 }}
                    variant={"outlined"}
                    onClick={() => history.push("/company")}>
              {t("common.back")}
            </Button>
          </Stack>
        </Stack>
        </Box>
      </FormControl>
        {state.from === StateFrom.DETAILS
          ? <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Typography><i>{t("header.companyProjects") + " (" + companyProjects.length + ")"}</i></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyProjectTable
                      search={state.search}
                      id={state.id}
                      companyProjects = {companyProjects}
                      view={state.view}/>
                </AccordionDetails>
              </Accordion>
            </>
          : <></>}
        {state.from === StateFrom.DETAILS &&
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Typography><i>{t("header.companyPersons") + " (" + companyPersons.length + ")"} </i></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyPersonTable
                      search={state.search}
                      id={state.id}
                      companyPersons={companyPersons}
                      view={state.view}/>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {state.from === StateFrom.DETAILS &&
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Typography><i>{t("header.companyBanks") + " (" + companyBanks.length + ")"}</i></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyBankTable
                      search={state.search}
                      id={state.id}
                      companyBanks = {companyBanks}
                      view={state.view}/>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {state.from === StateFrom.DETAILS
          ? <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Typography><i>{t("header.companyAddresses") + " (" + companyAddresses.length + ")"}</i></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyAddressTable
                      search={state.search}
                      id={state.id}
                      companyAddresses = {companyAddresses}
                      companyName={company.name}
                      view={state.view}/>
                </AccordionDetails>
              </Accordion>
            </>
          : <></>}
      </>
  );
};
export default AddEditCompany;
