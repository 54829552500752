import React from "react";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { RelationType, StateFrom } from "../../../components/interfaces/enums";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { addEmployeePerson, editEmployeePerson, getEmployeePerson } from "../services/EmployeePersonServices";
import { getAllPersons, getPerson } from "../../Persons/services/PersonServices";
import { PersonDto } from "../../Persons/types";
import { EmployeePersonDto } from "../types";
import { useTranslation } from "react-i18next";

const InitialEmployeePerson: EmployeePersonDto = {
  id: 0,
  personId: 0,
  employeeId: 0,
  relationType: RelationType.RESPONSIBLE_FOR
};

const InitialPerson : PersonDto = {
  address: "",
  city: "",
  email: "",
  shortName: "",
  firstName: "",
  id: 0,
  isoCode: "",
  lastName: "",
  mobile: "",
  otherMobile: "",
  zip: "",
  lastLogin: "",
  active: true,
  imageData: undefined
};
const AddEditEmployeePerson = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const history = useHistory();
  const { state } = useLocation<any>();
  const [employeePerson, setEmployeePerson] = React.useState<EmployeePersonDto>(InitialEmployeePerson);

  const [persons, setPersons] = React.useState<Array<PersonDto>>([]);
  const { t } = useTranslation("company-manager");
  const [selectedOption, setSelectedOption] = React.useState<PersonDto>(InitialPerson);

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editEmployeePerson"));
        loadEmployeePerson();
        loadPersons();
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.employeePersonDetails"));
        loadEmployeePerson();
        loadPersons();
        break;
      case StateFrom.ADD:
        loadPersons();
        setMessage(t("header.addEmployeePerson"));
        setEmployeePerson({
          ...employeePerson,
          employeeId: state.employeeId
        });
        break;
    }
  }, []);

  const loadEmployeePerson = () => {
    getEmployeePerson(state.id, cancelTokenSource)
      .then(EmployeePersonR => {
        setEmployeePerson(EmployeePersonR);
        if (state.from === StateFrom.EDIT || state.from === StateFrom.DETAILS) {
          getPerson(EmployeePersonR.personId, cancelTokenSource)
            .then(PersonR => setSelectedOption(PersonR));
        }
      });
  };

  const loadPersons = () => {
    getAllPersons(state.search, cancelTokenSource)
      .then(r => {
        setPersons(r);
      });
  };

  const handleRelationTypeChange = (event: any) => {
    setEmployeePerson({
      ...employeePerson,
      [event.target.name]: event.target.value
    });
  };

  const handlePersonChangeSelection = async (newValue: any) => {
    setSelectedOption(newValue);
    setEmployeePerson({ ...employeePerson, personId: newValue.id });
  };
  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addEmployeePerson(employeePerson, cancelTokenSource)
          .then(() => history.push("/upsert-employee",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.employeeId }));
        break;
      case StateFrom.EDIT:
        editEmployeePerson(employeePerson, state.id, cancelTokenSource)
          .then(() => history.push("/upsert-employee",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.employeeId }));
        break;
    }
  };

  return (
        <>
            <h3>{message}</h3>
            <FormControl>
                <Stack direction={"row"}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={persons}
                        getOptionLabel={(option: PersonDto) => option.firstName + " " + option.lastName}
                        value={selectedOption}
                        onChange={(event: any, newValue?: PersonDto) => {
                          handlePersonChangeSelection(newValue);
                        }}
                        sx={{ width: 250, m: 2 }}
                        renderInput={(params) =>
                            <TextField {...params} label={t("common.persons")}/>}
                    />
                    <FormControl>
                        <InputLabel sx={{ width: 250, m: 2 }}>Relation Type</InputLabel>
                        <Select
                            sx={{ width: 250, m: 2 }}
                            name={"relationType"}
                            type="text"
                            label={t("common.relationType")}
                            value={employeePerson.relationType}
                            onChange={handleRelationTypeChange}>
                            <MenuItem key={1} value={RelationType.KNOWS}>
                                {t("option.KNOWS")}
                            </MenuItem>
                            <MenuItem key={2} value={RelationType.RESPONSIBLE_FOR}>
                                {t("option.RESPONSIBLE_FOR")}
                            </MenuItem>
                            <MenuItem key={3} value={RelationType.CONTACT_PERSON}>
                                {t("option.CONTACT_PERSON")}
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} justifyContent={"right"}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        type={"submit"}
                        disabled={state?.from === StateFrom.DETAILS || selectedOption === null || selectedOption === InitialPerson}
                        onClick={onFormSubmit}>
                        {t("common.save")}
                    </Button>
                    <Button sx={{ m: 1 }}
                            variant={"outlined"}
                            onClick={() => history.push("/upsert-employee",
                              { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.employeeId })}>
                        {t("common.back")}
                    </Button>
                </Stack>
            </FormControl>
        </>
  );
};
export default AddEditEmployeePerson;
