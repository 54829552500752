import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { PublicVacationDto, UpsertPublicVacationDto } from "../types";

const baseUrl = "/company-manager-app/api/publicvacations";

export const addPublicVacation = (
  upsertPublicVacationDto: Partial<UpsertPublicVacationDto>,
  source: CancelTokenSource
) => {
  return axios
    .post(`${baseUrl}/add`, upsertPublicVacationDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const editPublicVacation = (
  upsertPublicVacationDto: Partial<UpsertPublicVacationDto>,
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, upsertPublicVacationDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deletePublicVacation = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getPublicVacation = (id: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getPublicVacationsForMonth = (date: string, source: CancelTokenSource): Promise<Array<PublicVacationDto>> => {
  return axios
    .get(`${baseUrl}/all-for-month`, { cancelToken: source.token, params: { date } })
    .then(response => response.data);
};

export const getPublicVacationsForThisYear = (source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/all-for-this-year`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getPublicVacationForYear = (year: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/all-for-year`, { cancelToken: source.token, params: { year } })
    .then(response => response.data);
};

export const nextYearHasPublicVacations = (source: CancelTokenSource): Promise<boolean> => {
  return axios
    .get(`${baseUrl}/next-year-has-public-vacations`, { cancelToken: source.token })
    .then(response => response.data);
};

export const transferVacationsToNextYear = (source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/transfer`, { cancelToken: source.token })
    .then(response => response.data);
};
