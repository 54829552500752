import { StateFrom } from "../../../components/interfaces/enums";
import React, { useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment/moment";
import styled from "styled-components";
import { PersonDto } from "../../Persons/types";
import { getAllPersonsWithoutEmployees, getPerson } from "../../Persons/services/PersonServices";
import { addEmployee, editEmployee, getAllTeamLeaderEmployees, getEmployee } from "../services/EmployeeServices";
import EmployeeWorkTimeTable from "./EmployeeWorkTimeTable";
import { useTranslation } from "react-i18next";
import MyEmployeesTable from "./MyEmployeesTable";
import { ExpandMore } from "@mui/icons-material";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";
import { CivilStatus, JobTitle } from "../../../ts-types/api.enums";
import EmployeePersonTable from "../../EmployeePerson/EmployeePersonPage";
import { EmployeeDto, UpsertEmployeeDto, UpsertWorkTimeDto } from "../../../ts-types/api.types";
import { WorkTimeDateKey } from "../../WorkTimes/types";
import { EmployeeDateKey } from "../types";

const DatePickerClass = styled.div`
    .datePicker {
        width: 250px;
    }
`;

const initialUpsertEmployeeDtoProps: UpsertEmployeeDto = {
  personId: 0,
  shortName: "",
  civilStatus: CivilStatus.UNKNOWN,
  dateOfBirth: "",
  dateOfEntry: "",
  dateOfExit: "",
  lastWorkingDate: "",
  teamLeader: false,
  workTimes: [],
  external: false,
  teamLeaderEmployeeId: undefined
};

const InitialEmployeeDateForm: EmployeeDateKey = {
  dateOfBirth: "",
  dateOfEntry: "",
  dateOfExit: "",
  lastWorkingDate: ""
};

const InitialWorkTimeDateForm: WorkTimeDateKey = {
  validFrom: undefined,
  validTo: ""
};

const AddEditEmployee = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { state } = useLocation<any>();
  const [message, setMessage] = React.useState<string>("");
  const [formData, updateFormData] = React.useState<UpsertEmployeeDto>(initialUpsertEmployeeDtoProps);
  const [employeeDateChange, setEmployeeDateChange] = React.useState<EmployeeDateKey>(InitialEmployeeDateForm);
  const [workTimeDateChange, setWorkTimeDateChange] = React.useState<WorkTimeDateKey>(InitialWorkTimeDateForm);
  const [persons, setPersons] = React.useState<Array<PersonDto>>([]);
  const [teamLeaders, setTeamLeaders] = React.useState<Array<EmployeeDto>>([]);
  const { t } = useTranslation("company-manager");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedOption, setSelectedOption] = React.useState<PersonDto>({
    address: "",
    city: "",
    email: "",
    shortName: "",
    firstName: "",
    id: 0,
    isoCode: "",
    lastName: "",
    mobile: "",
    otherMobile: "",
    zip: "",
    lastLogin: "",
    active: true,
    imageData: undefined
  });

  const [selectedTeamLeader, setSelectedTeamLeader] = React.useState<EmployeeDto | null>(null);
  const handleShowClosed = () => {
    if (formData) {
      updateFormData({ ...formData, teamLeader: !formData.teamLeader });
    }
  };

  const toggleExternalEmployee = () => {
    if (formData) {
      updateFormData({ ...formData, external: !formData.external });
    }
  };

  const [employee, setEmployee] = React.useState<UpsertEmployeeDto>();
  const [fte, setFte] = React.useState<number>(100);
  const [credits, setCredits] = React.useState<number>(21);
  const [shortName, setShortName] = React.useState<string>("");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        getSelectedEmployeeAndUpdateForm();
        setMessage(t("header.editEmployee"));
        break;
      case StateFrom.DETAILS:
        getSelectedEmployeeAndUpdateForm();
        setMessage(t("header.employeeDetails"));
        break;
      case StateFrom.ADD:
        updateFormData(initialUpsertEmployeeDtoProps);
        setMessage(t("header.addEmployee"));
        loadAllTeamLeaders();
        break;
    }
    loadAllPersons();
  }, [state.search]);

  React.useEffect(() => {
    if (employeeDateChange.dateOfBirth === "Invalid date" ||
        employeeDateChange.dateOfExit === "Invalid date" ||
          employeeDateChange.dateOfEntry === "Invalid date" || workTimeDateChange.validFrom) {
      setErrorMessage(t("error.INVALID_DATE"));
    }
    if (employeeDateChange.dateOfBirth !== "Invalid date" &&
          employeeDateChange.dateOfExit !== "Invalid date" &&
          employeeDateChange.dateOfEntry !== "Invalid date" && workTimeDateChange.validFrom !== "Invalid date") {
      setErrorMessage(undefined);
    }
  }, [employeeDateChange.dateOfBirth, employeeDateChange.dateOfExit, employeeDateChange.dateOfEntry, workTimeDateChange.validFrom]);

  const handlePersonChangeSelection = async (newValue: any) => {
    setSelectedOption(newValue);
    setShortName(newValue.firstName.charAt(0) + newValue.lastName.charAt(0));
  };

  const handleShortNameChange = React.useCallback((e) => {
    setShortName(e.target.value);
  }, [selectedOption]);

  const handleEmployeeDateChange = (dateKey: keyof EmployeeDateKey) => (value: string) => {
    setEmployeeDateChange({
      ...employeeDateChange,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
  };

  const handleWorkTimeDateChange = (dateKey: keyof WorkTimeDateKey) => (value: string) => {
    setWorkTimeDateChange({
      ...workTimeDateChange,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
  };

  const handleTeamLeadersChangeSelection = (newValue: EmployeeDto) => {
    setSelectedTeamLeader(newValue);
  };

  const getSelectedEmployeeAndUpdateForm = React.useCallback(() => {
    getEmployee(state?.id, cancelTokenSource)
      .then((r) => {
        setEmployee(r);
        updateFormData(r);
        setShortName(r.shortName);
        setEmployeeDateChange({
          dateOfBirth: r.dateOfBirth,
          dateOfEntry: r.dateOfEntry,
          dateOfExit: r.dateOfExit,
          lastWorkingDate: r.lastWorkingDate
        });
        getPerson(r.personId, cancelTokenSource)
          .then((r) => {
            setSelectedOption(r);
          });
        loadTeamLeaders(r);
      });
  }, [employee, selectedOption]);

  const loadAllPersons = React.useCallback(() => {
    getAllPersonsWithoutEmployees(state.search, cancelTokenSource).then((r) => {
      setPersons(r);
    });
  }, [persons, cancelTokenSource, state.search]);

  const loadTeamLeaders = React.useCallback((employee: EmployeeDto) => {
    getAllTeamLeaderEmployees(state.search, cancelTokenSource).then((r) => {
      setTeamLeaders(r);
      selectTeamLeaderToolTip(r, employee);
    });
  }, [teamLeaders, cancelTokenSource, state.search]);

  const loadAllTeamLeaders = () => {
    getAllTeamLeaderEmployees(state.search, cancelTokenSource).then((teamLeaders) => {
      setTeamLeaders(teamLeaders);
      teamLeaders.forEach(teamLeader => {
        setSelectedTeamLeader(teamLeader);
      });
    });
  };

  const selectTeamLeaderToolTip = (teamLeaders: Array<EmployeeDto>, employee: EmployeeDto) => {
    if (employee?.teamLeaderEmployeeId != null) {
      teamLeaders.forEach(teamLeader => {
        if (teamLeader.id === employee.teamLeaderEmployeeId) {
          setSelectedTeamLeader(teamLeader);
        }
      });
    }
  };

  const onFormSubmit = React.useCallback((e: any) => {
    e.preventDefault();
    const newWorkTime: UpsertWorkTimeDto = {
      employeeId: state.id,
      jobTitle: JobTitle.P1,
      ...workTimeDateChange,
      fte,
      credits,
      validFrom: workTimeDateChange.validFrom as string,
      validTo: workTimeDateChange.validTo
    };
    const newWorkTimeList: Array<UpsertWorkTimeDto> = [];
    newWorkTimeList.push(newWorkTime);
    const newEmployee: Partial<UpsertEmployeeDto> = {
      ...formData,
      shortName,
      ...employeeDateChange,
      workTimes: newWorkTimeList,
      personId: selectedOption.id,
      teamLeaderEmployeeId: selectedTeamLeader === undefined ? undefined : selectedTeamLeader?.id
    };
    AddEdit(newEmployee);
  }, [formData, employeeDateChange, workTimeDateChange, fte, shortName, selectedOption, selectedTeamLeader]);

  const AddEdit = (newEmployee: Partial<UpsertEmployeeDto>) => {
    if (state?.id) {
      editEmployee(newEmployee, state.id, cancelTokenSource).then(r => {
        if (r.error) {
          alert(r.message);
        } else {
          history.push("/employee");
        }
      }).catch(handleError);
    } else {
      addEmployee(newEmployee, cancelTokenSource).then((r) => {
        if (r.error) {
          alert(r.message);
        } else {
          history.push("/employee");
        }
      }).catch(handleError);
    }
  };

  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> =
        [errorUtils.INVALID_DATE, errorUtils.NAME_FIELD_IS_EMPTY, errorUtils.INVALID_WORKING_DATE];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }
      setErrorMessage(errMsgs);
    }
  };

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [formData]);

  return (
      <>
          <Box>
              <Stack direction={"row"}>
                  <Box>
                      <h1>{message}</h1>
                      <Collapse in={!!errorMessage}>
                          <Alert
                              severity={"error"}
                              action={
                                  <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setErrorMessage(undefined);
                                      }}
                                  >
                                      <CloseIcon fontSize="inherit"/>
                                  </IconButton>
                              }
                              sx={{ mb: 2 }}
                          >
                              {errorMessage}
                          </Alert>
                      </Collapse>
                      <FormControl>
                          <Box display={"inline-block"}>
                              <Stack direction={"column"}>
                                  <Stack direction={"row"}>
                                      {state.from === StateFrom.DETAILS
                                        ? <>
                                              <TextField
                                                  sx={{ width: 250, m: 2 }}
                                                  label={t("common.shortName")}
                                                  value={shortName}/>
                                          </>
                                        : state.from === StateFrom.ADD
                                          ? <> <Autocomplete
                                                  disablePortal
                                                  id="combo-box-demo"
                                                  options={persons}
                                                  getOptionLabel={(option: PersonDto) => option.email}
                                                  value={selectedOption}
                                                  onChange={(event: any, newValue?: PersonDto) => {
                                                    handlePersonChangeSelection(newValue);
                                                  }}
                                                  sx={{ width: 250, m: 2 }}
                                                  renderInput={(params) =>
                                                      <TextField {...params} label={t("legend.person")}/>}
                                              />
                                                  <TextField
                                                      name={"shortName"}
                                                      sx={{ width: 250, m: 2 }}
                                                      label={t("common.shortName")}
                                                      value={shortName}
                                                      onChange={handleShortNameChange}/>
                                              </>
                                          : <>
                                                  <Autocomplete
                                                      disablePortal
                                                      id="combo-box-demo"
                                                      options={persons}
                                                      getOptionLabel={(option: PersonDto) => option.email}
                                                      value={selectedOption}
                                                      onChange={(event: any, newValue?: PersonDto) => {
                                                        handlePersonChangeSelection(newValue);
                                                      }}
                                                      sx={{ width: 250, m: 2 }}
                                                      renderInput={(params) =>
                                                          <TextField {...params} label={t("common.person")}/>}
                                                  />
                                                  <TextField
                                                      name={"shortName"}
                                                      sx={{ width: 250, m: 2 }}
                                                      label={t("common.shortName")}
                                                      value={shortName}
                                                      onChange={handleShortNameChange}/>
                                              </>
                                      }
                                      <FormControl>
                                          <InputLabel sx={{ width: 250, m: 2 }}>{t("common.civilStatus")}</InputLabel>
                                          <Select
                                              sx={{ width: 250, m: 2 }}
                                              name={"civilStatus"}
                                              type="text"
                                              label={t("common.civilStatus")}
                                              value={formData.civilStatus}
                                              onChange={handleChange}>
                                              <MenuItem key={1} value={CivilStatus.UNKNOWN}>
                                                  {t("option.unknown")}
                                              </MenuItem>
                                              <MenuItem key={2} value={CivilStatus.SINGLE}>
                                                  {t("option.single")}
                                              </MenuItem>
                                              <MenuItem key={3} value={CivilStatus.MARRIED}>
                                                  {t("option.married")}
                                              </MenuItem>
                                              <MenuItem key={4} value={CivilStatus.WIDOWED}>
                                                  {t("option.widowed")}
                                              </MenuItem>
                                              <MenuItem key={5} value={CivilStatus.DIVORCED}>
                                                  {t("option.divorced")}
                                              </MenuItem>
                                              <MenuItem key={6} value={CivilStatus.SEPARATED}>
                                                  {t("option.separated")}
                                              </MenuItem>
                                              <MenuItem key={7} value={CivilStatus.PARTNERED}>
                                                  {t("option.partnered")}
                                              </MenuItem>
                                          </Select>
                                      </FormControl>
                                      <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          options={teamLeaders}
                                          getOptionLabel={(teamLeader: EmployeeDto) => teamLeader.shortName}
                                          value={selectedTeamLeader}
                                          onChange={(event: any, newValue: EmployeeDto) => {
                                            handleTeamLeadersChangeSelection(newValue);
                                          }}
                                          isOptionEqualToValue={(option: EmployeeDto, value: EmployeeDto) => option.id === value.id}
                                          sx={{ width: 250, m: 2 }}
                                          renderInput={(params) =>
                                              <TextField {...params} label={t("legend.teamLeader")}/>}
                                      />

                                      <div>
                                          <FormControlLabel control={
                                              <Checkbox
                                                  value={formData ? formData.teamLeader : false}
                                                  checked={formData ? formData.teamLeader : false}
                                                  onChange={handleShowClosed}
                                                  inputProps={{ "aria-label": "controlled" }}/>}
                                                            label={t("legend.teamLeader")} />
                                      </div>
                                      <div>
                                          <FormControlLabel control={
                                              <Checkbox
                                                  value={formData ? formData.external : false}
                                                  checked={formData ? formData.external : false}
                                                  onChange={toggleExternalEmployee}
                                                  inputProps={{ "aria-label": "controlled" }}/>}
                                                            label={t("legend.externalEmployee")} />
                                      </div>
                                  </Stack>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <Stack direction={"row"}>
                                          <Box sx={{ width: 250, m: 2 }}>
                                              <DatePickerClass>
                                                  <DesktopDatePicker
                                                      className="datePicker"
                                                      label={t("common.dateOfBirth")}
                                                      inputFormat="DD/MM/YYYY"
                                                      value={employeeDateChange.dateOfBirth}
                                                      onChange={handleEmployeeDateChange("dateOfBirth")}
                                                      renderInput={(params) => <TextField {...params}/>}
                                                  />
                                              </DatePickerClass>
                                          </Box>
                                          <Box sx={{ width: 250, m: 2 }}>
                                              <DatePickerClass>
                                                  <DesktopDatePicker
                                                      className="datePicker"
                                                      label={t("common.dateOfEntry")}
                                                      inputFormat="DD/MM/YYYY"
                                                      value={employeeDateChange.dateOfEntry}
                                                      onChange={handleEmployeeDateChange("dateOfEntry")}
                                                      renderInput={(params) => <TextField {...params} />}
                                                  />
                                              </DatePickerClass>
                                          </Box>
                                          <Box sx={{ width: 250, m: 2 }}>
                                              <DatePickerClass>
                                                  <DesktopDatePicker
                                                      className="datePicker"
                                                      label={t("legend.dateOfExit")}
                                                      inputFormat="DD/MM/YYYY"
                                                      value={employeeDateChange.dateOfExit}
                                                      onChange={handleEmployeeDateChange("dateOfExit")}
                                                      renderInput={(params) => <TextField {...params} />}
                                                  />
                                              </DatePickerClass>
                                          </Box>
                                          <Box sx={{ width: 250, m: 2 }}>
                                              <DatePickerClass>
                                                  <DesktopDatePicker
                                                      className="datePicker"
                                                      label={t("legend.lastWorkingDate")}
                                                      inputFormat="DD/MM/YYYY"
                                                      value={employeeDateChange.lastWorkingDate}
                                                      onChange={handleEmployeeDateChange("lastWorkingDate")}
                                                      renderInput={(params) => <TextField {...params} error={false} />}
                                                  />
                                              </DatePickerClass>
                                          </Box>
                                      </Stack>
                                  </LocalizationProvider>
                              </Stack>
                              {state.from === StateFrom.ADD
                                ? <>
                                      <h3>{t("header.workTime")}</h3>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <FormControl>
                                              <Stack direction={"row"}>
                                                  <Box sx={{ width: 250, m: 2 }}>
                                                      <DesktopDatePicker
                                                          className="datePicker"
                                                          label={t("common.validFrom")}
                                                          inputFormat="DD/MM/YYYY"
                                                          value={workTimeDateChange.validFrom}
                                                          onChange={handleWorkTimeDateChange("validFrom")}
                                                          renderInput={(params) => <TextField {...params} />}
                                                      />
                                                  </Box>

                                                  <Box sx={{ width: 250, m: 2 }}>
                                                      <DesktopDatePicker
                                                          className="datePicker"
                                                          label={t("common.validTo")}
                                                          inputFormat="DD/MM/YYYY"
                                                          value={workTimeDateChange.validTo}
                                                          onChange={handleWorkTimeDateChange("validTo")}
                                                          renderInput={(params) => <TextField {...params} />}
                                                      />
                                                  </Box>
                                                  <Box sx={{ width: 250, m: 2 }}>
                                                      <TextField
                                                          fullWidth={true}
                                                          type={"number"}
                                                          name={"fte"}
                                                          value={fte}
                                                          label={t("common.fte")}
                                                          onChange={(e) => setFte(parseInt(e.target.value))}
                                                      />
                                                  </Box>
                                                  <Box sx={{ width: 250, m: 2 }}>
                                                      <TextField
                                                          fullWidth={true}
                                                          type={"number"}
                                                          name={"credits"}
                                                          value={credits}
                                                          label={t("common.vacation")}
                                                          onChange={(e) => setCredits(parseInt(e.target.value))}
                                                      />
                                                  </Box>
                                              </Stack>
                                          </FormControl>
                                      </LocalizationProvider>
                                  </>
                                : <></>}
                              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                                  <Button
                                      sx={{ m: 1 }}
                                      variant={"outlined"}
                                      type={"submit"}
                                      disabled={state?.from === StateFrom.DETAILS}
                                      onClick={(e) => onFormSubmit(e)}>
                                      {t("common.save")}
                                  </Button>
                                  {/* <Box sx={{ m: 1 }}> */}
                                  {/*     {state.from !== StateFrom.ADD */}
                                  {/*       ? <YNPopper */}
                                  {/*             message={t("common.delete")} */}
                                  {/*             color={"error"} */}
                                  {/*             variant={"contained"} */}
                                  {/*             icon={<DeleteIcon/>} */}
                                  {/*             disabled={state.role === "ROLE_EMPLOYEE" || state.from === StateFrom.DETAILS} */}
                                  {/*             onConfirm={() => { */}
                                  {/*               disableEmployee(state.id, cancelTokenSource) */}
                                  {/*                 .then(() => history.push("/employee")); */}
                                  {/*             }}/> */}
                                  {/*       : <> </>} */}
                                  {/* </Box> */}
                                  <Button sx={{ m: 1 }}
                                          variant={"outlined"}
                                          onClick={() => history.push("/employee")}>
                                      {t("common.back")}
                                  </Button>
                              </Box>
                          </Box>
                      </FormControl>
                  </Box>
              </Stack>
              {state.from === StateFrom.DETAILS
                ? <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore/>}>
                          <Typography>{t("header.workTimes")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <EmployeeWorkTimeTable
                              employeeId={state.id}
                              search={state.search}
                              view={state.view}
                              from={state.from}/>
                      </AccordionDetails>
                  </Accordion>
                : <></>}

              {state.from === StateFrom.DETAILS && employee?.teamLeader
                ? <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore/>}>
                          <Typography>{t("common.employees")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <MyEmployeesTable employeeId={state.id}
                                            search={state.search}
                                            view={state.view}
                                            from={state.from}
                          />
                      </AccordionDetails>
                  </Accordion>

                : <></>}
              {state.from === StateFrom.DETAILS
                ? <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore/>}>
                      <Typography>{t("header.employeePersons")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <EmployeePersonTable
                          search={state.search}
                          id={state.id}
                          view={state.view}
                      />
                  </AccordionDetails>
              </Accordion>
                : <></>}
          </Box>
      </>

  );
};
export default AddEditEmployee;
