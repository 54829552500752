import React, { FC } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdminProps } from "../../components/interfaces/interface";
import { Box, Button, Stack } from "@mui/material";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { getEmployee } from "../Employees/services/EmployeeServices";
import { deleteEmployeePerson, getAllEmployeePersons } from "./services/EmployeePersonServices";
import { EmployeePersonTableDto } from "./types";
import { useAuthContext } from "../../context/AuthContext";
import { EmployeeDto } from "../../ts-types/api.types";

const EmployeePersonTable: FC<AdminProps> = (props): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const [employeePersons, setEmployeePersons] = React.useState<Array<EmployeePersonTableDto>>([]);
  const [employee, setEmployee] = React.useState<EmployeeDto>();
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadEmployeeAndEmployeePersons();
  }, []);

  const loadEmployeeAndEmployeePersons = React.useCallback(() => {
    if (props.id) {
      getEmployee(props.id, cancelTokenSource)
        .then(employeeP => {
          setEmployee(employeeP);
          if (props.id) {
            getAllEmployeePersons(props.id, cancelTokenSource)
              .then(employeePersons => {
                setEmployeePersons(employeePersons);
              });
          }
        });
    }
  }, [employee, employeePersons]);

  const renderEmployeePersonTable = (): JSX.Element => {
    const disableEmployeePerson = (id: number) => {
      deleteEmployeePerson(id, cancelTokenSource)
        .then(loadEmployeeAndEmployeePersons);
    };

    const translatedValues = ({ rowData } : any) => {
      return t("option." + rowData.relationType);
    };

    const actions = ({ rowData }: any) => {
      const employeePersonId = rowData.id;
      return (
                <Box width={100}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        onClick={() =>
                          history.push("/employeeperson",
                            {
                              id: employeePersonId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.DETAILS,
                              employeeId: employee?.id
                            })}>
                        {t("common.details")}
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/employeeperson",
                            {
                              id: employeePersonId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.EDIT,
                              employeeId: employee?.id
                            })}>
                        {t("common.edit")}
                    </Button>
                    <YNPopper
                        message={t("common.delete")}
                        color={"error"}
                        variant={"contained"}
                        icon={<DeleteIcon/>}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onConfirm={() => disableEmployeePerson(rowData.id)}/>
                </Box>
      );
    };
    const companyPersonsRowGetter = ({ index }: any) => {
      Object.assign(employeePersons[index], { index: index + 1 });

      return employeePersons[index];
    };

    const companyPersonsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };

    return (
            <VirtualizedTable
                rowCount={employeePersons.length}
                rowGetter={companyPersonsRowGetter}
                rowRenderer={companyPersonsRowRenderer}
                columns={[
                  {
                    width: 100,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 300,
                    label: t("header.personName"),
                    dataKey: "personName"
                  },
                  {
                    flexGrow: 1,
                    width: 300,
                    label: t("common.relationType"),
                    dataKey: "relationType",
                    cellRenderer: (translatedValues)
                  },
                  {
                    width: 400,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((employeePersons.length * rowHeight) + 25, maxTableHeight);

  return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3></h3>
                {employeePersons.length < 3
                  ? <Stack alignSelf={"center"}>
                        <Button
                            variant={"contained"}
                            disabled={!!user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                            onClick={() =>
                              history.push("/employeeperson",
                                { search: props.search, view: props.view, from: StateFrom.ADD, employeeId: employee?.id })}>
                            {t("button.addEmployeePerson")}
                        </Button>
                    </Stack>
                  : <></>
                }
            </Stack>
            <Box style={{ height: tableHeight }}>
                {renderEmployeePersonTable()}
            </Box>
        </Box>
  );
};
export default EmployeePersonTable;
