import React from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { StateFrom } from "../../../components/interfaces/enums";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { addProjectTask, deleteProjectTask, editProjectTask, getProjectTask } from "../services/ProjectTaskServices";
import { useTranslation } from "react-i18next";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectTaskType } from "../../../ts-types/api.enums";
import { ProjectTaskDto } from "../../../ts-types/api.types";

const AddEditProjectTask = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [projectTask, setProjectTask] = React.useState<ProjectTaskDto>({
    stamp: "",
    description: "",
    type: ProjectTaskType.WORKTRACKING,
    id: 0,
    projectId: 0,
    active: false,
    defaultVacationTrack: false
  });
  const [message, setMessage] = React.useState<string>("");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editProjectTask"));
        getProjectTask(state.projectTaskId, cancelTokenSource)
          .then(r => setProjectTask(r));
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.projectTaskDetails"));
        getProjectTask(state.projectTaskId, cancelTokenSource)
          .then(r => setProjectTask(r));
        break;
      case StateFrom.ADD:
        setMessage(t("header.addProjectTask"));
        setProjectTask({
          ...projectTask,
          projectId: state.projectId
        });
        break;
    }
  }, []);

  const handleChange = (e:any) => {
    setProjectTask({
      ...projectTask,
      [e.target.name]: e.target.value
    });
  };

  const handleActiveInactive = () => {
    if (!projectTask.active) {
      setProjectTask({ ...projectTask, active: true });
    } else {
      setProjectTask({ ...projectTask, active: false });
    }
  };
  const handleDefault = () => {
    if (!projectTask.defaultVacationTrack) {
      setProjectTask({ ...projectTask, defaultVacationTrack: true });
    } else {
      setProjectTask({ ...projectTask, defaultVacationTrack: false });
    }
  };

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addProjectTask(projectTask, cancelTokenSource)
          .then(() => {
            history.push("/upsert-project",
              { search: state.search, view: state.view, projectId: state.projectId, from: StateFrom.DETAILS });
          });
        break;
      case StateFrom.EDIT:
        editProjectTask(projectTask, state.projectTaskId, cancelTokenSource)
          .then(() => {
            history.push("/upsert-project",
              { search: state.search, view: state.view, projectId: state.projectId, from: StateFrom.DETAILS });
          });
        break;
    }
  };
  return (
<>
  <h1>{message}</h1>
  <FormControl>
    <Stack direction={"row"}>
    <TextField
        name={"description"}
        sx={{ width: 250, m: 2 }}
        label={t("common.description")}
        value={projectTask.description}
        onChange={handleChange}/>
    <FormControl>
      <InputLabel sx={{ width: 250, m: 2 }}>{t("legend.type")}</InputLabel>
      <Select
          sx={{ width: 250, m: 2 }}
          name={"type"}
          type="text"
          label={t("legend.type")}
          value={projectTask.type}
          onChange={handleChange}>
        <MenuItem key={1} value={ProjectTaskType.WORKTRACKING}>
          {t("option.workTracking")}
        </MenuItem>
        <MenuItem key={2} value={ProjectTaskType.VACATIONTRACKING}>
          {t("option.vacationTracking")}
        </MenuItem>
      </Select>

      <div>
        <FormControlLabel control={<Checkbox
            checked={projectTask.active}
            onChange={handleActiveInactive}
            inputProps={{ "aria-label": "controlled" }}/>}
                          label={t("button.active")} />
        <FormControlLabel control={<Checkbox
          checked={projectTask.defaultVacationTrack}
          onChange={handleDefault}
          inputProps={{ "aria-label": "controlled" }}/>}
                          label={t("legend.default")} />
      </div>
    </FormControl>
    </Stack>
  <Stack direction={"row"} justifyContent={"right"}>
    <Button
        sx={{ m: 1 }}
        variant={"outlined"}
        type={"submit"}
        disabled={state?.from === StateFrom.DETAILS}
        onClick={onFormSubmit}>
      {t("common.save")}
    </Button>
    <Box sx={{ m: 1 }}>
      {state.from !== StateFrom.ADD
        ? <YNPopper
              message={t("common.delete")}
              color={"error"}
              variant={"contained"}
              icon={<DeleteIcon/>}
              disabled={state.role === "ROLE_EMPLOYEE" || state.from === StateFrom.DETAILS}
              onConfirm={() => {
                deleteProjectTask(state.id, cancelTokenSource)
                  .then(() => history.push("/upsert-project", {
                    search: state.search,
                    view: state.view,
                    projectId: state.projectId,
                    from: StateFrom.EDIT
                  }));
              }}/>
        : <> </>}
    </Box>
    <Button sx={{ m: 1 }}
            variant={"outlined"}
            onClick={() => history.push("/upsert-project",
              { search: state.search, view: state.view, projectId: state.projectId, from: StateFrom.DETAILS })}>
      {t("common.back")}
    </Button>
  </Stack>
  </FormControl>
</>
  );
};
export default AddEditProjectTask;
