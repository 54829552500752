import React, { FC } from "react";
import axios from "axios";

import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdminProps } from "../../components/interfaces/interface";
import { UserDto } from "../Users/types";
import { getAllHolidays, listAllUsers, updateHolidaysTableAdmin } from "../../service/upsertAdminServices";
import { keyById } from "../../utils/mapUtils";
import HolidaysReportTable from "./HolidaysReportTable";
import { HolidayDto } from "../../ts-types/api.types";
import ApproveDeclineHolidays from "./components/ApproveDeclineHolidays";
import { HolidayStatus } from "../../components/interfaces/enums";
import HolidayList from "./components/HolidayList";
import { useHistory } from "react-router-dom";

const HolidaysPage: FC<AdminProps> = (props): JSX.Element => {
  const [selectedUser, setSelectedUser] = React.useState<UserDto>();
  const [holidays, setHolidays] = React.useState<Array<HolidayDto>>([]);
  const [userMap, setUserMap] = React.useState<Map<number, UserDto>>(new Map());
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const { t } = useTranslation("company-manager");
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const holidayRequestId = queryParams.get("holiday_request_id");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

  React.useEffect(() => {
    loadAllUsers();
    loadHolidaysByStatus(HolidayStatus.WAITING_ON_APPROVAL);
  }, []);

  React.useEffect(() => {
    loadHolidaysByStatus(HolidayStatus.WAITING_ON_APPROVAL);
  }, [selectedUser, props.search]);

  const loadAllUsers = React.useCallback(() => {
    setDataLoaded(false);
    listAllUsers(false, cancelTokenSource)
      .then((data: Array<UserDto>) => {
        const userMap1 = keyById<number, UserDto>(data);
        setUserMap(userMap1);
      })
      .finally(() => setDataLoaded(true));
  }, [cancelTokenSource]);

  const loadHolidaysByStatus = React.useCallback((status) => {
    getAllHolidays(status, cancelTokenSource, selectedUser?.id, props.search)
      .then((data: Array<HolidayDto>) => {
        setHolidays(data);
      });
  }, [cancelTokenSource, selectedUser, props.search]);

  const handleChangeSelectButtonUser = React.useCallback((e) => {
    setSelectedUser(userMap.get(parseInt(e.target.value)));
  }, [userMap]);

  const updateDays = React.useCallback(() => {
    updateHolidaysTableAdmin(HolidayStatus.WAITING_ON_APPROVAL, cancelTokenSource, selectedUser?.id, props.search)
      .then((data: Array<HolidayDto>) => {
        setHolidays(data);
      });
  }, [cancelTokenSource]);

  return (
        <Box>
            {dataLoaded
              ? <>
                <h3><i>{t("header.yourHolidayRequests")}</i></h3>
                <HolidayList onChange={loadHolidaysByStatus}/>
                <hr/>
    <FormControl fullWidth id="filter-form">
        <Stack direction={"row"}>
            <Stack sx={{ mx: 1 }}>
                <FormControl>
                    <InputLabel id="selectUser" sx={{ my: 1 }}>{t("legend.user")}</InputLabel>
                    <Select
                        size={"small"}
                        sx={{ minWidth: 220, my: 1 }}
                        labelId="selectUser"
                        id="selectUser"
                        name="user"
                        label={t("legend.user")}
                        defaultValue={-1}
                        value={selectedUser?.id}
                        onChange={handleChangeSelectButtonUser}>
                        <MenuItem key={-1} value={-1}>
                          {t("option.all")}
                        </MenuItem>
                        {Array.from(userMap.values()).map((userDto: UserDto) => (
                            <MenuItem key={userDto.id} value={userDto.id}>
                                {userDto.username}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </Stack>
    </FormControl>
     <ApproveDeclineHolidays holidays = {holidays}
                             holidayRequestId = {holidayRequestId ? parseInt(holidayRequestId, 10) : 0}
                             loadHolidays={loadHolidaysByStatus}/>
                <hr/>
     <Button type="button" onClick={() => updateDays()}>{t("button.update")}</Button>
    <HolidaysReportTable/>
                </>
              : <CircularProgress />
            }
        </Box>
  );
};
export default HolidaysPage;
