import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { EmployeeHolidayTableByWeek, HolidayDto, UpsertHolidayDto } from "../../../ts-types/api.types";
import { Undefinable } from "../../../types";

const baseUrl = "/company-manager-app/api/holidays";

export const getAllHolidays = (source: CancelTokenSource) : Promise<Array<HolidayDto>> => {
  return axios
    .get(`${baseUrl}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getHolidayById = (id: number, source: CancelTokenSource) : Promise<UpsertHolidayDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteById = (id: number, source: CancelTokenSource) : Promise<void> => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const save = (upsertHolidayDto: UpsertHolidayDto, source: CancelTokenSource): Promise<HolidayDto> => {
  return axios
    .post(`${baseUrl}`, upsertHolidayDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const edit = (
  id: number,
  upsertHolidayDto: UpsertHolidayDto,
  source: CancelTokenSource) : Promise<HolidayDto> => {
  return axios
    .put(`${baseUrl}/${id}`, upsertHolidayDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getHolidaysTable = (
  fromDate: string,
  source: CancelTokenSource,
  toDate?: string) : Promise<EmployeeHolidayTableByWeek> => {
  return axios
    .get(`${baseUrl}/table`, { cancelToken: source.token, params: { fromDate, toDate } })
    .then(response => response.data);
};

export const getAllHolidaysForTeamLeader = (status: string, source: CancelTokenSource) : Promise<Array<HolidayDto>> => {
  return axios
    .get(`${baseUrl}/get-all-holidays`, { cancelToken: source.token, params: { status } })
    .then(response => response.data);
};
export const approveByTeamLeader = (id: number, source: CancelTokenSource) : Promise<HolidayDto> => {
  return axios
    .put(`${baseUrl}/approve/${id}`, null, { cancelToken: source.token })
    .then(response => response.data);
};

export const declineByTeamLeader = (id: Undefinable<number>, text: string, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/decline/${id}`, {
      reason: text
    }, { cancelToken: source.token })
    .then(response => response.data);
};
