import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { ProjectPersonDto, ProjectPersonTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/project-person";

export const getProjectPerson = (id: number, source: CancelTokenSource): Promise<ProjectPersonDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllProjectPersons = (
  projectId: number,
  source: CancelTokenSource): Promise<Array<ProjectPersonTableDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { projectId } })
    .then(response => response.data);
};

export const addProjectPerson = (projectPersonDto: Partial<ProjectPersonDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, projectPersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const editProjectPerson = (
  projectPersonDto: Partial<ProjectPersonDto>,
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, projectPersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteProjectPerson = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
