import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Box, Button, CircularProgress, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import { AdminProps } from "../../components/interfaces/interface";
import { useAuthContext } from "../../context/AuthContext";
import { StateFrom, Role } from "../../components/interfaces/enums";
import DeleteIcon from "@mui/icons-material/Delete";
import { disableProject, enableProject, getAllProjectsStatistics } from "./services/ProjectServices";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { ProjectStatisticsTableDto } from "../../ts-types/api.types";
import { Check } from "@mui/icons-material";

const ProjectPage: FC<AdminProps> = (props): JSX.Element => {
  const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
  const [includeClosed, setIncludeClosed] = React.useState<boolean>(false);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const [projects, setProjects] = React.useState<Array<ProjectStatisticsTableDto>>([]);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadProjects(includeClosed, includeInactive);
  }, [props.search, props.role]);

  const loadProjects = React.useCallback(async (includeClosed, includeInactive) => {
    setDataLoaded(false);
    try {
      const response = await getAllProjectsStatistics(includeClosed, includeInactive, cancelTokenSource, props.search);
      setProjects(response);
    } finally {
      setDataLoaded(true);
    }
  }, [props.search, projects, cancelTokenSource]);

  const disProject = (id: number) => {
    disableProject(id, cancelTokenSource)
      .then(() => loadProjects(includeClosed, includeInactive));
  };

  const enaProject = (id: number) => {
    enableProject(id, cancelTokenSource)
      .then(() => loadProjects(includeClosed, includeInactive));
  };

  const handleIncludeInactive = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIncludeInactive(checked);
    loadProjects(includeClosed, checked);
  };

  const handleIncludeClosed = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIncludeClosed(checked);
    loadProjects(checked, includeInactive);
  };

  const renderProjectTable = () => {
    const actions = ({ rowData }: any) => {
      const projectId = rowData.id;
      return (
          <Box width={100}>
            <Button
                sx={{ my: 1, mr: 1 }}
                variant={"outlined"}
                onClick={() =>
                  history.push("/upsert-project",
                    { projectId, view: props.view, search: props.search, from: StateFrom.DETAILS })}>
              {t("common.details")}
            </Button>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/upsert-project",
                    { projectId, view: props.view, search: props.search, from: StateFrom.EDIT })}>
              {t("common.edit")}
            </Button>
            {rowData.active
              ? <YNPopper
                message={t("common.disable")}
                color={"error"}
                variant={"contained"}
                icon={<DeleteIcon/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => disProject(rowData.id)}/>
              : <YNPopper
                message={t("common.enable")}
                color={"success"}
                variant={"contained"}
                icon={<Check/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => enaProject(rowData.id)}/>
            }
          </Box>
      );
    };

    const projectsRowGetter = ({ index }: any) => {
      Object.assign(projects[index], { index: index + 1 });

      return projects[index];
    };

    const projectsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };
    const HeaderWithTooltip = ({ label, tooltip } : any) => {
      return (
              <div>
                  {
                      <Tooltip title={t(label)} placement="top" componentsProps={{
                        tooltip: {
                          sx: {
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "0"
                          }
                        }
                      }}>
                          <span data-tip={tooltip}>#{t(label + "Tooltip")}</span>
                      </Tooltip>
                  }
              </div>
      );
    };
    return (
        <VirtualizedTable
            rowCount={projects.length}
            rowGetter={projectsRowGetter}
            rowRenderer={projectsRowRenderer}
            columns={[
              {
                width: 75,
                label: "#",
                dataKey: "index"
              },
              history.location.pathname === "/upsert-company"
                ? {
                    width: 0
                  }
                : {
                    width: 300,
                    flexGrow: 1,
                    label: t("tableHeader.companyName"),
                    dataKey: "companyName"
                  },
              {
                width: 100,
                flexGrow: 1,
                label: t("tableHeader.code"),
                dataKey: "projectNumber"
              },
              {
                width: 200,
                flexGrow: 1,
                label: t("common.name"),
                dataKey: "name"
              },
              {
                width: 250,
                flexGrow: 1,
                label: t("common.projectType"),
                dataKey: "projectType"
              },
              {
                width: 200,
                flexGrow: 1,
                label: t("tableHeader.status"),
                dataKey: "status"
              },
              {
                width: 100,
                flexGrow: 1,
                label: "header.projectPersons",
                dataKey: "numOfProjectPersons",
                headerRenderer: (HeaderWithTooltip)
              },
              {
                width: 100,
                flexGrow: 1,
                label: "header.projectTasks",
                dataKey: "numOfProjectTasks",
                headerRenderer: (HeaderWithTooltip)
              },
              {
                width: 325,
                label: t("common.actions"),
                dataKey: "id",
                cellRenderer: (actions)
              }
            ]}
        />
    );
  };

  const rowHeight = 48;
  const tableHeight = (projects.length * rowHeight) + 25;

  return (
      <Box>
              <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3><i>{t("common.projects")}</i></h3>
                <Stack alignSelf={"center"} direction={"row"}>
                  <FormControlLabel
                    control={<Switch onChange={(e, check) => handleIncludeClosed(e, check)} checked={includeClosed}/>}
                    label={t("common.closed")} />
                  <FormControlLabel
                    control={<Switch onChange={(e, check) => handleIncludeInactive(e, check)} checked={includeInactive}/>}
                    label={t("common.inactive")} />
                  <Button
                      variant={"contained"}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onClick={() =>
                        history.push("/upsert-project",
                          { search: props.search, view: props.view, from: StateFrom.ADD })}>
                    {t("button.addProject")}
                  </Button>
                </Stack>
              </Stack>
        {dataLoaded
          ? <>
              <Box style={{ height: tableHeight }}>
                {renderProjectTable()}
              </Box>
            </>
          : <CircularProgress />
        }
      </Box>
  );
};
export default ProjectPage;
