import React, { FC } from "react";
import axios from "axios";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { Box, Button, Stack } from "@mui/material";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthContext } from "../../../context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { StateFrom, Role } from "../../../components/interfaces/enums";
import { UpsertWorkTimeDto } from "../../WorkTimes/types";
import { deleteWorkTime, getAllWorkTimes } from "../../WorkTimes/services/WorkTimeServices";
import { useTranslation } from "react-i18next";

interface Props {
    employeeId: number;
    view: string;
    search: string;
    from: string;
}
const EmployeeWorkTimeTable: FC<Props> = (props): JSX.Element => {
  const [workTimes, setWorkTimes] = React.useState<Array<UpsertWorkTimeDto>>([]);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { state } = useLocation<any>();
  const activeView: number = state?.view ? state?.view : 1;
  const activeSearch: string = state?.search ? state?.search : "";
  const { user } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadWorkTimes();
  }, []);

  const loadWorkTimes = React.useCallback(() => {
    getAllWorkTimes(props.employeeId ? props.employeeId : state.employeeId, cancelTokenSource)
      .then(r => {
        setWorkTimes(r);
      });
  }, [workTimes, cancelTokenSource]);

  const renderWorkTimeTable = () => {
    const actions = ({ rowData }: any) => {
      const workTimeId = rowData.id;
      return (
                <Box width={100}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-worktime",
                            {
                              id: workTimeId,
                              view: activeView,
                              search: activeSearch,
                              from: StateFrom.EDIT,
                              employeeId: props.employeeId
                            })}>
                      {t("common.edit")}
                    </Button>
                    <YNPopper
                        message={t("common.delete")}
                        color={"error"}
                        variant={"contained"}
                        icon={<DeleteIcon/>}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onConfirm={() => {
                          deleteWorkTime(rowData.id, cancelTokenSource).then(() => loadWorkTimes());
                        }}/>
                </Box>
      );
    };

    const jobTitleCellRenderer = ({ cellData }: any) => {
      return t(`enum.jobTitle.${cellData}`);
    };

    const workTimeRowGetter = ({ index }: any) => {
      Object.assign(workTimes[index], { index: index + 1 });

      return workTimes[index];
    };

    const workTimeRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };
    return (
            <VirtualizedTable
                rowCount={workTimes.length}
                rowGetter={workTimeRowGetter}
                rowRenderer={workTimeRowRenderer}
                columns={[
                  {
                    width: 75,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.validFrom"),
                    dataKey: "validFrom"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.validTo"),
                    dataKey: "validTo"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.jobTitle"),
                    dataKey: "jobTitle",
                    cellRenderer: (jobTitleCellRenderer)
                  },
                  {
                    width: 100,
                    flexGrow: 1,
                    label: t("common.fte"),
                    dataKey: "fte"
                  },
                  {
                    width: 100,
                    flexGrow: 1,
                    label: t("common.credits"),
                    dataKey: "credits"
                  },
                  {
                    width: 225,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };
  const rowHeight = 48;
  const maxTableHeight = (5 * rowHeight) + 25;
  const tableHeight = Math.min((workTimes.length * rowHeight) + 25, maxTableHeight);

  return (
      <Box>
          <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
              <h3><i>{t("header.workTimes")}</i></h3>
              <Stack alignSelf={"center"}>
              <Button
                  disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                  type={"button"}
                  variant={"contained"}
                  onClick={() => history.push("/upsert-worktime",
                    { view: activeView, search: activeSearch, from: StateFrom.ADD, employeeId: props.employeeId })}>
                {t("button.addWorkTime")}
              </Button>
              </Stack>
          </Stack>
          <Box height={tableHeight}>
              {renderWorkTimeTable()}
          </Box>
      </Box>
  );
};
export default EmployeeWorkTimeTable;
