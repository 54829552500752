import React from "react";
import { Autocomplete, Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { StateFrom } from "../../../components/interfaces/enums";
import { CompanyPersonDto } from "../types";
import { PersonDto } from "../../Persons/types";
import { addCompanyPerson, editCompanyPerson, getCompanyPerson } from "../services/CompanyPersonServices";
import { getAllPersonsWithoutCompany, getPerson } from "../../Persons/services/PersonServices";
import { useTranslation } from "react-i18next";

const InitialCompanyPersonDto: CompanyPersonDto = {
  personId: 0,
  function: "",
  companyId: 0,
  id: 0
};

const AddEditCompanyPerson = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const history = useHistory();
  const [companyPerson, setCompanyPerson] = React.useState<CompanyPersonDto>(InitialCompanyPersonDto);
  const [persons, setPersons] = React.useState<Array<PersonDto>>([]);
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const [selectedOption, setSelectedOption] = React.useState<PersonDto>({
    address: "",
    city: "",
    email: "",
    shortName: "",
    firstName: "",
    id: 0,
    isoCode: "",
    lastName: "",
    mobile: "",
    otherMobile: "",
    zip: "",
    lastLogin: "",
    active: true,
    imageData: undefined
  });

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editCompanyPerson"));
        loadCompanyPerson();
        loadPersons();
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.companyPersonDetails"));
        loadCompanyPerson();
        loadPersons();
        break;
      case StateFrom.ADD:
        loadPersons();
        setMessage(t("common.addCompanyPerson"));
        setCompanyPerson({
          ...companyPerson,
          companyId: state.companyId
        });
        break;
    }
  }, []);

  const loadCompanyPerson = () => {
    getCompanyPerson(state.id, cancelTokenSource)
      .then(CompanyPersonR => {
        setCompanyPerson(CompanyPersonR);
        if (state.from === StateFrom.EDIT || state.from === StateFrom.DETAILS) {
          getPerson(CompanyPersonR.personId, cancelTokenSource)
            .then(PersonR => setSelectedOption(PersonR));
        }
      });
  };

  const loadPersons = () => {
    getAllPersonsWithoutCompany(state.search, cancelTokenSource)
      .then(r => {
        setPersons(r);
      });
  };

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addCompanyPerson(companyPerson, cancelTokenSource)
          .then(() => history.push("/upsert-company",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId }));
        break;
      case StateFrom.EDIT:
        editCompanyPerson(companyPerson, state.id, cancelTokenSource)
          .then(() => history.push("/upsert-company",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId }));
        break;
    }
  };

  const handlePersonChangeSelection = async (newValue: any) => {
    setSelectedOption(newValue);
    setCompanyPerson({ ...companyPerson, personId: newValue.id });
  };

  const handleChange = (e: any) => {
    setCompanyPerson({
      ...companyPerson,
      [e.target.name]: e.target.value
    });
  };

  return (
      <Box>
        <h2>{message}</h2>
        <FormControl>
        <Stack direction={ "row"}>
         <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={persons}
            getOptionLabel={(option: PersonDto) => option.email}
            value={selectedOption}
            onChange={(event: any, newValue?: PersonDto) => {
              handlePersonChangeSelection(newValue);
            }}
            sx={{ width: 250, m: 2 }}
            renderInput={(params) =>
                <TextField {...params} label={t("common.persons")}/>}
        />
        <TextField
            sx={{ width: 250, m: 2 }}
            label={t("common.companyFunction")}
            name={"function"}
            onChange={handleChange}
            value={companyPerson?.function}/>
        </Stack>
        <Stack direction={"row"} justifyContent={"right"}>
          <Button
              sx={{ m: 1 }}
              variant={"outlined"}
              type={"submit"}
              disabled={state?.from === StateFrom.DETAILS}
              onClick={onFormSubmit}>
            {t("common.save")}
          </Button>
          <Button sx={{ m: 1 }}
                  variant={"outlined"}
                  onClick={() => history.push("/upsert-company",
                    { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId })}>
            {t("common.back")}
          </Button>
        </Stack>
        </FormControl>
      </Box>
  );
};
export default AddEditCompanyPerson;
