import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { EmployeePersonDto, EmployeePersonTableDto } from "../types";

const baseUrl = "/company-manager-app/api/employeeperson";

export const addEmployeePerson = (employeePersonDto: Partial<EmployeePersonDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, employeePersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllEmployeePersons = (employeeId: number, source: CancelTokenSource): Promise<Array<EmployeePersonTableDto>> => {
  return axios
    .get(`${baseUrl}/all/${employeeId}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getEmployeePerson = (id: number, source: CancelTokenSource): Promise<EmployeePersonDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editEmployeePerson = (employeePersonDto: Partial<EmployeePersonDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, employeePersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteEmployeePerson = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
