import React from "react";
import { Redirect, Switch } from "react-router-dom";
import LoginPage from "../../pages/Login/LoginPage";
import ForgotPassword from "../../pages/Login/components/ForgotPassword";
import ResetPassword from "../../pages/Login/components/ResetPassword";
import Route from "../Route";
import CollapsibleNavBar from "../Navbar/CollapsibleNavBar";

const Main = () => {
  return (
    <main style={{ height: "100vh", display: "grid", alignContent: "center", flex: "1" }}>
        {}
      <Switch>
        <Route
            path="/login"
            exact
            render={() => <LoginPage/>}
        />
        <Route
            path="/forgot-password"
            exact
            render={() => <ForgotPassword/>}
        />
        <Route
            path="/reset-password"
            exact
            render={() => <ResetPassword/>}
        />
        <Route
          path="/"
          render={() => <CollapsibleNavBar/>}
          />
         <Redirect to="/login"/>
      </Switch>
    </main>
  );
};
export default Main;
