import React, { useState } from "react";
import { Alert, Box, Button, Collapse, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { ChangePasswordDto } from "./Navbar/types";
import { changePassword } from "../pages/Users/services/userServices";
import { useTranslation } from "react-i18next";
import { ChangePasswordProps } from "./interfaces/interface";
import CloseIcon from "@mui/icons-material/Close";
import { errorUtils } from "../utils/errorUtils";

const initialPasswordChangeFormData: ChangePasswordDto = {
  oldPassword: "",
  newPassword: "",
  repeatNewPassword: ""
};

export const ChangePassword: React.FC<ChangePasswordProps> = (props: ChangePasswordProps) => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation("company-manager");
  const [passwordChangeForm, setPasswordChangeForm] = useState<ChangePasswordDto>(initialPasswordChangeFormData);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleChange = (e: any) => {
    setPasswordChangeForm({ ...passwordChangeForm, [e.target.name]: e.target.value });
  };

  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.INCORRECT_CREDENTIALS];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }

      setErrorMessage(errMsgs);
    }
  };

  const onChangePassword = () => {
    changePassword(passwordChangeForm, cancelTokenSource).then(() => {
      setPasswordChangeForm(initialPasswordChangeFormData);
      props.close();
    }).catch((e) => handleError(e.response.data));
  };

  return (
      <>
        <Dialog open={props.show}>
          <Box sx={{ width: 400 }}>
            <Collapse in={!!errorMessage}>
              <Alert
                  severity={"error"}
                  action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setErrorMessage(undefined);
                        }}
                    >
                      <CloseIcon fontSize="inherit"/>
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
              >
                {errorMessage}
              </Alert>
            </Collapse>
            <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
              Please enter new password!
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TextField
                  sx={{ m: 1 }}
                  type="password"
                  className="form-control"
                  id="oldPassword"
                  name="oldPassword"
                  label="Enter old password"
                  required
                  onChange={handleChange}
              />
              <TextField
                  sx={{ m: 1 }}
                  type="password"
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  label="Enter new password"
                  required
                  onChange={handleChange}
              />
              <TextField
                  sx={{ m: 1 }}
                  type="password"
                  className="form-control"
                  id="repeatNewPassword"
                  name="repeatNewPassword"
                  label="Repeat new password"
                  required
                  onChange={handleChange}
              />
              <Box>
                <Button
                    sx={{ m: 1 }}
                    type="button"
                    className="btn btn-warning"
                    onClick={onChangePassword}
                >
                  {t("common.save")}
                </Button>

                <Button
                    sx={{ m: 1 }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.close()}
                >
                  {t("common.back")}
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </>
  );
};
