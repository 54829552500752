import React, { FC } from "react";
import { CompanyProjectProps } from "../../../components/interfaces/interface";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Box, Button, Stack } from "@mui/material";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { StateFrom, Role } from "../../../components/interfaces/enums";
import { disableProject, enableProject } from "../../Projects/services/ProjectServices";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { emptyTableCell } from "../../../utils/tableUtils";
import { Check } from "@mui/icons-material";

const CompanyProjectTable: FC<CompanyProjectProps> = (props): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");
  const { state } = useLocation<any>();

  const disableCompanyProject = (id: number) => {
    disableProject(id, cancelTokenSource);
  };

  const enableCompanyProject = (id: number) => {
    enableProject(id, cancelTokenSource);
  };

  const renderCompanyProjectTable = (): JSX.Element => {
    const actions = ({ rowData }: any) => {
      return (
                <Box width={100}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        onClick={() =>
                          history.push("/upsert-project",
                            {
                              projectId: rowData.id,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.DETAILS,
                              companyId: state?.id,
                              prevPath: "/upsert-company"
                            })}>
                      {t("common.details")}
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-project",
                            {
                              projectId: rowData.id,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.EDIT,
                              companyId: state?.id,
                              prevPath: "/upsert-company"
                            })}>
                      {t("common.edit")}
                    </Button>
                  {rowData.active
                    ? <YNPopper
                      message={t("common.disable")}
                      color={"error"}
                      variant={"contained"}
                      icon={<DeleteIcon/>}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onConfirm={() => {
                        disableCompanyProject(rowData.id);
                        window.location.reload();
                      }}/>
                    : <YNPopper
                      message={t("common.enable")}
                      color={"success"}
                      variant={"contained"}
                      icon={<Check/>}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onConfirm={() => {
                        enableCompanyProject(rowData.id);
                        window.location.reload();
                      }}/>
                  }
                </Box>
      );
    };
    const companyProjectsRowGetter = ({ index }: any) => {
      if (props.companyProjects) {
        Object.assign(props.companyProjects[index], { index: index + 1 });

        return props.companyProjects[index];
      }
      return emptyTableCell();
    };

    const companyProjectsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };
    return (
            <VirtualizedTable
                rowCount={props.companyProjects ? props.companyProjects.length : 0}
                rowGetter={companyProjectsRowGetter}
                rowRenderer={companyProjectsRowRenderer}
                columns={[
                  {
                    width: 75,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 170,
                    flexGrow: 1,
                    label: t("tableHeader.projectNumber"),
                    dataKey: "projectNumber"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("header.projectName"),
                    dataKey: "name"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.projectType"),
                    dataKey: "projectType"
                  },
                  {
                    flexGrow: 1,
                    width: 300,
                    label: t("common.projectStatus"),
                    dataKey: "status"
                  },
                  {
                    width: 350,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((props.companyProjects ? props.companyProjects.length * rowHeight : 0) + 25, maxTableHeight);

  return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3></h3>
                <Stack alignSelf={"center"}>
                    <Button
                        variant={"contained"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-project", {
                            search: props.search,
                            view: props.view,
                            from: StateFrom.ADD,
                            companyId: state?.id,
                            prevPath: "/upsert-company"
                          })}>
                      {t("button.addCompanyProject")}
                    </Button>
                </Stack>
            </Stack>
            <Box style={{ height: tableHeight }}>
                {renderCompanyProjectTable()}
            </Box>
        </Box>
  );
};
export default CompanyProjectTable;
