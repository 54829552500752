import React from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  FormHelperText
} from "@mui/material";
import { EmployeeVacationType, StateFrom } from "../../../components/interfaces/enums";
import { useTranslation } from "react-i18next";
import { UpsertEmployeeVacationDto } from "../types";
import {
  deleteEmployeeVacation,
  editEmployeeVacation,
  getEmployeeVacation,
  getPrevYearCredits
} from "../services/VacationReportServices";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const EditEmployeeVacation = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [prevYearDays, setPrevYearDays] = React.useState<number>();
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation("company-manager");
  const [message, setMessage] = React.useState<string>();
  const [form, setForm] = React.useState<UpsertEmployeeVacationDto>({
    days: 0,
    employeeId: state.employeeId,
    remark: "",
    type: EmployeeVacationType.CREDIT
  });
  const [errors, setErrors] = React.useState<{ remark?: string }>({});

  React.useEffect(() => {
    setMessage(t("header.editEmployeeVacation"));
    getEmployeeVacation(state.id, cancelTokenSource)
      .then(r => setForm(r));
    loadDays();
  }, []);

  const loadDays = () => {
    getPrevYearCredits(state.id, cancelTokenSource)
      .then(r => setPrevYearDays(r));
  };

  const validateRemark = (remark: string) => {
    if (!remark) {
      return t("error.REQUIRED");
    }
    return undefined;
  };

  const handleChange = React.useCallback((e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    if (name === "remark") {
      const error = validateRemark(value);
      setErrors(prev => ({ ...prev, remark: error }));
    }
  }, [form]);

  const handleTypeChange = React.useCallback((e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
      days: form.type === EmployeeVacationType.USED
        ? (form.days | 0)
        : (form.days * (-1) | 0)
    });
  }, [form]);

  const onFormSubmit = React.useCallback(() => {
    const remarkError = validateRemark(form.remark);
    if (remarkError) {
      setErrors({ remark: remarkError });
      return;
    }
    editEmployeeVacation(state.id, form, cancelTokenSource)
      .then(() => history.push(
        "/vacation-report",
        { employeeVacationSelectedEmployee: state.employeeId }));
  }, [form, state]);

  const handleDateChange = (response: moment.Moment) => {
    setForm({ ...form, creationDate: response.format("YYYY-MM-DD") });
  };

  return (
    <Box>
      <FormControl>
        <h3>{message}</h3>
        <Stack direction={"row"}>
          <TextField
            sx={{ width: 100, m: 1 }}
            name={"days"}
            label={t("common.days")}
            value={Math.abs(form.days)}
            onChange={handleChange}/>
          <FormControl>
            <InputLabel sx={{ width: 250, m: 1 }}>{t("legend.type")}</InputLabel>
            <Select
              sx={{ width: 250, m: 1 }}
              name={"type"}
              type="text"
              label={"Type"}
              value={form.type}
              onChange={handleTypeChange}>
              <MenuItem key={1} value={EmployeeVacationType.FORWARD}>
                {t("option.forward")}
              </MenuItem>
              <MenuItem key={2} value={EmployeeVacationType.CREDIT}>
                {t("option.credit")}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Box sx={{ width: 370, mx: "7px", mt: 1 }} justifySelf={"center"}>
          <fieldset style={{
            borderColor: "silver",
            borderRadius: 5,
            padding: 0,
            borderStyle: "solid",
            borderWidth: 1
          }}>
            <legend style={{ marginLeft: 9, fontSize: 15, paddingInline: 4 }}>{t("tableHeader.remark")}</legend>
            <TextareaAutosize
              name={"remark"}
              minRows={3}
              value={form.remark}
              onChange={handleChange}
              style={{ width: 356, border: "none", outline: "none", height: 100 }}
              required={true}
            />
            {errors.remark && (
              <FormHelperText error>{errors.remark}</FormHelperText>
            )}
          </fieldset>
        </Box>
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Box sx={{ m: 1, mt: 3, width: 175 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="datePicker"
                label={t("common.date")}
                inputFormat="DD.MM.YYYY"
                value={form.creationDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          {form.type === EmployeeVacationType.FORWARD
            ? <Box sx={{ fontSize: 15, display: "flex", ml: 1, alignItems: "center" }}>
              {t("text.daysFromPreviousYear")} {prevYearDays}
            </Box>
            : <> </>}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box></Box>
          <Stack direction={"row"}>
            <Button
              sx={{ m: 1, justifySelf: "right" }}
              variant={"outlined"}
              type={"submit"}
              onClick={onFormSubmit}
              disabled={!!errors.remark}>
              {t("common.save")}
            </Button>
            <Box sx={{ m: 1 }}>
              {state.from !== StateFrom.ADD
                ? <YNPopper
                  message={t("common.delete")}
                  color={"error"}
                  variant={"contained"}
                  icon={<DeleteIcon/>}
                  disabled={state.role === "ROLE_EMPLOYEE" || state.from === StateFrom.DETAILS}
                  onConfirm={() => {
                    deleteEmployeeVacation(state.id, cancelTokenSource)
                      .then(() => history.push(
                        "/vacation-report",
                        { employeeVacationSelectedEmployee: state.employeeId }));
                  }}/>
                : <> </>}
            </Box>
            <Button sx={{ m: 1, display: "flex", justifySelf: "right" }}
                    variant={"outlined"}
                    onClick={() => history.push(
                      "/vacation-report",
                      { employeeVacationSelectedEmployee: state.employeeId }
                    )}>
              {t("common.back")}
            </Button>
          </Stack>
        </Stack>
      </FormControl>
    </Box>
  );
};
export default EditEmployeeVacation;
