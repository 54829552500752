import React from "react";
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, Stack, TextField
} from "@mui/material";
import { StateFrom } from "../../../components/interfaces/enums";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { UpsertCompanyBankDto } from "../../../ts-types/api.types";
import { addCompanyBank, editCompanyBank, getCompanyBank } from "../services/CompanyBankServices";

const AddEditCompanyBank = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const { state } = useLocation<any>();
  const history = useHistory();
  const [formData, updateFormData] = React.useState<UpsertCompanyBankDto>({
    defaultBank: false,
    companyId: state.companyId,
    accountNo: "",
    ibanCode: "",
    bank: "",
    bankAddress: "",
    swift: ""
  });
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editCompanyBank"));
        loadCompanyBank();
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.companyBankDetails"));
        loadCompanyBank();
        break;
      case StateFrom.ADD:
        setMessage(t("header.addCompanyBank"));
        updateFormData({
          ...formData, companyId: state.companyId
        });
        break;
    }
  }, []);

  const loadCompanyBank = () => {
    getCompanyBank(state.id, cancelTokenSource)
      .then(CompanyBankR => {
        updateFormData(CompanyBankR);
      });
  };

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addCompanyBank(formData, cancelTokenSource)
          .then(() => history.push("/upsert-company", {
            search: state.search,
            view: state.view,
            from: StateFrom.DETAILS,
            id: state.companyId
          }));
        break;
      case StateFrom.EDIT:
        editCompanyBank(formData, state.id, cancelTokenSource)
          .then(() => history.push("/upsert-company", {
            search: state.search,
            view: state.view,
            from: StateFrom.DETAILS,
            id: state.companyId
          }));
        break;
    }
  };

  const handleChange = (e: any) => {
    updateFormData({
      ...formData, [e.target.name]: e.target.value
    });
  };

  const handleIsDefault = () => {
    if (!formData.defaultBank) {
      updateFormData({ ...formData, defaultBank: true });
    } else {
      updateFormData({ ...formData, defaultBank: false });
    }
  };

  return (<Box>
            <h2>{message}</h2>
            <FormControl>
                <Stack direction={"row"}>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.accountNo")}
                        name={"accountNo"}
                        required
                        onChange={handleChange}
                        value={formData?.accountNo}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.ibanCode")}
                        name={"ibanCode"}
                        required
                        onChange={handleChange}
                        value={formData?.ibanCode}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.bank")}
                        name={"bank"}
                        required
                        onChange={handleChange}
                        value={formData?.bank}/>
                </Stack>
                <Stack direction={"row"}>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.bankAddress")}
                        name={"bankAddress"}
                        type="text"
                        onChange={handleChange}
                        value={formData?.bankAddress}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.swift")}
                        name={"swift"}
                        type="text"
                        required
                        onChange={handleChange}
                        value={formData?.swift}/>
                  <div>
                    <FormControlLabel control={
                      <Checkbox
                          checked={formData.defaultBank}
                          value={formData.defaultBank}
                          onChange={handleIsDefault}
                          name={"defaultBank"}
                          inputProps={{ "aria-label": "controlled" }}/>}
                                      label={t("legend.defaultBank")} />
                  </div>
                </Stack>
                <Stack direction={"row"} justifyContent={"right"}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        type={"submit"}
                        disabled={state?.from === StateFrom.DETAILS}
                        onClick={onFormSubmit}>
                        {t("common.save")}
                    </Button>
                    <Button sx={{ m: 1 }}
                            variant={"outlined"}
                            onClick={() => history.push("/upsert-company", {
                              search: state.search,
                              view: state.view,
                              from: StateFrom.DETAILS,
                              id: state.companyId
                            })}>
                        {t("common.back")}
                    </Button>
                </Stack>
            </FormControl>
        </Box>);
};
export default AddEditCompanyBank;
