export const errorUtils = {
  INCORRECT_CREDENTIALS: "INCORRECT_CREDENTIALS",
  INVALID_EMAIL: "INVALID_EMAIL",
  USERNAME_ALREADY_EXISTS: "USERNAME_ALREADY_EXISTS",
  NAME_ALREADY_EXISTS: "NAME_ALREADY_EXISTS",
  PROJECT_NUMBER_ALREADY_EXISTS: "PROJECT_NUMBER_ALREADY_EXISTS",
  INVALID_DATE: "INVALID_DATE",
  INVALID_PATTERN: "INVALID_PATTERN",
  NAME_FIELD_IS_EMPTY: "NAME_FIELD_IS_EMPTY",
  EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
  SHORT_NAME_NOT_VALID: "SHORT_NAME_NOT_VALID",
  NO_DAYS_LEFT: "NO_DAYS_LEFT",
  ENDING_DATE_IS_BEFORE_STARTING_DATE: "ENDING_DATE_IS_BEFORE_STARTING_DATE",
  HOLIDAYS_ARE_OVERLAPPED: "HOLIDAYS_ARE_OVERLAPPED",
  SOME_DAYS_LEFT: "SOME_DAYS_LEFT",
  ALREADY_DECLINED_HOLIDAY: "ALREADY_DECLINED_HOLIDAY",
  USER_DISABLED: "USER_DISABLED",
  IMAGE_TOO_LARGE: "IMAGE_TOO_LARGE",
  INVALID_WORKING_DATE: "INVALID_DATE"
};
