import React, { FC } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { CompanyPersonProps } from "../../components/interfaces/interface";
import { useAuthContext } from "../../context/AuthContext";
import { deleteCompanyPerson } from "./services/CompanyPersonServices";
import { Box, Button, Stack } from "@mui/material";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { emptyTableCell } from "../../utils/tableUtils";
import { Index } from "react-virtualized";

const CompanyPersonTable: FC<CompanyPersonProps> = (props): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");
  const { state } = useLocation<any>();
  const renderCompanyPersonTable = (): JSX.Element => {
    const disableCompanyPerson = (id: number) => {
      deleteCompanyPerson(id, cancelTokenSource);
    };

    const actions = ({ rowData }: any) => {
      const companyPersonId = rowData.id;
      return (
          <Box width={100}>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                onClick={() =>
                  history.push("/companyperson",
                    {
                      id: companyPersonId,
                      view: props.view,
                      search: props.search,
                      from: StateFrom.DETAILS,
                      companyId: state?.id
                    })}>
              {t("common.details")}
            </Button>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/companyperson",
                    {
                      id: companyPersonId,
                      view: props.view,
                      search: props.search,
                      from: StateFrom.EDIT,
                      companyId: state?.id
                    })}>
              {t("common.edit")}
            </Button>
            <YNPopper
                message={t("common.delete")}
                color={"error"}
                variant={"contained"}
                icon={<DeleteIcon/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => { disableCompanyPerson(rowData.id); window.location.reload(); }}/>
          </Box>
      );
    };
    const companyPersonsRowGetter = ({ index }: Index) => {
      if (props.companyPersons) {
        Object.assign(props.companyPersons[index], { index: index + 1 });

        return props.companyPersons[index];
      }
      return emptyTableCell();
    };

    const companyPersonsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    return (
        <VirtualizedTable
            rowCount={props.companyPersons ? props.companyPersons.length : 0}
            rowGetter={companyPersonsRowGetter}
            rowRenderer={companyPersonsRowRenderer}
            columns={[
              {
                width: 100,
                label: "#",
                dataKey: "index"
              },
              {
                width: 300,
                label: t("header.personName"),
                dataKey: "personName"
              },
              {
                flexGrow: 1,
                width: 300,
                label: t("common.companyFunction"),
                dataKey: "function"
              },
              {
                width: 400,
                label: t("common.actions"),
                dataKey: "id",
                cellRenderer: (actions)
              }
            ]}
        />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((props.companyPersons ? props.companyPersons.length * rowHeight : 0) + 25, maxTableHeight);

  return (
      <Box>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
           <h3></h3>
          <Stack alignSelf={"center"}>
            <Button
                variant={"contained"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/companyperson",
                    { search: props.search, view: props.view, from: StateFrom.ADD, companyId: state?.id })}>
              {t("common.addCompanyPerson")}
            </Button>
          </Stack>
        </Stack>
        <Box style={{ height: tableHeight }}>
          {renderCompanyPersonTable()}
        </Box>
      </Box>
  );
};
export default CompanyPersonTable;
