import React, { FC } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectProps } from "../../components/interfaces/interface";
import { useAuthContext } from "../../context/AuthContext";
import { Box, Button, Stack } from "@mui/material";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { deleteProjectPerson, getAllProjectPersons } from "./services/ProjectPersonServices";
import DoneIcon from "@mui/icons-material/Done";
import { emptyTableCell } from "../../utils/tableUtils";
import { ProjectPersonTableDto } from "../../ts-types/api.types";

const ProjectPersonTable: FC<ProjectProps> = (props): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const [projectPersons, setProjectPersons] = React.useState<Array<ProjectPersonTableDto>>([]);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadProjectAndProjectPersons();
  }, []);

  const loadProjectAndProjectPersons = React.useCallback(() => {
    if (props.project.id) {
      getAllProjectPersons(props.project.id, cancelTokenSource)
        .then(projectPerson => setProjectPersons(projectPerson));
    }
  }, [props.project, projectPersons]);

  const renderProjectPersonTable = (): JSX.Element => {
    const disableProjectPerson = (id: number) => {
      deleteProjectPerson(id, cancelTokenSource)
        .then(loadProjectAndProjectPersons);
    };

    const actions = ({ rowData }: any) => {
      const projectPersonId = rowData.id;
      return (
                <Box width={100}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        onClick={() =>
                          history.push("/projectperson",
                            {
                              id: projectPersonId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.DETAILS,
                              projectId: props.project.id
                            })}>
                        {t("common.details")}
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/projectperson",
                            {
                              id: projectPersonId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.EDIT,
                              projectId: props.project.id
                            })}>
                        {t("common.edit")}
                    </Button>
                    <YNPopper
                        message={t("common.delete")}
                        color={"error"}
                        variant={"contained"}
                        icon={<DeleteIcon/>}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onConfirm={() => disableProjectPerson(rowData.id)}/>
                </Box>
      );
    };
    const projectPersonsRowGetter = ({ index }: any) => {
      Object.assign(projectPersons[index], { index: index + 1 });

      return projectPersons[index];
    };

    const projectPersonsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };

    const statusCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <DoneIcon sx={{ fontSize: "16px" }}/>;
      }
      return emptyTableCell();
    };

    return (
            <VirtualizedTable
                rowCount={projectPersons.length}
                rowGetter={projectPersonsRowGetter}
                rowRenderer={projectPersonsRowRenderer}
                columns={[
                  {
                    width: 100,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 150,
                    label: t("header.personId"),
                    dataKey: "personId"
                  },
                  {
                    width: 350,
                    label: t("header.personName"),
                    dataKey: "personName"
                  },
                  {
                    width: 350,
                    label: t("common.functionInProject"),
                    dataKey: "functionInProject"
                  },
                  {
                    width: 150,
                    flexGrow: 1,
                    label: t("tableHeader.status"),
                    dataKey: "active",
                    cellRenderer: (statusCellRenderer)
                  },
                  {
                    width: 325,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((projectPersons.length * rowHeight) + 25, maxTableHeight);

  return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3><i>{t("header.projectPersons")}</i></h3>
                <Stack alignSelf={"center"}>
                    <Button
                        variant={"contained"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/projectperson",
                            { search: props.search, view: props.view, from: StateFrom.ADD, projectId: props.project.id })}>
                        {t("common.addProjectPerson")}
                    </Button>
                </Stack>
            </Stack>
            <Box style={{ height: tableHeight }}>
            {renderProjectPersonTable()}
            </Box>
        </Box>
  );
};
export default ProjectPersonTable;
