import React from "react";
import { AutoSizer, Column, Index, Table, TableProps } from "react-virtualized";
import styled from "styled-components";
import { ColumnProps } from "react-virtualized/dist/es/Table";
import "./VirtualizedTable.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledTable = styled(Table)`

    .ReactVirtualized__Table__Grid {
        outline: none;
        border-radius: unset;
    }

    .ReactVirtualized__Table__headerRow {
        font-weight: 600;
        font-size: 15px;
        color: #000000;
        text-transform: uppercase !important;
    }

    & .ReactVirtualized__Table__row {
        cursor: pointer;
        font-size: 15px;
        color: #000000;

        &:hover .ReactVirtualized__Table__rowColumn {
            background: rgba(0, 0, 0, .05) !important;
        }

        & .ReactVirtualized__Table__rowColumn {
            transition: 250ms all ease-in-out;
            background-color: rgba(255, 255, 255, 0);
        }

        & .ReactVirtualized__Table__rowColumn + .ReactVirtualized__Table__rowColumn {
            padding-left: 0px;
        }

        .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
            margin-right: 0px;
        }

        & .ReactVirtualized__Table__rowColumn:first-of-type {
            margin-left: 0px;
            padding-left: 0px;
            border-right: none !important;
            border-top-right-radius: unset !important;
            border-bottom-right-radius: unset !important;
            border-left: 1px solid #ebeef2;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        & .ReactVirtualized__Table__rowColumn:last-of-type {
            margin-left: 0px;
            padding-left: 0px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: 1px solid #ebeef2;
        }
    }

    & .ReactVirtualized__Table__headerColumn {
        margin-left: 0px;
        padding-left: 5px;
        margin-right: 0px;
    }

    & .ReactVirtualized__Table__rowColumn {
        margin-left: 0px;
        padding-left: 7px;
        margin-right: 0px;
    }
`;

function rowClassName ({ index }: Index) {
  if (index < 0) {
    return "headerRow";
  } else {
    return index % 2 === 0 ? "evenRow" : "oddRow";
  }
}

export interface ColumnPropsExtended extends ColumnProps {
    customClass?: string
}

const VirtualizedTable: React.VFC<Partial<TableProps>> = ({ columns, ...tableProps }) => {
  return (
        <AutoSizer>
            {({ width, height }) => (
                <StyledTable
                    width={width}
                    height={height}
                    headerHeight={25}
                    headerClassName='headerColumn'
                    rowClassName={rowClassName}
                    rowHeight={tableProps.rowHeight || 48}
                    {...tableProps}
                >
                    {
                        columns.map(({ dataKey, customClass, ...other }: Partial<ColumnPropsExtended>) => {
                          const custom = customClass || "";
                          return (
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                <Column
                                    key={dataKey}
                                    className={`bodyColumn ${custom}`}
                                    dataKey={dataKey}
                                    width={width}
                                    style={{
                                      borderTop: "1px solid #ebeef2",
                                      borderBottom: "1px solid #ebeef2",
                                      paddingTop: "10px",
                                      paddingLeft: "5px",
                                      paddingBottom: "10px"
                                    }}
                                    {...other}
                                />
                          );
                        })
                    }
                </StyledTable>
            )}
        </AutoSizer>
  );
};

export default VirtualizedTable;
