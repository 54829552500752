import { BillExportDto } from "../../../ts-types/api.types";
import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import FileDownload from "js-file-download";

const baseUrl = "/company-manager-app/api/export/pdf";

export const downloadPreviewBillPdf = (billExportDto: BillExportDto, source: CancelTokenSource, preview: boolean) => {
  if (preview) {
    return axios
      .post(`${baseUrl}/save-preview-bill`,
        billExportDto,
        { cancelToken: source.token, responseType: "blob", params: { preview } })
      .then(response => response.data);
  } else {
    return axios
      .post(`${baseUrl}/save-preview-bill`,
        billExportDto,
        { cancelToken: source.token, responseType: "blob", params: { preview } })
      .then(response => FileDownload(response.data, `${billExportDto.invoiceNumber}.pdf`));
  }
};
