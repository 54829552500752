import { CompanyPersonDto, CompanyPersonTableDto } from "../types";
import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";

const baseUrl = "/company-manager-app/api/company-person";

export const addCompanyPerson = (companyPersonDto: Partial<CompanyPersonDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, companyPersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllCompanyPersons = (
  companyId: number,
  source: CancelTokenSource): Promise<Array<CompanyPersonTableDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { companyId } })
    .then(response => response.data);
};

export const getCompanyPerson = (id: number, source: CancelTokenSource): Promise<CompanyPersonDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editCompanyPerson = (
  companyPersonDto: Partial<CompanyPersonDto>,
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, companyPersonDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteCompanyPerson = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
