import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { StateFrom } from "../../../components/interfaces/enums";
import { getAllCompanies, getCompany } from "../../Companies/services/CompanyServices";
import {
  addProject,
  disableProject,
  editProject,
  enableProject,
  getNewProjectNumberForParticularCompany,
  getProject
} from "../services/ProjectServices";
import ProjectTaskPage from "../../ProjectTasks/ProjectTaskPage";
import { useTranslation } from "react-i18next";
import ProjectPersonTable from "../../ProjectPersons/ProjectPersonPage";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectStatus, ProjectType } from "../../../ts-types/api.enums";
import { CompanyDto, ProjectDto } from "../../../ts-types/api.types";
import { Check } from "@mui/icons-material";

const AddEditProject = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const [companyName, setCompanyName] = React.useState<string>("");
  const [projectNumber, setProjectNumber] = React.useState<string>("");
  const [project, setProject] = React.useState<ProjectDto>({
    active: true,
    stamp: "",
    companyId: state.companyId === undefined ? undefined : state.companyId,
    id: 0,
    name: "",
    projectNumber: state.companyId === undefined ? "" : projectNumber,
    projectType: ProjectType.DEVELOPMENT,
    status: ProjectStatus.OPEN
  });
  const [companies, setCompanies] = React.useState<Array<CompanyDto>>([]);
  const [message, setMessage] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [selectedOption, setSelectedOption] = React.useState<CompanyDto>({
    active: true,
    deleted: false,
    stamp: "",
    address: "",
    city: "",
    email: "",
    id: 0,
    isoCode: "",
    name: "",
    phoneOffice: "",
    shortName: "",
    url: "",
    zip: ""
  });

  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("headers.editProject"));
        loadCompanies();
        loadProject();
        break;
      case StateFrom.DETAILS:
        setMessage(t("headers.projectDetails"));
        loadProject();
        break;
      case StateFrom.ADD:
        setMessage(t("headers.addProject"));
        loadCompanies();
        loadCompanyName();
        loadProjectNumber();
        break;
    }
  }, []);

  const loadCompanies = React.useCallback(() => {
    getAllCompanies("", cancelTokenSource)
      .then(r => setCompanies(r));
  }, [companies]);

  const loadProject = React.useCallback(() => {
    getProject(state.projectId, cancelTokenSource)
      .then(r => {
        setProject(r);
        setProjectNumber(r.projectNumber);
        getCompany(r.companyId, cancelTokenSource)
          .then(companyResponse => setSelectedOption(companyResponse));
      });
  }, [project]);

  const loadCompanyName = React.useCallback(() => {
    if (state.companyId) {
      getCompany(state.companyId, cancelTokenSource)
        .then(r => setCompanyName(r.name));
    }
  }, []);

  const loadProjectNumber = React.useCallback(() => {
    if (state.companyId) {
      getNewProjectNumberForParticularCompany(state.companyId, cancelTokenSource)
        .then(r => setProjectNumber(r));
    }
  }, []);

  const handleCompanyChangeSelection = (newValue: CompanyDto) => {
    setSelectedOption(newValue);
    if (newValue.id) {
      setProject({
        ...project,
        companyId: newValue.id
      });
      getNewProjectNumberForParticularCompany(newValue.id, cancelTokenSource)
        .then(r => setProjectNumber(r));
    }
  };

  const handleChange = (e:any) => {
    setProject({
      ...project,
      [e.target.name]: e.target.value
    });
  };

  const handleProjectNumberChange = (e:any) => {
    setProjectNumber(e.target.value);
  };

  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.NAME_ALREADY_EXISTS,
        errorUtils.PROJECT_NUMBER_ALREADY_EXISTS,
        errorUtils.INVALID_PATTERN
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`${violation.details}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }

      setErrorMessage(errMsgs);
    }
  };

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        project.projectNumber = projectNumber;
        addProject(project, cancelTokenSource)
          .then(() => {
            prevPath === "/upsert-company"
              ? history.push("/upsert-company",
                { id: state.companyId, search: state.search, view: state.view, from: StateFrom.DETAILS })
              : history.push("/project");
          }).catch((e) => handleError(e.response.data));
        break;
      case StateFrom.EDIT:
        project.projectNumber = projectNumber;
        editProject(project, state.projectId, cancelTokenSource)
          .then(() => {
            prevPath === "/upsert-company"
              ? history.push("/upsert-company",
                { id: state.companyId, search: state.search, view: state.view, from: StateFrom.DETAILS })
              : history.push("/project");
          }).catch((e) => handleError(e.response.data));
        break;
    }
  };

  return (
      <>
        <h1>{message}</h1>
        <Collapse in={!!errorMessage}>
          <Alert
              severity={"error"}
              action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorMessage(undefined);
                    }}
                >
                  <CloseIcon fontSize="inherit"/>
                </IconButton>
              }
              sx={{ mb: 2 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
      <FormControl>
        <Stack direction={"row"}>

          {
            state.from === StateFrom.ADD
              ? state.companyId === undefined
                ? <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={companies}
                        getOptionLabel={(option: CompanyDto) => option.name}
                        value={selectedOption}
                        onChange={(event: any, newValue: CompanyDto) => {
                          handleCompanyChangeSelection(newValue);
                        }}
                        sx={{ width: 250, m: 2 }}
                        renderInput={(params) =>
                            <TextField {...params} label={t("legend.company")}/>}
                    />
                : <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[]}
                        value={companyName}
                        readOnly
                        freeSolo
                        sx={{ width: 250, m: 2 }}
                        renderInput={(params) =>
                            <TextField {...params} label={t("legend.company")}/>}
                    />
              : ""
          }

          {
                  state.from === StateFrom.EDIT
                    ? <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={companies}
                          getOptionLabel={(option: CompanyDto) => option.name}
                          value={selectedOption}
                          onChange={(event: any, newValue: CompanyDto) => {
                            handleCompanyChangeSelection(newValue);
                          }}
                          sx={{ width: 250, m: 2 }}
                          renderInput={(params) =>
                              <TextField {...params} label={t("legend.company")}/>}
                      />
                    : <></>
                }

        <TextField
            name={"name"}
            sx={{ width: 250, m: 2 }}
            label={t("common.name")}
            value={project.name}
            onChange={handleChange}/>
        </Stack>
        <Stack direction={"row"}>
        <FormControl>
          <InputLabel sx={{ width: 250, m: 2 }}>{t("common.projectType")}</InputLabel>
          <Select
              sx={{ width: 250, m: 2 }}
              name={"projectType"}
              type="text"
              label={t("common.projectType")}
              value={project.projectType}
              onChange={handleChange}>
            <MenuItem key={1} value={ProjectType.DEVELOPMENT}>
              {t("option.development")}
            </MenuItem>
            <MenuItem key={2} value={ProjectType.MAINTENANCE}>
              {t("option.maintenance")}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel sx={{ width: 250, m: 2 }}>{t("common.projectStatus")}</InputLabel>
          <Select
              sx={{ width: 250, m: 2 }}
              name={"status"}
              type="text"
              label={t("common.projectStatus")}
              value={project.status}
              onChange={handleChange}>
            <MenuItem key={1} value={ProjectStatus.OPEN}>
              {t("option.open")}
            </MenuItem>
            <MenuItem key={2} value={ProjectStatus.RUNNING}>
              {t("option.running")}
            </MenuItem>
            <MenuItem key={3} value={ProjectStatus.CLOSED}>
              {t("option.closed")}
            </MenuItem>
            <MenuItem key={4} value={ProjectStatus.PERMANENT}>
              {t("option.permanent")}
            </MenuItem>
          </Select>
        </FormControl>
        </Stack>
        <Stack>
          <TextField
              name={"projectNumber"}
              sx={{ width: 250, m: 2 }}
              label={t("common.projectNumber")}
              value={projectNumber}
              onChange={handleProjectNumberChange}/>
        </Stack>
        <Stack direction={"row"} justifyContent={"right"}>
          <Button
              sx={{ m: 1 }}
              variant={"outlined"}
              type={"submit"}
              disabled={state?.from === StateFrom.DETAILS}
              onClick={onFormSubmit}>
            {t("common.save")}
          </Button>
          <Box sx={{ m: 1 }}>
            {state.from !== StateFrom.ADD
              ? (project.active
                  ? <YNPopper
                    message={t("common.disable")}
                    color={"error"}
                    variant={"contained"}
                    icon={<DeleteIcon/>}
                    disabled={state.role === "ROLE_EMPLOYEE" || state?.from === StateFrom.DETAILS}
                    onConfirm={() => {
                      disableProject(project.id, cancelTokenSource);
                      history.push("/project");
                    }}/>
                  : <YNPopper
                    message={t("common.enable")}
                    color={"success"}
                    variant={"contained"}
                    icon={<Check/>}
                    disabled={state.role === "ROLE_EMPLOYEE" || state?.from === StateFrom.DETAILS}
                    onConfirm={() => {
                      enableProject(project.id, cancelTokenSource);
                      history.push("/project");
                    }}/>
                )
              : <> </>}
          </Box>
          <Button sx={{ m: 1 }}
                  variant={"outlined"}
                  onClick={() => {
                    prevPath === "/upsert-company"
                      ? history.push("/upsert-company",
                        { id: state.companyId, search: state.search, view: state.view, from: StateFrom.DETAILS })
                      : history.push("/project");
                  }
                     }>
            {t("common.back")}
          </Button>
        </Stack>
      </FormControl>
        {project.id && state.from === StateFrom.DETAILS
          ? <>
              <ProjectTaskPage projectId={project.id}/>
              <ProjectPersonTable
                search={state.search}
                project={project}
                view={state.view}/></>
          : <></>}

      </>
  );
};
export default AddEditProject;
