import moment from "moment";

const DATE_FORMAT = "DD.MM.YYYY";

export const formatDate = (date: string): string => {
  return moment(date).format(DATE_FORMAT);
};

export const isoToDisplayDateTime = (isoDateTime?: string): string => {
  if (!isoDateTime) {
    return "";
  }

  return moment(isoDateTime).format("DD.MM.YYYY HH:mm");
};
