import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Dialog, DialogTitle, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { updateHolidayDays } from "../../../service/upsertAdminServices";

interface Props {
    userId?: number;
    oldAllowanceDays?: number;
    onUpdateAllowanceDaysSuccess: (newAllowanceDays: number) => void;
    onClose: () => void
}

export const UpdateAllowanceDays: React.FC<Props> = (props: Props) => {
  const { userId, oldAllowanceDays = 20, onUpdateAllowanceDaysSuccess } = props;
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation("company-manager");

  const [error, setError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [allowanceDays, setAllowanceDays] = useState<number>(oldAllowanceDays);

  React.useEffect(() => {
    setModalOpen(!!userId);
  }, [userId]);

  const onModalClose = React.useCallback(() => {
    setModalOpen(false);
    props.onClose();
  }, []);

  const errorAlert = React.useMemo(() => {
    return error
      ? (
                <div className="alert alert-danger" role="alert">
                    Please enter a valid number higher than 0
                </div>
        )
      : "";
  }, [error]);

  const updatingHolidayDays = React.useCallback(() => {
    if (userId) {
      if (allowanceDays <= 0) {
        setError(true);
      } else {
        setError(false);

        updateHolidayDays(userId, allowanceDays, cancelTokenSource)
          .then(() => onUpdateAllowanceDaysSuccess(allowanceDays));
      }
    }
  }, [userId, allowanceDays, onUpdateAllowanceDaysSuccess, cancelTokenSource]);

  return (
        <>
            <Dialog open={modalOpen}>
                <Box sx={{ width: 400 }}>
                    <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                      {t("header.updateEmployeeAllowanceDays")}
                    </DialogTitle>
                    <Stack justifyContent={"center"} direction={"column"}>
                        {errorAlert}
                        <TextField
                            sx={{ m: 2, mx: 5 }}
                            size={"small"}
                            type="number"
                            id="allowanceDays"
                            name="allowanceDays"
                            defaultValue={oldAllowanceDays}
                            required
                            onChange={({ target: { value } }) => setAllowanceDays(+value)}
                        />
                        <Stack justifyContent={"center"} direction={"row"} sx={{ mb: 1 }}>
                            <Button
                                sx={{ m: 1 }}
                                variant={"outlined"}
                                type="button"
                                    className="btn btn-primary"
                                    onClick={updatingHolidayDays}
                            >
                                {t("common.save")}
                            </Button>
                            <Button
                                sx={{ m: 1 }}
                                variant={"outlined"}
                                type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={onModalClose}
                            >
                                {t("common.close")}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
        </>
  );
};
