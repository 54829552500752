import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { UpsertEmployeeVacationDto } from "../types";
import { AddEmployeeVacationTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/employee-vacation";

export const addEmployeeVacation = (
  employeeVacationDto: Partial<UpsertEmployeeVacationDto>,
  source: CancelTokenSource
) => {
  return axios
    .post(`${baseUrl}`, employeeVacationDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getThisYearCredits = (source: CancelTokenSource, employeeId: number | undefined) => {
  return axios
    .get(`${baseUrl}/present-year-credits`, { cancelToken: source.token, params: { employeeId } })
    .then(response => response.data);
};

export const getEmployeeVacation = (id: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getPrevYearCredits = (employeeId: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/prev-year-credits`, { cancelToken: source.token, params: { employeeId } })
    .then(response => response.data);
};

export const getThisYearRecords = (source: CancelTokenSource, employeeId?: number) => {
  return axios
    .get(`${baseUrl}/all-by-employee`, { cancelToken: source.token, params: { employeeId } })
    .then(response => response.data);
};

export const deleteEmployeeVacation = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editEmployeeVacation = (id: number, vacationDto: UpsertEmployeeVacationDto, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/${id}`, vacationDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllForEmployeeVacation = (source: CancelTokenSource): Promise<Array<AddEmployeeVacationTableDto>> => {
  return axios
    .get(`${baseUrl}/all-for-upsert`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getRecordsByYear = (source: CancelTokenSource, year: number, employeeId?: number) => {
  return axios
    .get(`${baseUrl}/all-for-year`, { cancelToken: source.token, params: { year, employeeId } })
    .then(response => response.data);
};
