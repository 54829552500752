import axios from "../../../custom-axios/axios";
import { PersonDto, UpsertPersonDto } from "../types";
import { AxiosResponse, CancelTokenSource } from "axios";
import { PersonTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/person";

export const addPerson = (values: Partial<UpsertPersonDto>, source: CancelTokenSource): Promise<PersonDto> => {
  const formData: FormData = new FormData();
  Object.keys(values).forEach((key) => {
    let value = values[key as keyof UpsertPersonDto];

    if (value) {
      if (typeof value === "number" || typeof value === "boolean") {
        value = value.toString();
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      formData.append(key, value);
    }
  });
  return axios
    .post(`${baseUrl}/add`, formData, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllPersons = (search: string, source: CancelTokenSource): Promise<Array<PersonDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};

export const getAllPersonsTable = (
  search: string,
  includeInactive: boolean,
  source: CancelTokenSource
) : Promise <Array<PersonTableDto>> => {
  return axios
    .get(`${baseUrl}/alltable`, { cancelToken: source.token, params: { search, includeInactive } })
    .then(response => response.data);
};

export const getAllPersonsWithoutEmployees = (search: string, source: CancelTokenSource): Promise<Array<PersonDto>> => {
  return axios
    .get(`${baseUrl}/all-without-employees`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};

export const getAllPersonsWithoutCompany = (search: string, source: CancelTokenSource): Promise<Array<PersonDto>> => {
  return axios
    .get(`${baseUrl}/all-without-company`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};

export const getPerson = (id: number | undefined, source: CancelTokenSource): Promise<PersonDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editPerson = (values: Partial<UpsertPersonDto>, id: number, source: CancelTokenSource): Promise<PersonDto> => {
  const formData: FormData = new FormData();

  Object.keys(values).forEach((key) => {
    let value = values[key as keyof UpsertPersonDto];
    if (value) {
      if (typeof value === "number" || typeof value === "boolean") {
        value = value.toString();
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      formData.append(key, value);
    }
  });

  return axios
    .put(`${baseUrl}/edit/${id}`, formData, { cancelToken: source.token })
    .then(response => response.data);
};

export const disablePerson = (id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/disable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const enablePerson = (id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/enable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const hasUser = (personId: number, source: CancelTokenSource) => {
  return axios
    .get(`${baseUrl}/has-user`, { cancelToken: source.token, params: { personId } })
    .then(response => response.data);
};

export const resendEmail = (id: number, source: CancelTokenSource): Promise<AxiosResponse<any>> => {
  return axios
    .post(`${baseUrl}/resend/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const deletePhoto = (id:number, source: CancelTokenSource): Promise<AxiosResponse<any>> => {
  return axios
    .put(`${baseUrl}/delete-photo/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
