import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { CompanyDto, CompanyProjectTableDto, CompanyTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/companies";

export const addCompany = (
  companyDto: Partial<CompanyDto>,
  source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, companyDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllCompanies = (
  search: string,
  source: CancelTokenSource): Promise<Array<CompanyDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};

export const getAllCompaniesTable = (
  includeInactive: boolean,
  search: string,
  source: CancelTokenSource): Promise<Array<CompanyTableDto>> => {
  return axios
    .get(`${baseUrl}/table`, { cancelToken: source.token, params: { includeInactive, search } })
    .then(response => response.data);
};

export const getProjectsForParticularCompany = (
  companyId: number,
  source: CancelTokenSource): Promise<Array<CompanyProjectTableDto>> => {
  return axios
    .get("/company-manager-app/api/project/by-company",
      { cancelToken: source.token, params: { companyId } })
    .then(response => response.data);
};

export const getCompany = (
  id: number,
  source: CancelTokenSource): Promise<CompanyDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editCompany = (
  companyDto: Partial<CompanyDto>,
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, companyDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const disableCompany = (
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/disable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const enableCompany = (
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/enable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
