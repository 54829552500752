import React, { useState } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import Countries from "../../../components/interfaces/Countries.json";
import { Role, StateFrom } from "../../../components/interfaces/enums";
import { UpsertPersonDto } from "../types";
import {
  addPerson,
  deletePhoto,
  disablePerson,
  editPerson,
  enablePerson,
  getPerson,
  hasUser
} from "../services/PersonServices";
import { useTranslation } from "react-i18next";
import { UpsertUserDto } from "../../Users/types";
import { getRole, register } from "../../../service/upsertAdminServices";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Check } from "@mui/icons-material";

const initialUpsertPersonDtoProps: Partial<UpsertPersonDto> = {
  firstName: "",
  lastName: "",
  zip: "",
  city: "",
  isoCode: "",
  address: "",
  mobile: "",
  otherPhone: "",
  email: "",
  active: true
};

const AddEditPerson = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { state } = useLocation<any>();
  const [message, setMessage] = React.useState<string>("");
  const [formData, updateFormData] = React.useState<Partial<UpsertPersonDto>>(initialUpsertPersonDtoProps);
  const { t } = useTranslation("company-manager");
  const [addUser, setAddUser] = React.useState<boolean>(false);
  const [selectedRole, setSelectedRole] = React.useState<Role>(Role.ROLE_EMPLOYEE);
  const [personHasUser, setPersonHasUser] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [photoData, setPhotoData] = useState<Blob | undefined>(undefined);
  const [photoPreviewData, setPhotoPreviewData] = useState<string | undefined>("");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        getPerson(state?.id, cancelTokenSource).then((r) => {
          const personDto: Partial<UpsertPersonDto> = {
            firstName: r.firstName,
            lastName: r.lastName,
            zip: r.zip,
            city: r.city,
            isoCode: r.isoCode,
            address: r.address,
            mobile: r.mobile,
            otherPhone: r.otherMobile,
            email: r.email,
            active: r.active,
            imageDataContent: r.imageData
          };
          updateFormData(personDto);
          setPhotoData(r.imageData);
        });
        setMessage(t("header.editPerson"));
        break;
      case StateFrom.DETAILS:
        getPerson(state?.id, cancelTokenSource).then((r) => {
          const personDto: Partial<UpsertPersonDto> = {
            firstName: r.firstName,
            lastName: r.lastName,
            zip: r.zip,
            city: r.city,
            isoCode: r.isoCode,
            address: r.address,
            mobile: r.mobile,
            otherPhone: r.otherMobile,
            email: r.email,
            active: r.active,
            imageDataContent: r.imageData
          };
          updateFormData(personDto);
          setPhotoData(r.imageData);
        });
        setMessage(t("header.personDetails"));
        break;
      case StateFrom.ADD:
        updateFormData(initialUpsertPersonDtoProps);
        setMessage(t("header.addNewPerson"));
        break;
    }
    if (state.from === StateFrom.DETAILS || state.from === StateFrom.EDIT) {
      hasUser(state.id, cancelTokenSource)
        .then(r => {
          setPersonHasUser(r);
        });
      if (personHasUser) {
        getRole(state.id, cancelTokenSource).then(t => {
          t.name === "ROLE_ADMIN" ? setSelectedRole(Role.ROLE_ADMIN) : setSelectedRole(Role.ROLE_EMPLOYEE);
        });
      }
    } else {
      setPersonHasUser(true);
    }
  }, [state.id, photoData]);

  const onFormSubmit = async (e: any) => {
    const roleList: Array<Role> = [];
    roleList.push(selectedRole);
    const newUser: Partial<UpsertUserDto> = {
      name: formData.firstName,
      surname: formData.lastName,
      username: formData.email,
      roles: roleList,
      personId: state.id,
      lastLogin: formData.lastLogin
    };
    e.preventDefault();
    if (state?.id) {
      editPerson(formData, state.id, cancelTokenSource).then(r => {
        if (addUser) {
          register({
            ...newUser,
            personId: r.id
          }, cancelTokenSource)
            .then(() => history.push("/person"));
        } else {
          history.push("/person");
        }
      }).catch((e) => handleError(e.response.data));
    } else {
      addPerson(formData, cancelTokenSource).then((r) => {
        if (addUser) {
          register({
            ...newUser,
            personId: r.id
          }, cancelTokenSource)
            .then(() => history.push("/person"));
        } else {
          history.push("/person");
        }
      }).catch((e) => handleError(e.response.data));
    }
  };
  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.EMAIL_ALREADY_EXISTS, errorUtils.INVALID_EMAIL, errorUtils.NAME_FIELD_IS_EMPTY];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }

      setErrorMessage(errMsgs);
    }
  };

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [formData]);
  const handleImageChange = (e: any) => {
    updateFormData({
      ...formData,
      imageData: e.target.files[0]
    });
    setPhotoPreviewData(URL.createObjectURL(e.target.files[0]));
  };
  const handleAddUser = () => {
    setAddUser(!addUser);
  };

  const disPerson = (id: number) => {
    disablePerson(id, cancelTokenSource)
      .then(() => history.push("/person"));
  };

  const enaPerson = (id: number) => {
    enablePerson(id, cancelTokenSource)
      .then(() => history.push("/person"));
  };

  const handleChangeRole = React.useCallback((e) => {
    setSelectedRole(e.target.value);
  }, [updateFormData, formData]);

  return (
      <Box>
        <h2>{message}</h2>
          <Collapse in={!!errorMessage}>
              <Alert
                  severity={"error"}
                  action={
                      <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setErrorMessage(undefined);
                          }}
                      >
                          <CloseIcon fontSize="inherit"/>
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
              >
                  {errorMessage}
              </Alert>
          </Collapse>
            <FormControl onSubmit={onFormSubmit}>
                <Box display={"inline-block"}>
                    <Stack direction={"column"}>
                        <Stack direction={"row"}>
                            <TextField
                                required
                                sx={{ m: 1, mx: 2 }}
                                name={"firstName"}
                                size={"small"}
                                type="text"
                                label={t("common.firstName")}
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                sx={{ m: 1, mx: 2 }}
                                name={"lastName"}
                                size={"small"}
                                type="text"
                                label={t("common.lastName")}
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                            <TextField
                                required = {addUser}
                                helperText={(addUser && formData.email === "") && t("error.EMAIL_IS_REQUIRED") }
                                sx={{ minWidth: 350, m: 1, mx: 2 }}
                                name={"email"}
                                size={"small"}
                                type="text"
                                label={t("common.email")}
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Stack>
                        <Stack direction={"row"}>
                            <TextField
                                sx={{ m: 1, mx: 2 }}
                                name={"zip"}
                                size={"small"}
                                type="text"
                                label={t("legend.zip")}
                                value={formData.zip}
                                onChange={handleChange}
                            />
                            <TextField
                                sx={{ m: 1, mx: 2 }}
                                name={"city"}
                                size={"small"}
                                type="text"
                                label={t("common.city")}
                                value={formData.city}
                                onChange={handleChange}
                            />
                            <TextField
                                sx={{ minWidth: 350, m: 1, mx: 2 }}
                                name={"address"}
                                size={"small"}
                                type="text"
                                label={t("common.address")}
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Stack>
                        <Stack direction={"row"}>
                            <TextField
                                sx={{ m: 1, mx: 2 }}
                                name={"mobile"}
                                size={"small"}
                                type="text"
                                label={t("common.mobile")}
                                value={formData.mobile}
                                onChange={handleChange}
                            />
                            <TextField
                                sx={{ m: 1, mx: 2 }}
                                name={"otherMobile"}
                                size={"small"}
                                type="text"
                                label={t("legend.otherPhone")}
                                value={formData.otherPhone}
                                onChange={handleChange}
                            />
                            <FormControl>
                                <InputLabel id="selectCountry"
                                            sx={{ mx: 2, m: 1, pl: 2 }}>
                                    Country
                                </InputLabel>
                                <Select
                                    size={"small"}
                                    sx={{ minWidth: 350, m: 1, mx: 2 }}
                                    labelId="selectCountry"
                                    id="selectCountry"
                                    name="isoCode"
                                    label={t("header.country")}
                                    value={formData.isoCode ? formData.isoCode : "//"}
                                    onChange={handleChange}>
                                    { Countries.map((country, index) => {
                                      return (
                                                <MenuItem key={index}
                                                          value={country.isoCode}>
                                                    {country.name} ({country.dialCode})
                                                </MenuItem>
                                      );
                                    }
                                    )}
                                </Select>
                            </FormControl>
                        </Stack>
                      <Stack>
                        <Input
                          type="file"
                          onChange={handleImageChange}
                          inputProps={{ accept: ".jpg, .png, .jpeg" }}
                          sx = {{ px: 3, py: 3 }}
                          disabled={photoData !== null && photoData !== undefined}
                        />
                        <Stack direction={"row"} display={"flex"} justifyContent={"left"}>
                          <Box
                            component="img"
                            sx={{
                              height: 233,
                              width: 350,
                              maxHeight: { xs: 233, md: 167 },
                              maxWidth: { xs: 350, md: 250 }
                            }}
                            alt={t("common.noPhoto")}
                            src={photoPreviewData !== "" ? photoPreviewData : `data:image/png;base64,${photoData}`}
                          />
                          {
                            photoData && state?.from !== StateFrom.DETAILS &&
                            (<Box sx={{ width: 100, position: "relative" }}>
                                <YNPopper
                                  message={t("common.delete")}
                                  color={"error"}
                                  variant={"contained"}
                                  icon={<DeleteIcon/>}
                                  disabled={state.role === "ROLE_EMPLOYEE" || state?.from === StateFrom.DETAILS}
                                  onConfirm={() => {
                                    deletePhoto(state.id, cancelTokenSource);
                                    setPhotoData(undefined);
                                    setPhotoPreviewData("");
                                  }}/>
                              </Box>)
                          }
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      {(!personHasUser && state.from === StateFrom.EDIT) || state.from === StateFrom.ADD
                        ? <Box sx={{ m: 2 }}>
                            <FormControlLabel control={<Checkbox
                              checked={addUser}
                              onChange={handleAddUser}
                              inputProps={{ "aria-label": "controlled" }}/>}
                                              label={t("common.addUser")} />
                          {addUser && (
                              <FormControl sx={{ width: 225 }}>
                                <FormControl component="fieldset">
                                  <RadioGroup aria-label="User" value={selectedRole} onChange={handleChangeRole}>
                                    <FormControlLabel value={Role.ROLE_EMPLOYEE} control={<Radio />} label={t("option.employee")}/>
                                    <FormControlLabel value={Role.ROLE_ADMIN} control={<Radio />} label={t("option.admin")} />
                                  </RadioGroup>
                                </FormControl>
                              </FormControl>
                          )
                          }
                      </Box>
                        : <Box>
                          <FormControl sx={{ width: 225 }}>
                            <FormControl component="fieldset">
                              <RadioGroup aria-label="User" value={selectedRole} onChange={handleChangeRole}>
                                <FormControlLabel value={Role.ROLE_EMPLOYEE} control={<Radio />} label={t("option.employee")}/>
                                <FormControlLabel value={Role.ROLE_ADMIN} control={<Radio />} label={t("option.admin")} />
                              </RadioGroup>
                            </FormControl>
                          </FormControl>
                        </Box>}
                        <Box>
                        <Button
                            sx={{ m: 1 }}
                            variant={"outlined"}
                            type={"submit"}
                            disabled={state?.from === StateFrom.DETAILS || (addUser && formData.email === "")}
                            onClick={onFormSubmit}>
                          {t("common.save")}
                        </Button>
                          {state.from !== StateFrom.ADD
                            ? (formData.active
                                ? <YNPopper
                                  message={t("common.disable")}
                                  color={"error"}
                                  variant={"contained"}
                                  icon={<DeleteIcon/>}
                                  disabled={state.from === StateFrom.DETAILS}
                                  onConfirm={() => disPerson(state.id)}/>
                                : <YNPopper
                                  message={t("common.enable")}
                                  color={"success"}
                                  variant={"contained"}
                                  icon={<Check/>}
                                  disabled={state.from === StateFrom.DETAILS}
                                  onConfirm={() => enaPerson(state.id)}/>
                              )
                            : <> </>}
                        <Button sx={{ m: 1 }}
                                variant={"outlined"}
                                onClick={() => history.push("/person")}>
                          {t("common.back")}
                        </Button>
                      </Box>
                    </Stack>
                </Box>
            </FormControl>
      </Box>
  );
};

export default AddEditPerson;
