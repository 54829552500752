import React, { FC } from "react";
import { CompanyBankProps } from "../../components/interfaces/interface";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack } from "@mui/material";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { deleteCompanyBank } from "./services/CompanyBankServices";
import { Index } from "react-virtualized";
import { emptyTableCell } from "../../utils/tableUtils";

import DoneIcon from "@mui/icons-material/Done";

const CompanyBankTable: FC<CompanyBankProps> = (props): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");
  const { state } = useLocation<any>();

  const renderCompanyBankTable = (): JSX.Element => {
    const disableCompanyBank = (id: number) => {
      deleteCompanyBank(id, cancelTokenSource);
    };

    const defaultCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <DoneIcon sx={{ fontSize: "16px" }}/>;
      }
      return emptyTableCell();
    };

    const actions = ({ rowData }: any) => {
      const companyBankId = rowData.id;
      return (
                <Box width={100}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        onClick={() =>
                          history.push("/companybank",
                            {
                              id: companyBankId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.DETAILS,
                              companyId: state.id
                            })}>
                        {t("common.details")}
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/companybank",
                            {
                              id: companyBankId,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.EDIT,
                              companyId: state.id
                            })}>
                        {t("common.edit")}
                    </Button>
                    <YNPopper
                        message={t("common.delete")}
                        color={"error"}
                        variant={"contained"}
                        icon={<DeleteIcon/>}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onConfirm={() => { disableCompanyBank(rowData.id); window.location.reload(); }}/>
                </Box>
      );
    };
    const companyBankRowGetter = ({ index }: Index) => {
      if (props.companyBanks) {
        Object.assign(props.companyBanks[index], { index: index + 1 });

        return props.companyBanks[index];
      }
      return emptyTableCell();
    };

    const companyBankRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };

    return (
            <VirtualizedTable
                rowCount={props.companyBanks ? props.companyBanks.length : 0}
                rowGetter={companyBankRowGetter}
                rowRenderer={companyBankRowRenderer}
                columns={[
                  {
                    width: 100,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 300,
                    label: t("header.accountNo"),
                    dataKey: "accountNo"
                  },
                  {
                    flexGrow: 1,
                    width: 300,
                    label: t("header.ibanCode"),
                    dataKey: "ibanCode"
                  },
                  {
                    width: 300,
                    label: t("header.bank"),
                    dataKey: "bank"
                  },
                  {
                    flexGrow: 1,
                    width: 300,
                    label: t("header.bankAddress"),
                    dataKey: "bankAddress"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("header.swift"),
                    dataKey: "swift"
                  },
                  {
                    width: 100,
                    flexGrow: 1,
                    label: t("legend.defaultBank"),
                    dataKey: "defaultBank",
                    cellRenderer: (defaultCellRenderer)
                  },
                  {
                    width: 400,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((props.companyBanks ? props.companyBanks.length * rowHeight : 0) + 25, maxTableHeight);

  return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3></h3>
                <Stack alignSelf={"center"}>
                    <Button
                        variant={"contained"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/companybank",
                            { search: props.search, view: props.view, from: StateFrom.ADD, companyId: state.id })}>
                        {t("button.addCompanyBank")}
                    </Button>
                </Stack>
            </Stack>
            <Box style={{ height: tableHeight }}>
                {renderCompanyBankTable()}
            </Box>
        </Box>
  );
};
export default CompanyBankTable;
