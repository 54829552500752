import React, { ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../components/interfaces/enums";

interface ChildrenInterface {
  children: ReactNode
}

const AdminRoute = ({ children } : ChildrenInterface) => {
  const { user } = useAuthContext();
  if (!user?.roles?.includes(Role.ROLE_ADMIN)) {
    return (
      <Redirect to="/timetracking"/>
    );
  }
  return <>{children}</>;
};

export default AdminRoute;
