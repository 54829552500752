import React from "react";
import { AccountRemarkProps } from "../../../components/interfaces/interface";
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AccountRemarkModal: React.FC<AccountRemarkProps> = (props: AccountRemarkProps) => {
  const { t } = useTranslation("company-manager");
  const { form, rowIndex, timetracks } = props;
  const [accountedRemark, setAccountedRemark] = React.useState<string>(form.get(rowIndex)!.accountedRemark);

  const handleFormChange = (e: any) => {
    setAccountedRemark(e.target.value);
  };

  const handleSave = () => {
    const newFormMap = new Map(form);
    const newObj = newFormMap.get(rowIndex);
    if (newFormMap && newObj !== undefined) {
      newObj.accountedRemark = accountedRemark;
      newFormMap.set(rowIndex, newObj);
      props.save(newFormMap);
    }
    props.close();
  };

  return (
      <Dialog open={props.show} maxWidth={"md"}>
        <Box>
          <DialogTitle>{t("header.accountRemark")}</DialogTitle>
          <DialogContent>
            <Box>
              <Stack direction={"column"}>
                <Box sx={{ width: 362, mx: "7px", mt: 1, height: 200 }} justifySelf={"center"}>
                  <fieldset disabled={true} style={{
                    borderColor: "silver",
                    borderRadius: 5,
                    padding: 0,
                    borderStyle: "solid",
                    borderWidth: 1
                  }}>
                    <legend style={{ marginLeft: 9, fontSize: 15, paddingInline: 4 }}>Remark</legend>
                    <TextareaAutosize
                        disabled={true}
                        minRows={3}
                        value={timetracks.get(rowIndex)?.remark}
                        style={{ width: 356, border: "none", outline: "none", height: 175 }}
                    />
                  </fieldset>
                </Box>
                <Box sx={{ width: 362, mx: "7px", mt: 1, height: 200 }} justifySelf={"center"}>
                  <fieldset style={{
                    borderColor: "silver",
                    borderRadius: 5,
                    padding: 0,
                    borderStyle: "solid",
                    borderWidth: 1
                  }}>
                    <legend style={{ marginLeft: 9, fontSize: 15, paddingInline: 4 }}>Account remark</legend>
                    <TextareaAutosize
                        minRows={3}
                        value={accountedRemark}
                        onChange={handleFormChange}
                        style={{ width: 356, border: "none", outline: "none", height: 175 }}
                    />
                  </fieldset>
                </Box>
              </Stack>
              <Stack direction={"row"} justifyContent={"center"}>
                <Button
                    sx={{ m: 2 }}
                    type="button"
                    onClick={handleSave}
                >
                  {t("common.save")}
                </Button>

                <Button
                    sx={{ m: 2 }}
                    type="button"
                    onClick={() => props.close()}
                >
                  {t("common.back")}
                </Button>
              </Stack>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
  );
};
