import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { ResetPasswordDto } from "../types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { resetPassword } from "../services/resetPasswordServices";

const InitialResetPasswordDto: ResetPasswordDto = {
  password: "",
  repeatPassword: "",
  token: ""
};

const ResetPassword = () => {
  const history = useHistory();
  const { t } = useTranslation("login");
  const cancelTokenSource = React.useMemo(axios.CancelToken.source, []);
  const [form, setForm] = React.useState<ResetPasswordDto>(InitialResetPasswordDto);
  const queryParams = new URLSearchParams(history.location.search);
  const token = queryParams.get("token");

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    resetPassword({ ...form, token }, cancelTokenSource)
      .then(() => history.push("/login"));
  };

  return (
      <>
        <Box alignSelf={"center"} justifySelf={"center"}>
          <Stack direction={"column"}>
            <Box display={"flex"} justifyContent={"center"}>
              <h1>{t("header.resetPassword")}</h1>
            </Box>
            <Box sx={{ my: 2 }} display={"flex"} justifyContent={"center"}>
              <Stack direction={"column"}>
              <TextField
                  sx={{ m: 1 }}
                  name={"password"}
                  size={"small"}
                  type={"password"}
                  value={form.password}
                  onChange={handleChange}
                  label={t("legend.enterPassword")}/>
              <TextField
                  sx={{ m: 1 }}
                  name={"repeatPassword"}
                  size={"small"}
                  type={"password"}
                  value={form.repeatPassword}
                  onChange={handleChange}
                  label={t("legend.repeatPassword")}/>
              </Stack>
            </Box>
            <Box>
              <hr/>
            </Box>
            <Stack direction={"row"} display={"flex"} justifyContent={"center"}>
              <Button
                  sx={{ mx: 1 }}
                  type={"button"}
                  onClick={handleSubmit}
                  variant={"contained"}>
                {t("button.changePassword")}
              </Button>
              <Button
                  type={"button"}
                  variant={"contained"}
                  sx={{ background: "#64748B", mx: 1 }}
                  onClick={() => history.push("/login")}>
                {t("button.backToLogin")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
  );
};
export default ResetPassword;
