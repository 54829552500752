import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { AccountedTimeTrackingDto, AccountedWorkingReportDto } from "../types";
import FileDownload from "js-file-download";
import moment from "moment/moment";
import { RequestBillsDto } from "../../../ts-types/api.types";
import { ReworkingReportDto } from "../../TimeTrackings/types";

const baseUrl = "/company-manager-app/api/accounted-time-trackings";

export const getAccountedTimetrackings = (
  reworkingReportDto: AccountedWorkingReportDto | ReworkingReportDto,
  source: CancelTokenSource) => {
  return axios
    .post(baseUrl, reworkingReportDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const addAccountedTimeTrackings = (timeTrackList: Array<AccountedTimeTrackingDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/all`, timeTrackList, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAccountedTimeTrackingReportExportUrl = (
  dateFrom: string,
  dateTo: string,
  employeeShortName: string,
  project: string,
  showClosed: boolean,
  sort: Array<string>) => {
  return axios
    .get(
      "/company-manager-app/d5c627c6c6b048858759ba268400e1b2/accountedtimetrack-export",
      {
        responseType: "blob",
        params: { dateFrom, dateTo, employeeShortName, project, showClosed, sort: sort.join(",") }
      })
    .then((response) => {
      if (dateTo === "") {
        const now = moment().format("YYYY-MM-DD");
        FileDownload(response.data, `${dateFrom}_${now}TimeTrackingReport.xlsx`);
      } else {
        FileDownload(response.data, `${dateFrom}_${dateTo}TimeTrackingReport.xlsx`);
      }
    });
};

export const getBillsFromAccountedTimeTracks = (requestBillsDto: RequestBillsDto, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/bills`, requestBillsDto, { cancelToken: source.token })
    .then(response => response.data);
};
