import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { CompanyAddressDto, CompanyAddressTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/companyaddress";

export const addCompanyAddress = (companyAddressDto: Partial<CompanyAddressDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, companyAddressDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllCompanyAddressForCompany = (
  companyId: number,
  source: CancelTokenSource): Promise<Array<CompanyAddressTableDto>> => {
  return axios
    .get(`${baseUrl}/all/${companyId}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getCompanyAddress = (id: number, source: CancelTokenSource): Promise<CompanyAddressDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editCompanyAddress = (
  companyAddressDto: Partial<CompanyAddressDto>,
  id: number,
  source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, companyAddressDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteCompanyAddress = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
