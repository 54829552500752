import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import {
  TimeTrackingDto,
  TimeTrackingTableDto,
  WorkingTableByDayDto,
  WorkingTableDto
} from "../../../ts-types/api.types";
import { ReworkingReportDto, TimeTrackingTableDurationDto } from "../types";

const baseUrl = "/company-manager-app/api/time-tracking";

export const addTimeTracking = (timeTrackingDto: Partial<TimeTrackingDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}`, timeTrackingDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllTimeTrackingsByEmployee = (
  startDate: string,
  endDate: string,
  source: CancelTokenSource): Promise<Array<TimeTrackingTableDto>> => {
  return axios
    .get(`${baseUrl}/all-by-employee`,
      { cancelToken: source.token, params: { startDate, endDate } })
    .then(response => response.data);
};

export const editTimeTracking = (timeTrackingDto: Partial<TimeTrackingDto>, id: number | undefined, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, timeTrackingDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteTimeTracking = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getTimeTrackingReport = (
  reworkingReportDto: ReworkingReportDto,
  source: CancelTokenSource): Promise<TimeTrackingTableDurationDto> => {
  return axios
    .post(`${baseUrl}/report`, reworkingReportDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getTimeTrackingReportExportUrl = (
  dateFrom: string,
  dateTo: string,
  employeeShortName: string,
  project: string,
  showClosed: boolean,
  sort: Array<string>) => {
  return axios
    .get(
      "/company-manager-app/d5c627c6c6b048858759ba268400e1b2/timetrack-export",
      {
        responseType: "blob",
        params: { dateFrom, dateTo, employeeShortName, project, showClosed, sort: sort.join(",") }
      })
    .then((response) => {
      if (dateTo === "") {
        const now = moment().format("YYYY-MM-DD");
        FileDownload(response.data, `${dateFrom}_${now}TimeTrackingReport.xlsx`);
      } else {
        FileDownload(response.data, `${dateFrom}_${dateTo}TimeTrackingReport.xlsx`);
      }
    });
};

export const getWorkingTable = (date: string, source: CancelTokenSource): Promise<WorkingTableDto> => {
  return axios
    .get(`${baseUrl}/working-table`,
      { cancelToken: source.token, params: { date } })
    .then(response => response.data);
};

export const getWorkingReportByDay = (
  reworkingReportDto: ReworkingReportDto,
  source: CancelTokenSource): Promise<WorkingTableByDayDto> => {
  return axios
    .post(`${baseUrl}/working-table-by-day`, reworkingReportDto, { cancelToken: source.token })
    .then(response => response.data);
};
