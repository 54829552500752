import React from "react";
import { Route as ReactRoute, RouteProps } from "react-router";
import * as Sentry from "@sentry/react";

export const Route: React.VFC<RouteProps> = (props) => {
  if (process.env.REACT_APP_USE_SENTRY && Number(process.env.REACT_APP_USE_SENTRY) === 1) {
    const SentryRoute = Sentry.withSentryRouting(ReactRoute);

    return <SentryRoute {...props}/>;
  }

  return <ReactRoute {...props} />;
};
