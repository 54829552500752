import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { Box } from "@mui/material";
import AddEditPerson from "../../pages/Persons/components/AddEditPerson";
import AddEditEmployee from "../../pages/Employees/components/AddEditEmployee";
import AddEditWorkTime from "../../pages/WorkTimes/components/AddEditWorkTime";
import AddEditCompany from "../../pages/Companies/components/AddEditCompany";
import AddEditCompanyPerson from "../../pages/CompanyPerson/components/AddEditCompanyPerson";
import AddEditProject from "../../pages/Projects/components/AddEditProject";
import AddEditProjectTask from "../../pages/ProjectTasks/components/AddEditProjectTask";
import TimeTrackingPage from "../../pages/TimeTrackings/TimeTrackingPage";
import AddEditPublicVacation from "../../pages/PublicVacations/components/AddEditPublicVacation";
import AddEmployeeVacation from "../../pages/VacationReports/components/AddEmployeeVacation";
import EditEmployeeVacation from "../../pages/VacationReports/components/EditEmployeeVacation";
import AddEditProjectPerson from "../../pages/ProjectPersons/components/AddEditProjectPerson";
import Route from "../Route";
import AddOvertimePage from "../../pages/Overtimes/components/AddOvertimePage";
import AddEditCompanyAddress from "../../pages/CompanyAddress/components/AddEditCompanyAddress";
import AddEditEmployeePerson from "../../pages/EmployeePerson/components/AddEditEmployeePerson";
import AddEditCompanyBank from "../../pages/CompanyBank/components/AddEditCompanyBank";
import ExportInvoice from "../../pages/Invoice/ExportInvoice";
import PersonPage from "../../pages/Persons/PersonPage";
import EmployeePage from "../../pages/Employees/EmployeePage";
import CompanyPage from "../../pages/Companies/CompanyPage";
import HolidaysPage from "../../pages/Holidays/HolidaysPage";
import HolidayList from "../../pages/Holidays/components/HolidayList";
import { useAuthContext } from "../../context/AuthContext";
import UsersPage from "../../pages/Users/UsersPage";
import ProjectPage from "../../pages/Projects/ProjectPage";
import TimeTrackingTable from "../../pages/TimeTrackings/components/TimeTrackingTable";
import VacationReportsPage from "../../pages/VacationReports/VacationReportsPage";
import PublicVacationPage from "../../pages/PublicVacations/PublicVacationPage";
import AccountedWorkingReportsPage from "../../pages/AccountedWorkingReports/AccountedWorkingReportsPage";
import AdminRoute from "../../pages/AdminRoute/AdminRoute";
import { Role } from "../interfaces/enums";

const Home = () => {
  const { user } = useAuthContext();
  return (
    <main style={{ height: "100vh" }}>
      <Box height={100}>
      </Box>
      {user &&
      <Box>
        <Switch>
            <Box>
          <Route
            path="/holiday"
            exact
            render={() => user
              ? user?.roles?.includes(Role.ROLE_ADMIN)
                ? <HolidaysPage search={""}/>
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                : <HolidayList onChange={() => {}}/>
              : <></>
          }
          />
          <Route
            path="/user"
            exact
            render={() => <AdminRoute children={<UsersPage/>}/> }
          />
          <Route
            path="/person"
            exact
            render={() => <PersonPage search={""}/>}
          />
          <Route
            path="/upsert-person"
            exact
            render={() => <AddEditPerson/>}
          />
          <Route
            path="/employee"
            exact
            render={() => <EmployeePage search={""}/>}
          />
          <Route
            path="/upsert-employee"
            exact
            render={() => <AddEditEmployee/>}
          />
          <Route
            path="/upsert-worktime"
            exact
            render={() => <AddEditWorkTime/>}
          />
          <Route
            path="/company"
            exact
            render={() => <CompanyPage search={""}/>}
          />
          <Route
            path="/upsert-company"
            exact
            render={() => <AddEditCompany/>}
          />
          <Route
            path="/companyperson"
            exact
            render={() => <AddEditCompanyPerson/>}
          />
          <Route
            path="/project"
            exact
            render={() => <ProjectPage search={""}/>}
          />
          <Route
            path="/upsert-project"
            exact
            render={() => <AddEditProject/>}
          />
          <Route
            path="/working-report"
            exact
            render={() => <TimeTrackingTable search={""}/>}
          />
          <Route
            path="/vacation-report"
            exact
            render={() => <VacationReportsPage search={""}/>}
          />
          <Route
            path="/accounted-working-report"
            exact
            render={() => <AdminRoute children={<AccountedWorkingReportsPage search={""}/>}/> }
          />
          <Route
            path="/projectperson"
            exact
            render={() => <AddEditProjectPerson/>}
          />
          <Route
            path="/projecttask"
            exact
            render={() => <AddEditProjectTask/>}
          />
          {user?.employeeId != null &&
            <Route
            path="/timetracking"
            exact
            render={() => <TimeTrackingPage/>}
          />
          }
          <Route
            path="/edit-employee-vacation"
            exact
            render={() => <EditEmployeeVacation/>}
          />
          <Route
            path="/add-employee-vacation"
            exact
            render={() => <AddEmployeeVacation/>}
          />
          <Route
            path="/public-vacation"
            exact
            render={() => <PublicVacationPage search={""}/>}
          />
          <Route
            path="/upsert-public-vacation"
            exact
            render={() => <AddEditPublicVacation/>}
          />
          <Route
            path="/overtime"
            exact
            render={() => <AddOvertimePage/>}
          />
          <Route
            path="/employeeperson"
            exact
            render={() => <AddEditEmployeePerson/>}
          />
          <Route
            path="/companyaddress"
            exact
            render={() => <AddEditCompanyAddress/>}
          />
          <Route
            path="/companybank"
            exact
            render={() => <AddEditCompanyBank/>}
          />
          <Route
            path="/invoice"
            exact
            render={() => <ExportInvoice/>}
          />
            </Box>
          <Redirect to="/login"/>
        </Switch>
      </Box>
      }
    </main>
  );
};
export default Home;
