import React from "react";
import DoneIcon from "@mui/icons-material/Done";

const DoneIconComponent = (): JSX.Element => {
  return (
        <div>
            <DoneIcon sx={{ fontSize: "16px" }}/>
        </div>
  );
};
export default DoneIconComponent;
