export enum HolidayStatus {
    ALL = "ALL",
    WAITING_ON_APPROVAL = "WAITING_ON_APPROVAL",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
    PAST_HOLIDAY = "PAST_HOLIDAY"
}
export enum StateFrom {
    ADD = "ADD",
    EDIT = "EDIT",
    DETAILS = "DETAILS"
}
export enum ProjectTaskType {
    WORKTRACKING = "WORKTRACKING",
    VACATIONTRACKING = "VACATIONTRACKING"
}
export enum EmployeeVacationType {
    FORWARD = "FORWARD",
    CREDIT = "CREDIT",
    USED = "USED"
}
export enum AddEmployeeVacationType {
    Forward = "Forward",
    Credit = "Credit",
    ForwardCredit = "Forward & Credit"
}
export enum Role {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_EMPLOYEE = "ROLE_EMPLOYEE"
}
export enum WorkingReportsView {
    VIEW_BY_RECORD = "VIEW_BY_RECORD",
    VIEW_BY_DAY = "VIEW_BY_DAY"
}
export enum Trimester {
    T1 = "T1",
    T2 = "T2",
    T3 = "T3",
    T4 = "T4"
}
export enum AddOvertimeType {
    CREDIT = "CREDIT",
    COMPENSATE = "COMPENSATE"
}
export enum CompensateType {
    VACATION = "VACATION",
    PAYMENT = "PAYMENT"
}
export enum RelationType {
    RESPONSIBLE_FOR = "RESPONSIBLE_FOR",
    KNOWS = "KNOWS",
    CONTACT_PERSON="CONTACT_PERSON"
}
