import { useAuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { PublicVacationDto } from "./types";
import {
  deletePublicVacation, getPublicVacationForYear, getPublicVacationsForThisYear,
  nextYearHasPublicVacations,
  transferVacationsToNextYear
} from "./services/PublicVacationServices";
import axios from "axios";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { AdminProps } from "../../components/interfaces/interface";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

const PublicVacationPage: FC<AdminProps> = (props): JSX.Element => {
  const { user } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation("company-manager");
  const [publicVacations, setPublicVacations] = React.useState<Array<PublicVacationDto>>([]);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [nextYearHasVacations, setNextYearHasVacations] = React.useState<boolean>(true);
  const [selectedYear, setSelectedYear] = React.useState<string>(moment().format("YYYY-MM-DD"));

  React.useEffect(() => {
    loadPublicVacations();
  }, []);

  const loadPublicVacations = () => {
    getPublicVacationsForThisYear(cancelTokenSource)
      .then(r => setPublicVacations(r));
    nextYearHasPublicVacations(cancelTokenSource)
      .then(r => setNextYearHasVacations(r));
  };

  const handleDelete = (id: number) => {
    deletePublicVacation(id, cancelTokenSource)
      .then(loadPublicVacations);
  };

  const handleDateChange = (date: Date) => {
    setSelectedYear(moment(date).clone().format("YYYY"));
    getPublicVacationForYear(parseInt(moment(date).clone().format("YYYY")), cancelTokenSource)
      .then(r => setPublicVacations(r));
  };

  const renderPublicVacation = () => {
    const actions = ({ rowData }: any) => {
      const publicVacationId = rowData.id;
      return (
          <Box width={100}>
            <Button
                sx={{ m: 1 }}
                variant={"outlined"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/upsert-public-vacation", {
                    publicVacationId,
                    view: props.view,
                    search: props.search,
                    from: StateFrom.EDIT
                  })}>
              {t("common.edit")}
            </Button>
            <YNPopper
                message={t("common.delete")}
                color={"error"}
                variant={"contained"}
                icon={<DeleteIcon/>}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onConfirm={() => handleDelete(publicVacationId)}/>
          </Box>
      );
    };
    const publicVacationsRowGetter = ({ index }: any) => {
      Object.assign(publicVacations[index], { index: index + 1 });

      return publicVacations[index];
    };

    const publicVacationsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };
    return (
        <VirtualizedTable
            rowCount={publicVacations.length}
            rowGetter={publicVacationsRowGetter}
            rowRenderer={publicVacationsRowRenderer}
            columns={[
              {
                width: 75,
                label: "#",
                dataKey: "index"
              },
              {
                width: 200,
                label: t("common.date"),
                dataKey: "date"
              },
              {
                width: 500,
                flexGrow: 1,
                label: t("common.description"),
                dataKey: "description"
              },
              {
                width: 225,
                label: t("common.actions"),
                dataKey: "id",
                cellRenderer: (actions)
              }
            ]}
        />
    );
  };

  const rowHeight = 48;
  const tableHeight = (publicVacations.length * rowHeight) + 25;

  return (
      <>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
          <Stack direction={"row"} sx={{ mx: 3 }}>
            <h3><i>{t("common.publicVacations")}</i></h3>
            <Box marginX={4} width={130} marginY={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  className="datePicker"
                  label={t("common.year")}
                  inputFormat="YYYY"
                  value={selectedYear}
                  openTo="year"
                  views={["year"]}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params}/>}
                />
              </LocalizationProvider>
            </Box>
          </Stack>
          <Stack alignSelf={"center"} direction={"row"}>
            <Button
                sx = {{ m: 2 }}
                variant={"contained"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE) || nextYearHasVacations}
                onClick={() => {
                  transferVacationsToNextYear(cancelTokenSource)
                    .then(loadPublicVacations);
                }}>
              {t("button.transferVacations")}
            </Button>
            <Button
                sx = {{ m: 2 }}
                variant={"contained"}
                disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                onClick={() =>
                  history.push("/upsert-public-vacation", {
                    search: props.search,
                    view: props.view,
                    from: StateFrom.ADD
                  })}>
              {t("button.addPublicVacation")}
            </Button>
          </Stack>
        </Stack>
        <Box style={{ height: tableHeight }}>
          {renderPublicVacation()}
        </Box>
      </>
  );
};
export default PublicVacationPage;
