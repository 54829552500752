import React, { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { Alert, Button, Collapse, IconButton, Stack, TextField } from "@mui/material";
import { LoginDto } from "../../../components/interfaces/interface";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosError } from "axios";

const initialLoginDtoState: LoginDto = {
  username: "",
  password: ""
};

const LoginForm = () => {
  const { login } = useAuthContext();
  const { t } = useTranslation("login");
  const [formData, updateFormData] = React.useState<LoginDto>(initialLoginDtoState);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const history = useHistory();

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    updateFormData({
      ...formData,
      [name]: value
    });
  }, [updateFormData, formData]);

  const updateFormAndLogin = React.useCallback((e) => {
    e.preventDefault();
    login(formData)
      .catch((error: AxiosError) => {
        handleError(error);
      });
  }, [updateFormData, formData, login]);

  const handleError = (error: AxiosError<unknown>) => {
    if (error.response?.status === 401) {
      setErrorMessage(t("error.INCORRECT_CREDENTIALS"));
    } else {
      setErrorMessage(t("error.general"));
    }
  };

  return (
        <>
            <form onSubmit={updateFormAndLogin}>
                <Stack direction={"column"}>
                    <Stack direction={"row"} sx={{ my: 1, alignSelf: "center" }}>
                        <TextField
                            size={"small"}
                            type="email"
                            className="form-control"
                            id="username"
                            name="username"
                            label={t("legend.enterEmail")}
                            required
                            onChange={handleChange}
                        />
                    </Stack>
                    <Stack direction={"row"} sx={{ my: 1, alignSelf: "center" }}>
                        <TextField
                            size={"small"}
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            label={t("legend.enterPassword")}
                            required
                            onChange={handleChange}
                        />
                    </Stack>
                </Stack>
                <hr/>
                <Button type={"submit"} sx={{ m: 1 }} variant={"contained"}>
                  {t("button.login")}
                </Button>
              <Button
                  onClick={() => history.push("/forgot-password", { username: formData.username })}
                  type={"button"}
                  sx={{ m: 1 }}
                  variant={"contained"}
                  color={"warning"}>
                {t("button.forgotPassword")}
              </Button>
              <Collapse in={!!errorMessage}>
                <Alert
                  severity={"error"}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorMessage(undefined);
                      }}
                    >
                      <CloseIcon fontSize="inherit"/>
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {errorMessage}
                </Alert>
              </Collapse>
            </form>

        </>
  );
};
export default LoginForm;
