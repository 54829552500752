import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Alert, Box, Button, Collapse, Dialog, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { save } from "../services/HolidayServices";
import { HolidayDto } from "../../../ts-types/api.types";
import { useTranslation } from "react-i18next";
import { errorUtils } from "../../../utils/errorUtils";

export interface Props {
  onSave: (holidayDto: HolidayDto) => void,
  show: boolean,
  close: () => void
}
const AddHoliday = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation("company-manager");
  const cancelTokenSource = useMemo(() => axios.CancelToken.source(), []);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formData, updateFormData] = React.useState<HolidayDto>({
    id: 0,
    reasonForDeclining: "",
    status: "WAITING_ON_APPROVAL",
    startingDate: moment().format("YYYY-MM-DD"),
    endingDate: moment().format("YYYY-MM-DD"),
    days: 0,
    type: "",
    requestedOn: "",
    approvedOn: "",
    requestedByEmployeeId: 0,
    approvedByEmployeeId: 0,
    shortNameRequestedBy: "",
    shortNameApprovedBy: ""
  });
  const handleChangeStartingDate = (newValue: null) => {
    updateFormData({ ...formData, startingDate: moment(newValue).format("YYYY-MM-DD") });
  };

  const onFormSubmit = React.useCallback(async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    await setIsSubmitting(true);
    await save(formData, cancelTokenSource)
      .then(r => { props.onSave(r); }).catch((e) => handleError(e.response.data)).finally(() => setIsSubmitting(false));
  }, [updateFormData, formData]);

  const handleError = (error: any) => {
    if (error) {
      let errorMessage;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.NO_DAYS_LEFT, errorUtils.ENDING_DATE_IS_BEFORE_STARTING_DATE,
        errorUtils.HOLIDAYS_ARE_OVERLAPPED, errorUtils.SOME_DAYS_LEFT, errorUtils.ALREADY_DECLINED_HOLIDAY];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errorMessage = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errorMessage) {
        if (knownErrors.includes(errorCode)) {
          errorMessage = t(`error.${errorCode}`);
        } else {
          errorMessage = t("error.general");
        }
      }

      setErrorMessage(errorMessage);
    }
  };
  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [updateFormData, formData]);

  return (
        <>
            <Dialog
                disablePortal={false}
                maxWidth="xl"
                open={props.show}
            >
                <DialogTitle>
                    Add holiday
                    <IconButton
                        aria-label="close"
                        onClick={props.close}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <Collapse in={!!errorMessage}>
                    <Alert
                        severity={"error"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setErrorMessage(undefined);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>
                <Box sx={{ p: 2, minWidth: 220 }}>
                    <form onSubmit={onFormSubmit}>
                        <Stack direction="column">
                            <Stack direction="row">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack direction={"column"} sx={{ mx: 1 }}>
                                    <DesktopDatePicker
                                        label="Starting date"
                                        inputFormat="DD/MM/YYYY"
                                        value={formData.startingDate}
                                        onChange={handleChangeStartingDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                                <Stack direction={"column"} sx={{ mx: 1 }}>
                                    <TextField inputProps={{ step: "0.5" }}
                                        sx={{ m: 1 }}
                                        size={"small"}
                                        type="number"
                                        variant="outlined"
                                        id="days"
                                        name="days"
                                        label="Amount of days"
                                        required
                                        onChange={handleChange}
                                    />
                                </Stack>
                                </LocalizationProvider>
                            </Stack>
                            <Stack>
                                <TextField
                                    sx={{ m: 1 }}
                                    size={"small"}
                                    type="text"
                                    variant="outlined"
                                    id="type"
                                    name="type"
                                    label="Enter type of holiday that you require"
                                    required
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction={"row"} justifyContent={"center"}>
                                <Button sx={{ m: 1 }} type="submit" variant={"outlined"} disabled={isSubmitting}>
                                    Submit
                                </Button>
                                <Button sx={{ m: 1 }} type={"button"} variant={"outlined"}
                                        onClick={props.close}>Back</Button>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Dialog>
        </>
  );
};
export default AddHoliday;
