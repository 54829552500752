import React from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddOvertimeType, CompensateType, StateFrom, Trimester } from "../../../components/interfaces/enums";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import {
  getOvertimesToCompensate,
  getOvertimesToCredit,
  saveCompensateOvertimes,
  saveCreditOvertimes
} from "../services/OvertimeServices";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { CompensateOvertimeDto, CreditOvertimeDto } from "../types";

const AddOvertimePage = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation("company-manager");
  const [message, setMessage] = React.useState<string>(t("header.creditOvertimes"));
  const history = useHistory();
  const { state } = useLocation<any>();
  const [selectedOvertimeType, setSelectedOvertimeType] = React.useState<AddOvertimeType>(AddOvertimeType.CREDIT);
  const [selectedTrimester, setSelectedTrimester] = React.useState<Trimester>(Trimester.T1);
  const [selectedYear, setSelectedYear] = React.useState<string>(moment().format("YYYY-MM-DD"));
  const [compensateRecords, setCompensateRecords] = React.useState<Array<CompensateOvertimeDto>>([]);
  const [creditRecords, setCreditRecords] = React.useState<Array<CreditOvertimeDto>>([]);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);

  const handleDateChange = async (date: any) => {
    setSelectedYear(moment(date).clone().format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    setInitialTrimester();
  }, []);

  React.useEffect(() => {
    switch (selectedOvertimeType) {
      case AddOvertimeType.COMPENSATE:
        loadCompensateRecords();
        break;
      case AddOvertimeType.CREDIT:
        loadCreditRecords();
        break;
    }
  }, [selectedTrimester, selectedYear, selectedOvertimeType]);

  const setInitialTrimester = () => {
    const today = moment.now();
    if (moment(today).month() >= 0 && moment(today).month() <= 2) {
      setSelectedTrimester(Trimester.T1);
    } else if (moment(today).month() >= 3 && moment(today).month() <= 5) {
      setSelectedTrimester(Trimester.T2);
    } else if (moment(today).month() >= 6 && moment(today).month() <= 8) {
      setSelectedTrimester(Trimester.T3);
    } else if (moment(today).month() >= 9 && moment(today).month() <= 11) {
      setSelectedTrimester(Trimester.T4);
    }
  };

  const loadCompensateRecords = async () => {
    setDataLoaded(false);
    try {
      const response = await getOvertimesToCompensate(
        cancelTokenSource,
        selectedTrimester,
        parseInt(moment(selectedYear).clone().format("YYYY"))
      );
      setCompensateRecords(response);
    } finally {
      setDataLoaded(true);
    }
  };

  const loadCreditRecords = async () => {
    setDataLoaded(false);
    try {
      const response = await getOvertimesToCredit(
        cancelTokenSource,
        selectedTrimester,
        parseInt(moment(selectedYear).clone().format("YYYY"))
      );
      setCreditRecords(response);
    } finally {
      setDataLoaded(true);
    }
  };

  const handleSave = () => {
    switch (selectedOvertimeType) {
      case AddOvertimeType.COMPENSATE:
        saveCompensateOvertimes(cancelTokenSource, compensateRecords)
          .then(() => history.push(
            "/vacation-report",
            { employeeVacationSelectedEmployee: state.employeeId }
          ));
        break;
      case AddOvertimeType.CREDIT:
        saveCreditOvertimes(cancelTokenSource, creditRecords)
          .then(() => history.push(
            "/vacation-report",
            { employeeVacationSelectedEmployee: state.employeeId }
          ));
        break;
    }
  };

  const handleAddOvertimeTypeChange = (e: any) => {
    setSelectedOvertimeType(e.target.value);
    handleHeaderChange(e.target.value);
  };

  const handleTrimesterChange = (e: any) => {
    setSelectedTrimester(e.target.value);
  };

  const handleHeaderChange = (selected: AddOvertimeType) => {
    if (selected === AddOvertimeType.CREDIT) {
      setMessage(t("header.creditOvertimes"));
    } else {
      setMessage(t("header.compensateOvertimes"));
    }
  };

  const renderCompensateTable = (): JSX.Element => {
    const compensateRowGetter = ({ index }: any) => {
      Object.assign(compensateRecords[index], { index: index + 1 });

      return compensateRecords[index];
    };

    const compensateRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const selectBox = ({ rowIndex }: any) => {
      return (
          <Checkbox onClick={() => handleSelectChange(rowIndex)} checked={compensateRecords[rowIndex].selected}/>
      );
    };

    const handleSelectChange = (id: number) => {
      const newList = [...compensateRecords];
      const compensateDto = newList[id];
      if (newList && compensateDto !== undefined) {
        compensateDto.selected = !compensateDto.selected;
        newList[id] = compensateDto;
      }
      setCompensateRecords(newList);
    };

    const selectBoxLabel = () => {
      let howManyAreSelected = 0;
      let selectBoxState = 2;
      compensateRecords.forEach((compensateRecord) => {
        if (compensateRecord.selected) {
          howManyAreSelected++;
        }
      });
      if (howManyAreSelected === 0) {
        selectBoxState = 0;
      } else if (howManyAreSelected < compensateRecords.length) {
        selectBoxState = 1;
      } else {
        selectBoxState = 2;
      }
      return (
          <Checkbox
              onClick={() => selectDeselectAll(selectBoxState)}
              checked={selectBoxState === 1 || selectBoxState === 2}
              color={selectBoxState === 1 ? "default" : "primary"}
              sx={{ ml: -0.5 }}/>
      );
    };

    const selectDeselectAll = (state: number) => {
      const newList = [...compensateRecords];
      if (state === 0) {
        newList.forEach((value) => {
          value.selected = true;
        });
      } else {
        newList.forEach((value) => {
          value.selected = false;
        });
      }
      setCompensateRecords(newList);
    };

    const compensateHours = ({ rowIndex }: any) => {
      return (
          <TextField
              sx={{ width: 70 }}
              size={"small"}
              type={"text"}
              value={compensateRecords[rowIndex].hoursToCompensate}
              placeholder={t("placeHolder.hours")}
              onChange={(e) => handleCompensateDuration(e, rowIndex)}/>
      );
    };

    const handleCompensateDuration = (e: any, id: number) => {
      const newList = [...compensateRecords];
      const compensateDto = newList[id];
      if (newList && compensateDto !== undefined) {
        compensateDto.hoursToCompensate = e.target.value;
        newList[id] = compensateDto;
      }
      setCompensateRecords(newList);
    };

    const compensationType = ({ rowIndex }: any) => {
      return (
          <Select
              size={"small"}
              sx={{ width: 175 }}
              defaultValue={CompensateType.VACATION}
              value={compensateRecords[rowIndex].compensationType}
              onChange={(e) => handleCompensationChange(e, rowIndex)}>
                  <MenuItem key={1} value={CompensateType.VACATION}>{t("common.vacation")}</MenuItem>
                  <MenuItem key={2} value={CompensateType.PAYMENT}>{t("common.payment")}</MenuItem>
          </Select>
      );
    };

    const handleCompensationChange = (e: any, id: number) => {
      const newList = [...compensateRecords];
      const compensationDto = newList[id];
      if (newList && compensationDto !== undefined) {
        compensationDto.compensationType = e.target.value;
        newList[id] = compensationDto;
      }
      setCompensateRecords(newList);
    };

    return (
        <VirtualizedTable
            height={500}
            rowCount={compensateRecords.length}
            rowGetter={compensateRowGetter}
            rowRenderer={compensateRowRenderer}
            columns={[
              {
                label: (selectBoxLabel()),
                width: 50,
                dataKey: "id",
                cellRenderer: (selectBox)
              },
              {
                width: 125,
                flexGrow: 1,
                label: t("common.shortName"),
                dataKey: "shortName"
              },
              {
                width: 200,
                flexGrow: 1,
                label: t("tableHeader.availableHours"),
                dataKey: "availableHours"
              },
              {
                width: 300,
                label: t("tableHeader.hoursToCompensate"),
                dataKey: "hoursToCompensate",
                cellRenderer: (compensateHours)
              },
              {
                width: 300,
                label: t("tableHeader.compensationType"),
                dataKey: "compensationType",
                cellRenderer: (compensationType)
              }
            ]}
        />
    );
  };

  const renderCreditTable = (): JSX.Element => {
    const creditRowGetter = ({ index }: any) => {
      Object.assign(creditRecords[index], { index: index + 1 });

      return creditRecords[index];
    };

    const creditRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    const selectBox = ({ rowIndex }: any) => {
      return (
          <Checkbox onClick={() => handleSelectChange(rowIndex)} checked={creditRecords[rowIndex].selected}/>
      );
    };

    const handleSelectChange = (id: number) => {
      const newList = [...creditRecords];
      const creditDto = newList[id];
      if (newList && creditDto !== undefined) {
        creditDto.selected = !creditDto.selected;
        newList[id] = creditDto;
      }
      setCreditRecords(newList);
    };

    const selectBoxLabel = () => {
      let howManyAreSelected = 0;
      let selectBoxState = 2;
      creditRecords.forEach((creditRecord) => {
        if (creditRecord.selected) {
          howManyAreSelected++;
        }
      });
      if (howManyAreSelected === 0) {
        selectBoxState = 0;
      } else if (howManyAreSelected < creditRecords.length) {
        selectBoxState = 1;
      } else {
        selectBoxState = 2;
      }
      return (
          <Checkbox
              onClick={() => selectDeselectAll(selectBoxState)}
              checked={selectBoxState === 1 || selectBoxState === 2}
              color={selectBoxState === 1 ? "default" : "primary"}
              sx={{ ml: -0.5 }}/>
      );
    };

    const selectDeselectAll = (state: number) => {
      const newList = [...creditRecords];
      if (state === 0) {
        newList.forEach((value) => {
          value.selected = true;
        });
      } else {
        newList.forEach((value) => {
          value.selected = false;
        });
      }
      setCreditRecords(newList);
    };

    const creditHours = ({ rowIndex }: any) => {
      return (
          <TextField
              sx={{ width: 70 }}
              size={"small"}
              type={"text"}
              value={creditRecords[rowIndex].hoursToTransfer}
              placeholder={t("placeHolder.hours")}
              onChange={(e) => handleCreditDuration(e, rowIndex)}/>
      );
    };

    const handleCreditDuration = (e: any, id: number) => {
      const newList = [...creditRecords];
      const creditDto = newList[id];
      if (newList && creditDto !== undefined) {
        creditDto.hoursToTransfer = e.target.value;
        newList[id] = creditDto;
      }
      setCreditRecords(newList);
    };
    return (
        <VirtualizedTable
            height={500}
            rowCount={creditRecords.length}
            rowGetter={creditRowGetter}
            rowRenderer={creditRowRenderer}
            columns={[
              {
                label: (selectBoxLabel()),
                width: 50,
                dataKey: "id",
                cellRenderer: (selectBox)
              },
              {
                width: 125,
                flexGrow: 1,
                label: t("common.shortName"),
                dataKey: "shortName"
              },
              {
                width: 200,
                flexGrow: 1,
                label: t("tableHeader.nonTransferredHours"),
                dataKey: "nonTransferredHours"
              },
              {
                width: 300,
                label: t("tableHeader.hoursToTransfer"),
                dataKey: "hoursToTransfer",
                cellRenderer: (creditHours)
              }
            ]}
        />
    );
  };

  const tableView = () => {
    if (dataLoaded) {
      switch (selectedOvertimeType) {
        case AddOvertimeType.CREDIT:
          return renderCreditTable();
        case AddOvertimeType.COMPENSATE:
          return renderCompensateTable();
      }
    } else {
      return (<CircularProgress/>);
    }
  };

  return (
      <Box>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"}>
        <h2>{message}</h2>
            <FormControl>
              <InputLabel sx={{ width: 250, m: 2 }}>{t("common.trimester")}</InputLabel>
              <Select
                  sx={{ width: 250, m: 2 }}
                  type="text"
                  label={t("common.trimester")}
                  value={selectedTrimester}
                  onChange={handleTrimesterChange}>
                <MenuItem key={1} value={Trimester.T1}>
                  {t("option.jan-mar")}
                </MenuItem>
                <MenuItem key={2} value={Trimester.T2}>
                  {t("option.apr-jun")}
                </MenuItem>
                <MenuItem key={3} value={Trimester.T3}>
                  {t("option.jul-sep")}
                </MenuItem>
                <MenuItem key={4} value={Trimester.T4}>
                  {t("option.oct-dec")}
                </MenuItem>
              </Select>
            </FormControl>
            <Box width={130}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("common.year")}
                  inputFormat="YYYY"
                  value={selectedYear}
                  openTo="year"
                  views={["year"]}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params}/>}
              />
            </LocalizationProvider>
            </Box>
          </Stack>
          <FormControl>
            <InputLabel sx={{ width: 250, m: 2 }}>{t("common.overtimeType")}</InputLabel>
            <Select
                sx={{ width: 250, m: 2 }}
                type="text"
                label={t("common.overtimeType")}
                value={selectedOvertimeType}
                onChange={handleAddOvertimeTypeChange}>
              <MenuItem key={1} value={AddOvertimeType.CREDIT}>
                {t("option.credit")}
              </MenuItem>
              <MenuItem key={2} value={AddOvertimeType.COMPENSATE}>
                {t("option.compensate")}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Box height={500}>
        {tableView()}
        </Box>
        <Stack direction={"row"} justifyContent={"right"}>
          <Button
              sx={{ m: 1 }}
              variant={"outlined"}
              type={"submit"}
              disabled={state?.from === StateFrom.DETAILS}
              onClick={handleSave}
          >
            {t("common.save")}
          </Button>
          <Button sx={{ m: 1 }}
                  variant={"outlined"}
                  onClick={() => history.push(
                    "/vacation-report",
                    { employeeVacationSelectedEmployee: state.employeeId }
                  )}>
            {t("common.back")}
          </Button>
        </Stack>
      </Box>
  );
};
export default AddOvertimePage;
