import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { StateFrom } from "../../../components/interfaces/enums";
import { PersonDto } from "../../Persons/types";
import { getAllPersons, getPerson } from "../../Persons/services/PersonServices";
import { useTranslation } from "react-i18next";
import { addProjectPerson, editProjectPerson, getProjectPerson } from "../services/ProjectPersonServices";
import { FunctionInProject } from "../../../ts-types/api.enums";
import { ProjectPersonDto } from "../../../ts-types/api.types";

const InitialProjectPersonDto: ProjectPersonDto = {
  stamp: "",
  id: 0,
  personId: 0,
  projectId: 0,
  functionInProject: FunctionInProject.DEVELOPER,
  dailyRate: 0,
  comments: "",
  active: false,
  deleted: false
};

const AddEditProjectPerson = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const history = useHistory();
  const [projectPerson, setProjectPerson] = React.useState<ProjectPersonDto>(InitialProjectPersonDto);
  const [persons, setPersons] = React.useState<Array<PersonDto>>([]);
  const { state } = useLocation<any>();
  const { t } = useTranslation("company-manager");
  const [selectedOption, setSelectedOption] = React.useState<PersonDto>({
    address: "",
    city: "",
    email: "",
    shortName: "",
    firstName: "",
    id: 0,
    isoCode: "",
    lastName: "",
    mobile: "",
    otherMobile: "",
    zip: "",
    lastLogin: "",
    active: true,
    imageData: undefined
  });

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editProjectPerson"));
        loadProjectPerson();
        loadPersons();
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.projectPersonDetails"));
        loadProjectPerson();
        loadPersons();
        break;
      case StateFrom.ADD:
        loadPersons();
        setMessage(t("common.addProjectPerson"));
        setProjectPerson({
          ...projectPerson,
          projectId: state.projectId
        });
        break;
    }
  }, []);

  const loadProjectPerson = () => {
    getProjectPerson(state.id, cancelTokenSource)
      .then(projectPerson => {
        setProjectPerson(projectPerson);
        if (state.from === StateFrom.EDIT || state.from === StateFrom.DETAILS) {
          getPerson(projectPerson.personId, cancelTokenSource)
            .then(person => setSelectedOption(person));
        }
      });
  };

  const loadPersons = () => {
    getAllPersons(state.search, cancelTokenSource)
      .then(r => {
        setPersons(r);
      });
  };
  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addProjectPerson(projectPerson, cancelTokenSource)
          .then(() => history.push("/upsert-project",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, projectId: state.projectId }));
        break;
      case StateFrom.EDIT:
        editProjectPerson(projectPerson, state.id, cancelTokenSource)
          .then(() => history.push("/upsert-project",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, projectId: state.projectId }));
        break;
    }
  };

  const handlePersonChangeSelection = async (newValue: any) => {
    setSelectedOption(newValue);
    setProjectPerson({ ...projectPerson, personId: newValue.id });
  };

  const handleChange = (e: any) => {
    setProjectPerson({
      ...projectPerson,
      [e.target.name]: e.target.value
    });
  };

  const handleActiveInactive = () => {
    if (!projectPerson.active) {
      setProjectPerson({ ...projectPerson, active: true });
    } else {
      setProjectPerson({ ...projectPerson, active: false });
    }
  };

  return (
        <Box>
            <h2>{message}</h2>
            <FormControl>
                <Stack direction={ "row"}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={persons}
                        getOptionLabel={(option: PersonDto) => option.email}
                        value={selectedOption}
                        onChange={(event: any, newValue?: PersonDto) => {
                          handlePersonChangeSelection(newValue);
                        }}
                        sx={{ width: 250, m: 2 }}
                        renderInput={(params) =>
                            <TextField {...params} label={t("common.persons")}/>}
                    />
                    <FormControl>
                        <InputLabel sx={{ width: 250, m: 2 }}>{t("common.functionInProject")}</InputLabel>
                        <Select
                            sx={{ width: 250, m: 2 }}
                            name={"functionInProject"}
                            type="text"
                            label={t("common.functionInProject")}
                            value={projectPerson.functionInProject}
                            onChange={handleChange}>
                            <MenuItem key={1} value={FunctionInProject.PROJECT_LEAD}>
                                {t("option.projectLead")}
                            </MenuItem>
                            <MenuItem key={2} value={FunctionInProject.LEAD_DEVELOPER}>
                                {t("option.leadDeveloper")}
                            </MenuItem>
                            <MenuItem key={3} value={FunctionInProject.TEAM_MEMBER}>
                                {t("option.teamMember")}
                            </MenuItem>
                            <MenuItem key={4} value={FunctionInProject.DEVELOPER}>
                                {t("option.developer")}
                            </MenuItem>
                            <MenuItem key={5} value={FunctionInProject.OTHER}>
                                {t("option.other")}
                            </MenuItem>
                        </Select>
                    </FormControl>

                     <div>
                        <FormControlLabel control={<Checkbox
                            checked={projectPerson.active}
                            onChange={handleActiveInactive}
                            inputProps={{ "aria-label": "controlled" }}/>}
                                          label={t("button.active")} />
                     </div>
                </Stack>
                <Stack direction={"row"} justifyContent={"right"}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        type={"submit"}
                        disabled={state?.from === StateFrom.DETAILS}
                        onClick={onFormSubmit}>
                        {t("common.save")}
                    </Button>
                    <Button sx={{ m: 1 }}
                            variant={"outlined"}
                            onClick={() => history.push("/upsert-project",
                              { search: state.search, view: state.view, projectId: state.projectId, from: StateFrom.DETAILS })}>
                        {t("common.back")}
                    </Button>
                </Stack>
            </FormControl>
        </Box>
  );
};
export default AddEditProjectPerson;
