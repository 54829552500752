import axios from "../../../custom-axios/axios";
import { UpsertWorkTimeDto, WorkTimeDto } from "../types";
import { CancelTokenSource } from "axios";

const baseUrl = "/company-manager-app/api/worktime";

export const addWorktime = (workTimeDto: Partial<UpsertWorkTimeDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, workTimeDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllWorkTimes = (employeeId: number, source: CancelTokenSource): Promise<Array<WorkTimeDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { id: employeeId } })
    .then(response => response.data);
};

export const getWorkTime = (id: number, source: CancelTokenSource): Promise<WorkTimeDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editWorkTime = (workTimeDto: Partial<UpsertWorkTimeDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, workTimeDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteWorkTime = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
