import React from "react";
import Main from "./Main";
import { AuthProvider } from "../../context/AuthContext";
import { Box } from "@mui/material";

const App: React.FC = () => {
  return (
    <AuthProvider>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="stretch"
            minHeight="100vh"
            fontSize={20}
        >
            <Main/>
        </Box>
    </AuthProvider>
  );
};

export default App;
