import React, { FC } from "react";
import { Box, Button, CircularProgress, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AdminProps } from "../../components/interfaces/interface";
import { useAuthContext } from "../../context/AuthContext";
import { getAllEmployeePersonsTable } from "./services/EmployeeServices";
import { StateFrom, Role } from "../../components/interfaces/enums";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import DoneIconComponent from "../../components/StyleComponents/DoneIconComponent";
import { emptyTableCell } from "../../utils/tableUtils";
import { EmployeeTableDto } from "../../ts-types/api.types";
import classNames from "classnames";

const EmployeePage: FC<AdminProps> = (props): JSX.Element => {
  const [employees, setEmployees] = React.useState<Array<EmployeeTableDto>>([]);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadEmployees(false);
  }, [props.search, props.role]);

  const loadEmployees = React.useCallback(async (includeInactive: boolean) => {
    setDataLoaded(false);

    try {
      await getAllEmployeePersonsTable(includeInactive, cancelTokenSource)
        .then(r => {
          setEmployees(r);
        });
    } finally {
      setDataLoaded(true);
    }
  }, [props.search, employees, cancelTokenSource]);

  const handleIncludeInactive = (event: any, checked: boolean) => {
    setIncludeInactive(checked);
    loadEmployees(checked);
  };
  const renderEmployeeTable = (): JSX.Element => {
    const statusCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <DoneIconComponent/>;
      }
      return emptyTableCell();
    };
    const actions = ({ rowData }: any) => {
      const employeeId = rowData.id;
      return (
          <Box width={100}>
              <Button
                  sx={{ my: 1, mr: 1 }}
                  variant={"outlined"}
                  onClick={() =>
                    history.push("/upsert-employee",
                      { id: employeeId, view: props.view, search: props.search, from: StateFrom.DETAILS })}>
                {t("common.details")}
              </Button>
        <Button
            sx={{ m: 1 }}
            variant={"outlined"}
            disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
            onClick={() =>
              history.push("/upsert-employee",
                { id: employeeId, view: props.view, search: props.search, from: StateFrom.EDIT })}>
          {t("common.edit")}
        </Button>
          </Box>
      );
    };

    const HeaderWithTooltip = ({ label } : any) => {
      return (
          <Tooltip title={t(label)} placement="top" componentsProps={{
            tooltip: {
              sx: {
                color: "black",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "0"
              }
            }
          }}>
              <span>#{t(label + "Tooltip")}</span>
          </Tooltip>
      );
    };
    const employeesRowGetter = ({ index }: any) => {
      Object.assign(employees[index], { index: index + 1 });

      return employees[index];
    };

    const employeesRowRenderer = ({ className, columns, index, key, style, rowData }: any) => {
      const isInactive = rowData.active === false;
      const rowClasses = classNames(className, {
        inactiveRow: isInactive
      });
      const a11yProps = { "aria-rowindex": index + 1 };
      return (
              <div
                  {...a11yProps}
                  className={rowClasses}
                  key={key}
                  role='row'
                  style={style}
              >
                  {columns}
              </div>
      );
    };

    const openPersonDetails = (rowData: any): JSX.Element => {
      const personId = rowData.rowData.personId;
      return (
          <div onClick={() => history.push("/upsert-person",
            { id: personId, view: props.view, search: props.search, from: StateFrom.DETAILS })}>
               {rowData.rowData.firstName + " " + rowData.rowData.lastName}
          </div>
      );
    };

    return (
          <VirtualizedTable
              rowCount={employees.length}
              rowGetter={employeesRowGetter}
              rowRenderer={employeesRowRenderer}
              headerHeight={50}
              columns={[
                {
                  width: 75,
                  label: "#",
                  dataKey: "index"
                },
                {
                  width: 200,
                  label: t("common.shortName"),
                  dataKey: "shortName"
                },
                {
                  width: 400,
                  label: t("common.personName"),
                  cellRenderer: openPersonDetails
                },
                {
                  width: 200,
                  label: t("common.dateOfEntry"),
                  dataKey: "dateOfEntry"
                },
                {
                  width: 100,
                  label: "legend.teamLeader",
                  dataKey: "teamLeader",
                  cellRenderer: statusCellRenderer,
                  headerRenderer: (HeaderWithTooltip)
                },
                {
                  width: 120,
                  label: t("legend.externalEmployee"),
                  dataKey: "external",
                  cellRenderer: statusCellRenderer
                },
                {
                  width: 100,
                  flexGrow: 1,
                  label: t("common.active"),
                  dataKey: "active",
                  cellRenderer: statusCellRenderer
                },
                {
                  width: 200,
                  label: t("common.actions"),
                  dataKey: "id",
                  cellRenderer: (actions)
                }
              ]}
          />
    );
  };

  const rowHeight = 48;
  const tableHeight = ((employees.length + 1) * rowHeight) + 25;
  return (
        <Box>
            {dataLoaded
              ? <>
                <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                    <h3><i>{t("common.employees")}</i></h3>
                  <Stack alignSelf={"center"} direction={"row"}>
                    <FormControlLabel
                      control={<Switch onChange={(e, check) => handleIncludeInactive(e, check)} checked={includeInactive}/>}
                      label={t("common.inactive")} />
                        <Button
                            variant={"contained"}
                            disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                            onClick={() =>
                              history.push("/upsert-employee", { search: props.search, view: props.view, from: StateFrom.ADD })}>
                          {t("button.addEmployee")}
                        </Button>
                    </Stack>
                </Stack>
                <Box style={{ height: tableHeight }}>
                    {renderEmployeeTable()}
                </Box>
                </>
              : <CircularProgress />
            }
        </Box>
  );
};
export default EmployeePage;
