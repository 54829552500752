import axios from "../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { UpsertUserDto, UserDto, UserRole } from "../pages/Users/types";
import { HolidayDto } from "../ts-types/api.types";
import { Undefinable } from "../types";

const baseUrl = "/company-manager-app/api/admin";

export const listAllUsers = (includeInactive: boolean, source: CancelTokenSource): Promise<Array<UserDto>> => {
  return axios
    .get(`${baseUrl}/get-all-users`, { cancelToken: source.token, params: { includeInactive } })
    .then(response => response.data);
};

export const getAllHolidays = (
  status: string,
  source: CancelTokenSource,
  userId?: number,
  type?: string): Promise<Array<HolidayDto>> => {
  return axios
    .get(`${baseUrl}/get-all-holidays-by-type`, { cancelToken: source.token, params: { type, userId, status } })
    .then(response => response.data);
};

export const register = (userDto: Partial<UpsertUserDto>, source: CancelTokenSource): Promise<UserDto> => {
  return axios
    .post(`${baseUrl}/register`, userDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const approve = (id: number, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/approve/${id}`, null, { cancelToken: source.token })
    .then(response => response.data);
};

export const updateHolidayDays = (
  id: number,
  maxAllowedDaysForHoliday: number,
  source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/admin/update/${id}`, null, { params: { maxAllowedDaysForHoliday }, cancelToken: source.token })
    .then(response => response.data);
};

export const decline = (id: Undefinable<number>, text: string, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/decline/${id}`, {
      reason: text
    }, { cancelToken: source.token })
    .then(response => response.data);
};

export const disableUser = (id: number, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/disable/${id}`, null, { cancelToken: source.token })
    .then(response => response.data);
};

export const enableUser = (id: number, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/enable/${id}`, null, { cancelToken: source.token })
    .then(response => response.data);
};

export const updateHolidaysTableAdmin = (
  status: string,
  source: CancelTokenSource,
  userId?: number,
  type?: string): Promise<Array<HolidayDto>> => {
  return axios
    .get(`${baseUrl}/update-table`, { cancelToken: source.token, params: { type, userId, status } })
    .then(response => response.data);
};

export const getRole = (personId: number, source: CancelTokenSource): Promise<UserRole> => {
  return axios
    .get(`${baseUrl}/get-role`, { cancelToken: source.token, params: { personId } })
    .then(response => response.data);
};
