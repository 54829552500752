import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { EmployeeDto, EmployeeTableDto, UpsertEmployeeDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/employee";

export const addEmployee = (employeeDto: Partial<UpsertEmployeeDto>, source: CancelTokenSource) => {
  return axios
    .post(baseUrl, employeeDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllEmployees = (source: CancelTokenSource): Promise<Array<EmployeeDto>> => {
  return axios
    .get(baseUrl, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllTeamLeaderEmployees = (search: string, source: CancelTokenSource): Promise<Array<EmployeeDto>> => {
  return axios
    .get(`${baseUrl}/all-team-leaders`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};

export const getAllEmployeesForSpecificTeamLeader = (employeeId: number, source: CancelTokenSource): Promise<Array<EmployeeTableDto>> => {
  return axios
    .get(`${baseUrl}/all-for-team-leader`, { cancelToken: source.token, params: { id: employeeId } })
    .then(response => response.data);
};

export const getAllEmployeePersonsTable = (
  includeInactive: boolean,
  source: CancelTokenSource
): Promise<Array<EmployeeTableDto>> => {
  return axios
    .get(`${baseUrl}/get-all-for-table`, { cancelToken: source.token, params: { includeInactive } })
    .then(response => response.data);
};

export const getEmployee = (id: number, source: CancelTokenSource): Promise<EmployeeDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
export const editEmployee = (employeeDto: Partial<UpsertEmployeeDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/${id}`, employeeDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const disableEmployee = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getEmployeeByPersonId = (id: number | undefined, source: CancelTokenSource) : Promise<EmployeeDto> => {
  return axios
    .get(`${baseUrl}/by-person-id//${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
