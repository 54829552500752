import { CancelTokenSource } from "axios";
import axios from "../../../custom-axios/axios";
import { ResetPasswordDto } from "../types";

const baseUrl = "/company-manager-app/api/reset-password";

export const requestPasswordResetToken = (email: string, source: CancelTokenSource): Promise<void> => {
  return axios
    .get(`${baseUrl}/request`, { cancelToken: source.token, params: { email } })
    .then(response => response.data);
};

export const resetPassword = (resetPasswordDto: ResetPasswordDto, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/reset`, resetPasswordDto, { cancelToken: source.token })
    .then(response => response.data);
};
