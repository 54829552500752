import axios, { AxiosRequestConfig } from "axios";
import { getAuthToken } from "../pages/Login/services/loginServices";

const backendRootUrl = process.env.REACT_APP_BACKEND_ROOT_URL;

export const backendUrls = {
  root: backendRootUrl,
  apiRoot: `${backendRootUrl}/api`
};

const client = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

client.interceptors.request.use(function (request: AxiosRequestConfig) {
  request.headers = {
    ...request.headers,
    Authorization: getAuthToken() as string
  };

  return request;
});

export default client;
