import { supportedLanguages } from "../utils/localizationUtils";
import { getAuthToken } from "../pages/Login/services/loginServices";

export const PROP_NAME_LANGUAGE = "company-manager-language";

export const getLanguage = () => {
  const sessionStorageLang: string | null = sessionStorage.getItem(PROP_NAME_LANGUAGE);

  if (sessionStorageLang && supportedLanguages.includes(sessionStorageLang)) {
    return sessionStorageLang;
  }

  const browserLang = window.navigator.language.slice(0, 2);
  if (supportedLanguages.includes(browserLang)) {
    return browserLang;
  }

  return "en";
};

export const setLanguage = (language: string) => {
  if (supportedLanguages.includes(language)) {
    sessionStorage.setItem(PROP_NAME_LANGUAGE, language);
  } else {
    sessionStorage.setItem(PROP_NAME_LANGUAGE, "en");
  }
};

export const getAuthorizationHeaderValue = (authToken?: string): string => {
  const tokenValue = authToken ?? getAuthToken();
  return `Bearer ${tokenValue}`;
};
