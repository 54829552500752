import React from "react";
import axios from "axios";
import { Alert, Box, Button, Collapse, IconButton, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ForgotPasswordDto } from "../../../components/interfaces/interface";
import CloseIcon from "@mui/icons-material/Close";
import { requestPasswordResetToken } from "../services/resetPasswordServices";

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { t } = useTranslation("login");
  const [username, setUsername] = React.useState<string>(state.username);
  const cancelTokenSource = React.useMemo(axios.CancelToken.source, []);
  const [open, setOpen] = React.useState(false);

  const handleChange = (e: any) => {
    setUsername(e.target.value);
  };

  const handleSubmit = () => {
    const forgotPasswordDto: ForgotPasswordDto = {
      username
    };
    requestPasswordResetToken(forgotPasswordDto.username, cancelTokenSource)
      .then(() => {
        setOpen(true);
      });
  };

  return (
      <Box alignSelf={"center"} justifySelf={"center"}>
        <Stack direction={"column"}>
          <Collapse in={open}>
            <Alert
                action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                  >
                    <CloseIcon fontSize="inherit"/>
                  </IconButton>
                }
                sx={{ mb: 2 }}
            >
              {t("alert.confirmationMailSentTo")} {username}
            </Alert>
          </Collapse>
          <Box display={"flex"} justifyContent={"center"}>
            <h1>{t("header.forgotPassword")}</h1>
          </Box>
          <Box sx={{ my: 2 }} display={"flex"} justifyContent={"center"}>
            <TextField
                size={"small"}
                type={"email"}
                value={username}
                onChange={handleChange}
                label={t("legend.enterEmail")}/>
          </Box>
          <Box>
            <hr/>
          </Box>
          <Stack direction={"row"} display={"flex"} justifyContent={"center"}>
            <Button
                sx={{ mx: 1 }}
                type={"button"}
                onClick={handleSubmit}
                variant={"contained"}>
              {t("button.submit")}
            </Button>
            <Button
                variant={"contained"}
                sx={{ background: "#64748B", mx: 1 }}
                onClick={() => history.push("/login", { username })}>
              {t("button.backToLogin")}
            </Button>
          </Stack>
        </Stack>
      </Box>
  );
};
export default ForgotPassword;
