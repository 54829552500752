import React, { FC, useCallback, useEffect } from "react";
import { AdminProps } from "../../components/interfaces/interface";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  addAccountedTimeTrackings,
  getAccountedTimeTrackingReportExportUrl,
  getAccountedTimetrackings
} from "./services/AccountedWorkingReportServices";
import { getAllEmployees } from "../Employees/services/EmployeeServices";
import { getAllProjects } from "../Projects/services/ProjectServices";
import { getAllProjectTasks } from "../ProjectTasks/services/ProjectTaskServices";
import styled from "styled-components";
import { AccountRemarkModal } from "./components/AccountRemarkModal";
import {
  getTimeTrackingReport
} from "../TimeTrackings/services/TimeTrackingServices";
import { ReworkingReportDateKey, ReworkingReportDto } from "../TimeTrackings/types";
import {
  AccountedTimeTrackingTableDto,
  AccountedWorkTrackingReportDto,
  EmployeeDto, ProjectTableDto, ProjectTaskDto,
  TimeTrackingReportDto
} from "../../ts-types/api.types";
import { AccountedTimeTrackingDto, AccountedWorkingReportDto, AddAccountedTimetrackingDto } from "./types";

const DatePickerClass = styled.div`
  .datePicker {
    justify-content: center;
    height: 25px;
    width: 150px;
  }
`;

const InitialDateStartOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");

const InitialReportDateKey: ReworkingReportDateKey = {
  dateTo: "",
  dateFrom: InitialDateStartOfMonth
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface SortOptions {
  date: { value: string, label: string }
  shortName: { value: string, label: string }
  projectNumber: { value: string, label: string }
}

const AccountedWorkingReports: FC<AdminProps> = (): JSX.Element => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [formDateChange, setFormDateChange] = React.useState<ReworkingReportDateKey>(InitialReportDateKey);
  const [accountedTimeTrackings, setAccountedTimeTrackings] = React.useState<Array<AccountedWorkTrackingReportDto>>([]);
  const [sort, setSort] = React.useState<string[]>(["date"]);
  const { t } = useTranslation("company-manager");
  const [accounting, setAccounting] = React.useState<boolean>(false);
  const [timeTrackings, setTimeTrackings] = React.useState<Map<number, TimeTrackingReportDto>>(new Map());
  const [form, setForm] = React.useState<Map<number, AddAccountedTimetrackingDto>>(new Map<number, AddAccountedTimetrackingDto>());
  const [employees, setEmployees] = React.useState<Array<EmployeeDto>>([]);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);
  const [projects, setProjects] = React.useState<Array<ProjectTableDto>>([]);
  const [projectTasks, setProjectTasks] = React.useState<Array<ProjectTaskDto>>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [currentRowIndex, setCurrentRowIndex] = React.useState<number>();
  const [workingReportForm, setWorkingReportForm] = React.useState<AccountedWorkingReportDto>({
    sort,
    showClosed: false,
    dateFrom: InitialDateStartOfMonth,
    dateTo: "",
    employeeShortName: "",
    project: ""
  });
  const [totalDuration, setTotalDuration] = React.useState<number>(0);
  const [totalAccDuration, setTotalAccDuration] = React.useState<number>(0);
  const [accountedDurationValidationMessage, setAccountedDurationValidationMessage] = React.useState<boolean>(false);
  const sortOptions: SortOptions = React.useMemo(() => {
    return {
      date: {
        value: "date",
        label: t("common.date")
      },
      shortName: {
        value: "shortName",
        label: t("common.shortName")
      },
      projectNumber: {
        value: "projectNumber",
        label: t("common.projectNumber")
      }
    };
  }, [t]);

  const handleSave = () => {
    if (!isAccountedDurationsEmpty()) {
      const newList: AccountedTimeTrackingDto[] = Array.from(timeTrackings.entries())
        .filter(([id]) => form.get(id)!.selected)
        .map(([id, timeTracking]) => ({
          timeTrackingId: id,
          date: timeTracking.date,
          remark: timeTracking.remark,
          duration: timeTracking.duration,
          projectId: timeTracking.projectId,
          projectTaskId: timeTracking.projectTaskId,
          employeeId: timeTracking.employeeId,
          accountedProjectTaskId: form.get(id)!.accountedProjectTaskId,
          accountedDate: form.get(id)!.accountedDate,
          accountedDuration: form.get(id)!.accountedDuration,
          accountedRemark: form.get(id)!.accountedRemark,
          accountedEmployeeId: form.get(id)!.accountedEmployeeId,
          accountedProjectId: form.get(id)!.accountedProjectId
        }));
      addAccountedTimeTrackings(newList, cancelTokenSource)
        .then(() => {
          loadAccountedTimeTrackingTableDto()
            .then(() => {
              setAccounting(false);
            });
        });
      setAccountedDurationValidationMessage(false);
    } else {
      setAccountedDurationValidationMessage(true);
    }
  };

  const handleShowClosed = () => {
    if (!workingReportForm.showClosed) {
      setWorkingReportForm({ ...workingReportForm, showClosed: true });
    } else {
      setWorkingReportForm({ ...workingReportForm, showClosed: false });
    }
  };

  const exportTimeTrackingReport = () => {
    setTimeout(() => {
      getAccountedTimeTrackingReportExportUrl(
        workingReportForm.dateFrom,
        workingReportForm.dateTo,
        workingReportForm.employeeShortName,
        workingReportForm.project,
        workingReportForm.showClosed,
        sort).then();
    }, 500);
  };

  const handlePreview = () => {
    setAccountedDurationValidationMessage(false);
    if (accounting) {
      loadUnaccountedTimeTracks();
    } else {
      loadAccountedTimeTrackingTableDto();
    }
  };

  const loadAccountedTimeTrackingTableDto = async () => {
    setDataLoaded(false);
    try {
      const response = await getAccountedTimetrackings({ ...workingReportForm, sort }, cancelTokenSource);
      loadTableData(response);
    } finally {
      setDataLoaded(true);
    }
  };

  const loadTableData = (response: AccountedTimeTrackingTableDto) => {
    setAccountedTimeTrackings(response.accountedWorkTrackingReportDtos);
    setTotalDuration(response.duration ? response.duration : 0);
    setTotalAccDuration(response.accDuration);
  };

  const handleFormChange = (e: any) => {
    setWorkingReportForm({
      ...workingReportForm,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleAccountDateChange = (response: moment.Moment, id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.accountedDate = moment(response).format("YYYY-MM-DD");
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const handleDateChange = (dateKey: keyof ReworkingReportDateKey) => (value: string) => {
    setWorkingReportForm({
      ...workingReportForm,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
    setFormDateChange({
      ...formDateChange,
      [dateKey]: moment(value).format("YYYY-MM-DD")
    });
  };

  const changeToLastMonth = () => {
    const today = moment().clone();
    today.subtract(1, "month");
    setFormDateChange({
      dateFrom: moment(today).clone().startOf("month").format("YYYY-MM-DD"),
      dateTo: moment(today).clone().endOf("month").format("YYYY-MM-DD")
    });
    setWorkingReportForm({
      ...workingReportForm,
      dateFrom: moment(today).clone().startOf("month").format("YYYY-MM-DD"),
      dateTo: moment(today).clone().endOf("month").format("YYYY-MM-DD")
    });
  };

  const handleSelectionChange = (event: SelectChangeEvent<typeof sort>) => {
    const {
      target: { value }
    } = event;
    setSort(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    calculateAccountedTimeTracks();
  }, [form]);
  const renderAccountingTable = () => {
    return (
        <Box>
          {renderAccountTimeTrackingsTable()}
          <Box>{renderTotalDurations(timeTrackings.size)}</Box>
        </Box>
    );
  };

  const handleAccountingChange = async () => {
    setAccountedDurationValidationMessage(false);
    if (accounting) {
      loadAccountedTimeTrackingTableDto();
      setAccounting(false);
    } else {
      loadUnaccountedTimeTracks();
      setAccounting(true);
    }
  };

  const handleAccountDuration = (e: any, id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.accountedDuration = e.target.value;
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const handleEmployeeChange = (e: any, id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.accountedEmployeeId = e.target.value;
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const handleProjectChange = (e: any, id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.accountedProjectId = e.target.value;
      reportDto.accountedProjectTaskId = 0;
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const handleProjectTaskChange = (e: any, id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.accountedProjectTaskId = e.target.value;
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const loadUnaccountedTimeTracks = async () => {
    setDataLoaded(false);
    const reworkingReport: ReworkingReportDto = {
      dateTo: workingReportForm.dateTo,
      dateFrom: workingReportForm.dateFrom,
      unAccounted: true,
      sort,
      showClosed: workingReportForm.showClosed,
      projectName: workingReportForm.project,
      employeeShortName: workingReportForm.employeeShortName
    };
    try {
      const employeeResponse = await getAllEmployees(cancelTokenSource);
      setEmployees(employeeResponse);
      const timetracksResponse: any = await getTimeTrackingReport(reworkingReport, cancelTokenSource);
      const timetrackingsMap = new Map<number, TimeTrackingReportDto>();
      timetracksResponse?.timeTrackingReportDtos?.map((timetrack: any) => timetrackingsMap.set(timetrack.id, timetrack));
      setTimeTrackings(timetrackingsMap);
      createFormData(timetracksResponse.timeTrackingReportDtos);
      const projectResponse = await getAllProjects(cancelTokenSource, "");
      setProjects(projectResponse);
      const projectTaskResponse = await getAllProjectTasks(cancelTokenSource);
      setProjectTasks(projectTaskResponse);
      setTotalDuration(timetracksResponse.duration ? timetracksResponse.duration : 0);
    } finally {
      setDataLoaded(true);
    }
  };

  const createFormData = (timetracks: Array<TimeTrackingReportDto>) => {
    const accountedTimetracksMap = new Map(timetracks.map((timetrack) =>
      [timetrack.id, {
        timetrackingId: timetrack.id,
        accountedProjectId: timetrack.projectId,
        accountedEmployeeId: timetrack.employeeId,
        accountedProjectTaskId: timetrack.projectTaskId,
        accountedDate: timetrack.date,
        accountedDuration: timetrack.duration,
        selected: true,
        accountedRemark: timetrack.remark
      }]));
    setForm(accountedTimetracksMap);
  };

  const calculateAccountedTimeTracks = () => {
    const total = Array.from(form.values())
      .reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.accountedDuration);
      }, 0);
    setTotalAccDuration(total);
  };

  const renderReportTable = (): JSX.Element => {
    const durationCells = ({ rowData }: any) => {
      return (
          <div>{rowData.duration}h</div>
      );
    };

    const accountedDurationCells = ({ rowData }: any) => {
      return (
          <div>{rowData.accountedDuration}h</div>
      );
    };

    const accountedTimeTrackReportsRowGetter = ({ index }: any) => {
      Object.assign(accountedTimeTrackings[index], { index: index + 1 });

      return accountedTimeTrackings[index];
    };

    const accountedTimeTrackReportsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };
      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };

    return (
        <VirtualizedTable
            rowCount={accountedTimeTrackings.length}
            rowGetter={accountedTimeTrackReportsRowGetter}
            rowRenderer={accountedTimeTrackReportsRowRenderer}
            columns={[
              {
                width: 175,
                label: t("tableHeader.projectNo"),
                dataKey: "projectNumber"
              },
              {
                width: 185,
                label: t("tableHeader.accountedProjectNo"),
                dataKey: "accountedProjectNumber"
              },
              {
                width: 160,
                label: t("tableHeader.projectTask"),
                dataKey: "projectTaskDesc"
              },
              {
                width: 220,
                label: t("tableHeader.accountedProjectTaskDescription"),
                dataKey: "accountedProjectTaskDesc"
              },
              {
                width: 60,
                label: t("tableHeader.sn"),
                dataKey: "shortName"
              },
              {
                width: 120,
                label: t("tableHeader.accountedSN"),
                dataKey: "accountedShortName"
              },
              {
                width: 125,
                label: t("common.date"),
                dataKey: "date"
              },
              {
                width: 165,
                label: t("tableHeader.accountedDate"),
                dataKey: "accountedDate"
              },
              {
                width: 100,
                label: t("common.duration"),
                dataKey: "duration",
                cellRenderer: (durationCells)
              },
              {
                width: 170,
                label: t("tableHeader.accountedDuration"),
                dataKey: "accountedDuration",
                cellRenderer: (accountedDurationCells)
              },
              {
                width: 200,
                flexGrow: 1,
                label: t("tableHeader.remark"),
                dataKey: "remark"
              },
              {
                width: 305,
                flexGrow: 1,
                label: t("tableHeader.accountedRemark"),
                dataKey: "accountedRemark"
              }
            ]}
        />
    );
  };

  const handleSelectChange = (id: number) => {
    const newMap = new Map(form);
    const reportDto = newMap.get(id);
    if (newMap && reportDto !== undefined) {
      reportDto.selected = !reportDto.selected;
      newMap.set(id, reportDto);
    }
    setForm(newMap);
  };

  const selectBox = useCallback(({ rowData }: any) => {
    const id = rowData.id;
    return (
        <Checkbox onClick={() => handleSelectChange(id)} checked={form.get(id)?.selected}/>
    );
  }, [form]);

  const selectDeselectAll = (state: number) => {
    const newMap = new Map(form);
    if (state === 0) {
      newMap.forEach((value) => {
        value.selected = true;
      });
    } else {
      newMap.forEach((value) => {
        value.selected = false;
      });
    }
    setForm(newMap);
  };

  const selectBoxLabel = () => {
    let howManyAreSelected = 0;
    let selectBoxState = 2;
    form.forEach((timeTrack) => {
      if (timeTrack.selected) {
        howManyAreSelected++;
      }
    });
    if (howManyAreSelected === 0) {
      selectBoxState = 0;
    } else if (howManyAreSelected < form.size) {
      selectBoxState = 1;
    } else {
      selectBoxState = 2;
    }
    return (
        <Checkbox
            onClick={() => selectDeselectAll(selectBoxState)}
            checked={selectBoxState === 1 || selectBoxState === 2}
        color={selectBoxState === 1 ? "default" : "primary"}
        sx={{ ml: -0.5 }}/>
    );
  };

  const renderAccountTimeTrackingsTable = () => {
    const durationCells = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <div>{timeTrackings.get(id)?.duration}h</div>
      );
    };

    const accountSn = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <Select
              size={"small"}
              sx={{ width: 75 }}
              value={form.get(id)?.accountedEmployeeId}
              onChange={(e) => handleEmployeeChange(e, id)}>
            {employees?.map((employee) => {
              return (
                  <MenuItem key={employee.id} value={employee.id}>{employee.shortName}</MenuItem>
              );
            })}
          </Select>
      );
    };

    const accountDuration = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <TextField
              required={true}
              sx={{ width: 70 }}
              size={"small"}
              type={"text"}
              value={form.get(id)?.accountedDuration}
              placeholder={t("placeHolder.hours")}
              onChange={(e) => handleAccountDuration(e, id)}/>
      );
    };

    const accountRemark = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Box sx={{ width: 150 }}>
            {form.get(id)?.accountedRemark}
            </Box>
            <Button
                sx={{ width: 155 }}
                type={"button"}
                variant={"outlined"}
            onClick={() => {
              setCurrentRowIndex(id);
              setModalOpen(true);
            }}>
              {t("button.accountRemark")}
            </Button>
          </Stack>
      );
    };

    const accountProject = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <Select
              size={"small"}
              sx={{ width: 135 }}
              value={form.get(id)?.accountedProjectId}
              onChange={(e) => handleProjectChange(e, id)}>
            {projects?.map((project) => {
              return (
                  <MenuItem key={project.id} value={project.id}>{project.projectNumber}</MenuItem>
              );
            })}
          </Select>
      );
    };

    const accountProjectTask = ({ rowData }: any) => {
      const id = rowData.id;
      let newProjectTaskList: Array<ProjectTaskDto> = [...projectTasks];
      const accountedProjectId = form.get(id)?.accountedProjectId;
      newProjectTaskList = newProjectTaskList.filter(projectTask => projectTask.projectId === accountedProjectId
      );
      return (
          <Select
              size={"small"}
              sx={{ width: 190 }}
              value={form.get(id)?.accountedProjectTaskId}
              onChange={(e) => handleProjectTaskChange(e, id)}>
            <MenuItem key={0} value={0}><Box color={"red"}>{t("common.undefined")}</Box></MenuItem>
            {newProjectTaskList.map((projectTask) => {
              return (
                  <MenuItem key={projectTask.id} value={projectTask.id}>{projectTask.description}</MenuItem>
              );
            })}
          </Select>
      );
    };

    const accountDate = ({ rowData }: any) => {
      const id = rowData.id;
      return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePickerClass>
              <DesktopDatePicker
                  className="datePicker"
                  inputFormat="DD/MM/YYYY"
                  value={form.get(id)?.accountedDate}
                  onChange={(e) => handleAccountDateChange(moment(e), id)}
                  renderInput={(params) => <TextField {...params} />}
              />
            </DatePickerClass>
          </LocalizationProvider>
      );
    };

    const AccountTimeTrackRowGetter = ({ index }: any) => {
      const trackings = [...timeTrackings.values()];
      const timeTracking = trackings[index];

      Object.assign(timeTracking, { index: index + 1 });
      return timeTracking;
    };
    const AccountTimeTrackRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
          <div
              {...a11yProps}
              className={className}
              key={key}
              role='row'
              style={style}
          >
            {columns}
          </div>
      );
    };
    return (
        <VirtualizedTable
            height={800}
            rowCount={[...timeTrackings.values()].length}
            rowGetter={AccountTimeTrackRowGetter}
            rowRenderer={AccountTimeTrackRowRenderer}
            columns={[
              {
                label: (selectBoxLabel()),
                width: 50,
                dataKey: "id",
                cellRenderer: (selectBox)
              },
              {
                width: 125,
                label: t("tableHeader.projectNo"),
                dataKey: "projectNumber"
              },
              {
                width: 160,
                label: t("tableHeader.accountProjectNo"),
                dataKey: "id",
                cellRenderer: (accountProject)
              },
              {
                width: 160,
                label: t("tableHeader.projectTaskDescription"),
                dataKey: "projectTaskDesc"
              },
              {
                width: 220,
                label: t("tableHeader.accountProjectTaskDescription"),
                dataKey: "id",
                cellRenderer: (accountProjectTask)
              },
              {
                width: 60,
                label: t("tableHeader.sn"),
                dataKey: "shortName"
              },
              {
                width: 120,
                label: t("tableHeader.accountSN"),
                dataKey: "id",
                cellRenderer: (accountSn)
              },
              {
                width: 125,
                label: t("common.date"),
                dataKey: "date"
              },
              {
                width: 165,
                label: t("tableHeader.accountDate"),
                dataKey: "id",
                cellRenderer: (accountDate)
              },
              {
                width: 100,
                label: t("common.duration"),
                dataKey: "duration",
                cellRenderer: (durationCells)
              },
              {
                width: 170,
                label: t("tableHeader.accountDuration"),
                dataKey: "id",
                cellRenderer: (accountDuration)
              },
              {
                width: 200,
                label: t("tableHeader.remark"),
                dataKey: "remark"
              },
              {
                width: 305,
                flexGrow: 1,
                label: t("button.accountRemark"),
                dataKey: "id",
                cellRenderer: (accountRemark)
              }
            ]}
        />
    );
  };
  const renderTotalDurations = useCallback((trackingsLength: number): JSX.Element => {
    const height = trackingsLength > 16 ? 800 : (trackingsLength + 1) * 46.5;
    return (
        <Box>
          <Chip style={{ marginTop: `${height}px`, marginLeft: "1172px" }} size='medium' label={`${t("placeHolder.totalDuration")}: ${totalDuration} ${t("placeHolder.totalAccDuration")}: ${totalAccDuration}`} />
        </Box>
    );
  }, [totalDuration, totalAccDuration]);
  const isAccountedDurationsEmpty = () => {
    const entriesArray = Array.from(form.values());
    const foundEntry = entriesArray.find((timetrack) => timetrack.accountedDuration.toString() === "");
    return foundEntry !== undefined;
  };
  return (
      <Box>
        <Stack direction={"column"}>
          <Stack direction={"row"}>
          <TextField
              sx={{ width: 259, m: 2 }}
              name={"project"}
              label={t("common.project")}
              value={workingReportForm?.project}
              onChange={handleFormChange}/>
          <TextField
              sx={{ width: 259, m: 2 }}
              name={"employeeShortName"}
              label={t("legend.employeeShortName")}
              value={workingReportForm?.employeeShortName}
              onChange={handleFormChange}/>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ m: 2 }}>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("legend.from")}
                  inputFormat="DD/MM/YYYY"
                  value={formDateChange.dateFrom}
                  onChange={handleDateChange("dateFrom")}
                  renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ m: 2 }}>
              <DesktopDatePicker
                  className="datePicker"
                  label={t("legend.to")}
                  inputFormat="DD/MM/YYYY"
                  value={formDateChange.dateTo}
                  onChange={handleDateChange("dateTo")}
                  renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <Button
              size={"small"}
              sx={{ m: 2 }}
              variant={"outlined"}
              type={"button"}
              onClick={changeToLastMonth}>
            {t("button.lastMonth")}
          </Button>
          </Stack>
          {accountedDurationValidationMessage
            ? <Box sx={{ display: "flex", marginLeft: "85%", fontSize: "14px", color: "red" }}>
              {t("message.error")}
            </Box>
            : <Box>
            </Box>
          }
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"}>
          <Box sx={{ alignContent: "center", display: "flex" }}>
            <FormControl sx={{ m: 2, width: 400, height: 56 }}>
              <InputLabel id="demo-multiple-chip-label">{t("legend.sort")}</InputLabel>
              <Select
                  sx={{ height: 56 }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={sort}
                  onChange={handleSelectionChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Sort"/>}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: keyof SortOptions) => (
                            <Chip key={value} label={sortOptions[value].label}/>
                        ))}
                      </Box>
                  )}
              >
                {Object.keys(sortOptions).map((key: keyof SortOptions) => (
                    <MenuItem key={sortOptions[key].value} value={sortOptions[key].value}>
                      {sortOptions[key].label}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <FormControlLabel control={<Checkbox
                  checked={workingReportForm.showClosed}
                  onChange={handleShowClosed}
                  inputProps={{ "aria-label": "controlled" }}/>}
                                label={t("button.showClosedProjects")}/>
            </Box>
            </Stack>
            <Stack direction={"row"}>
          <Box sx={{ flexGrow: 1, alignContent: "center", display: "flex" }}>
            <Button
                variant={"outlined"}
                sx={{ height: 56, width: 107, margin: 2 }}
                onClick={handleAccountingChange}>
              {t("button.account")}
            </Button>
            <Button
                sx={{ height: 56, width: 107, margin: 2 }}
                size={"small"}
                variant={"outlined"}
                type={"button"}
                onClick={handlePreview}>
              {t("button.preview")}
            </Button>
            {accounting
              ? <Button
                    onClick={handleSave}
                    variant={"outlined"}
                    sx={{ height: 56, width: 107, margin: 2 }}>
                  {t("common.save")}
                </Button>
              : <Button
                    variant={"outlined"}
                    sx={{ height: 56, width: 107, margin: 2 }}
                    onClick={() => exportTimeTrackingReport()}>
                  {t("button.export")}
                </Button>
            }
          </Box>
            </Stack>
          </Stack>
        </Stack>
        {dataLoaded
          ? <Box>
              {accounting
                ? <Box height={800}>
                    <Box>
                      {renderAccountingTable()}
                    </Box>

              </Box>
                : <Box height={800}>
                    {renderReportTable()}
                    {renderTotalDurations(accountedTimeTrackings.length)}
                  </Box>}
            </Box>
          : <CircularProgress />}
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          {accounting
            ? <Button
                onClick={handleSave}
                type={"submit"}
              >
                {t("common.save")}
              </Button>
            : <Button onClick={() => exportTimeTrackingReport()}>
                {t("button.export")}
              </Button>}
        </Box>
        {modalOpen && currentRowIndex && <AccountRemarkModal
            show={modalOpen}
            close={handleCloseModal}
            form={form}
            rowIndex={currentRowIndex}
            save={setForm}
            timetracks={timeTrackings}/>}
      </Box>
  );
};
export default AccountedWorkingReports;
