import React from "react";
import { AutoSizer, Column, Index, Table, TableProps } from "react-virtualized";
import { ColumnPropsExtended } from "./VirtualizedTable";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledTable = styled(Table)`

  .ReactVirtualized__Table__Grid {
    outline: none;
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: 600;
    font-size: 10px;
    color: silver;
  }
  
  & .ReactVirtualized__Table__row {
    cursor: pointer;
    font-size: 15px;
    padding-top: -0.5px;
    height: 30px;
    
    &:hover .ReactVirtualized__Table__rowColumn {
      background: rgba(0,0,0,.05)!important;
      color: rgba(0,0,0,.95)!important;
    }

    & .ReactVirtualized__Table__rowColumn {
      transition: 250ms all ease-in-out;
      background-color: #ffffff;
      color: #000000;
      height: 30px;
      min-width: 80px;
    }

    & .ReactVirtualized__Table__rowColumn + .ReactVirtualized__Table__rowColumn {
      padding-left: 0px;
    }

    .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
      margin-right: 0px;
    }
    
    & .ReactVirtualized__Table__rowColumn:first-of-type {
      margin-left: 0px;
      padding-left: 0px;
    }

    & .ReactVirtualized__Table__rowColumn:last-of-type {
      margin-left: 0px;
      padding-left: 0px;
    }
  }

  & .ReactVirtualized__Table__headerColumn {
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-bottom: 2px;
    border-right: 1px solid silver;
    border-bottom: 1px solid silver;
    min-width: 80px;
  }

  & .ReactVirtualized__Table__rowColumn {
    padding-left: 7px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-right: 1px solid silver;
    border-bottom: 1px solid silver;
  }
  & .ReactVirtualized__Table__rowColumn:last-of-type {
    border-right: none;
  }

  & .ReactVirtualized__Table__headerColumn:first-of-type {
    border-bottom: 1px solid silver;
  }

  & .ReactVirtualized__Table__headerColumn:last-of-type {
    color: black;
    border-right: none;
  }
  
  .ReactVirtualized__Table__row:last-of-type {
    & .ReactVirtualized__Table__rowColumn {
      border-bottom: none;
      font-style: oblique;
      font-weight: bold;
    }
  }
  .ReactVirtualized__Table__row {
    & .ReactVirtualized__Table__rowColumn:first-of-type {
      justify-content: left;
      padding-left: 10px;
    }
  }

  .bodyColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 30px;
  }
`;

function rowClassName ({ index }: Index) {
  if (index < 0) {
    return "headerRow";
  } else {
    return index % 2 === 0 ? "evenRow" : "oddRow";
  }
}

const VirtualizedForTimeTrackingTable: React.VFC<Partial<TableProps>> = ({ columns, ...tableProps }) => {
  return (
      <AutoSizer>
        {({ width, height }) => (
            <StyledTable
                width={width}
                height={height}
                headerHeight={25}
                headerClassName='headerColumn'
                rowClassName={rowClassName}
                rowHeight={tableProps.rowHeight || 30}
                {...tableProps}
            >
              {
                columns.map(({ dataKey, customClass, ...other }: Partial<ColumnPropsExtended>) => {
                  const custom = customClass || "";
                  return (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <Column
                          key={dataKey}
                          className={`bodyColumn ${custom}`}
                          dataKey={dataKey}
                          width={width}
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px"
                          }}
                          {...other}
                      />
                  );
                })
              }
            </StyledTable>
        )}
      </AutoSizer>
  );
};

export default VirtualizedForTimeTrackingTable;
