import axios, { backendUrls } from "../../../custom-axios/axios";
import { isDevEnv } from "../../../utils/appUtils";
import { getAuthorizationHeaderValue } from "../../../service/browserStorageServices";
import { LoginUserDto } from "../types";
import { ChangePasswordDto } from "../../../components/Navbar/types";
import { CancelTokenSource } from "axios";
import { LoginDto } from "../../../components/interfaces/interface";

const baseUrl = "/company-manager-app/api/users";

const rootUrl = backendUrls.root;

const devEnv = isDevEnv();
const textUrls = {
  privateRoot: devEnv ? `${rootUrl}/res/dev-locale` : `${rootUrl}/res/locale`,
  publicRoot: devEnv ? `${rootUrl}/pub/res/dev-locale` : `${rootUrl}/pub/res/locale`
};

export const loadLocaleResources = (url: string, isPrivateResource: boolean) => {
  const config: any = {
    url: `${textUrls.publicRoot}/${url}`,
    method: "get"
  };

  if (isPrivateResource) {
    config.url = `${textUrls.privateRoot}/${url}`;
    config.headers = {
      Authorization: getAuthorizationHeaderValue()
    };
  }

  return axios(config);
};

export const getUserData = (source: CancelTokenSource): Promise<LoginUserDto> => {
  return axios
    .get(`${baseUrl}/get-logged-user`, { cancelToken: source.token })
    .then(response => response.data);
};

export const changePassword = (changePasswordDto: ChangePasswordDto, source: CancelTokenSource): Promise<void> => {
  return axios
    .put(`${baseUrl}/change`, changePasswordDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const loginUser = (login: LoginDto, source: CancelTokenSource): Promise<void> => {
  return axios.post("company-manager-app/login", login, { cancelToken: source.token });
};

export const logOutUser = (source: CancelTokenSource) => {
  return axios.post("company-manager-app/logout", null, { cancelToken: source.token });
};
