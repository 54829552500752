import moment from "moment/moment";
import React from "react";
import { CalendarProps } from "../interfaces/interface";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import classNames from "classnames";
import CalendarStyled from "./CalendarStyled";
import { getPublicVacationsForMonth } from "../../pages/PublicVacations/services/PublicVacationServices";
import axios from "axios";
import { PublicVacationDto } from "../../pages/PublicVacations/types";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

const Calendar = (props: CalendarProps) => {
  const today = moment();
  const [startDay, setStartDay] = React.useState(moment().clone().startOf("month"));
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [publicVacations, setPublicVacations] = React.useState<Map<string, string>>(new Map());
  const [calendarPublicVacations, setCalendarPublicVacations] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    loadPublicVacations();
  }, []);

  const loadPublicVacations = () => {
    const newMap: Map<string, string> = new Map();
    getPublicVacationsForMonth(moment(startDay).format("YYYY-MM-DD"), cancelTokenSource)
      .then((r) => {
        r.forEach((publicVacation) => newMap.set(publicVacation.date, publicVacation.description));
        setPublicVacations(newMap);
        loadCalendarPublicVacations(r);
      });
  };

  const loadCalendarPublicVacations = (vacations: Array<PublicVacationDto>) => {
    const stringArray: Array<string> = [];
    vacations.forEach(vacation => {
      if (moment(vacation.date).format("dddd") === "Sunday") {
        stringArray.push(moment(vacation.date).add(1, "day").format("YYYY-MM-DD"));
      } else {
        stringArray.push(vacation.date);
      }
    });
    setCalendarPublicVacations(stringArray);
  };

  const handleMonthChange = (forward: boolean) => {
    if (forward) {
      setStartDay(startDay.add(1, "month").clone());
      loadPublicVacations();
    } else {
      setStartDay(startDay.subtract(1, "month").clone());
      loadPublicVacations();
    }
  };

  const renderWeekdaysForCalendar = () => {
    return <Box sx={{ display: "flex", flexDirection: "row" }}>
      {moment.weekdays(true).map((weekday) => {
        return (
            <Box sx={{ width: 100, display: "flex", justifyContent: "center" }}>
              {weekday.slice(0, 3)}
            </Box>
        );
      })}
    </Box>;
  };

  const renderCalendar = React.useMemo(() => {
    const calendar: { days: moment.Moment[]; }[] = [];
    const endDay = moment(startDay, "DD-MM-YYYY").clone().endOf("month").endOf("week");
    const date = moment(startDay, "DD-MM-YYYY").clone().startOf("week").subtract(1, "day");

    while (date.isBefore(endDay, "day")) {
      calendar.push({
        days: Array(7).fill(0).map(() => date.add(1, "day").clone())
      });
    }

    const getTimeTrackingsForADay = (day: moment.Moment) => {
      let totalTime = 0;
      props.timeTrackings.forEach((timeTrack) => {
        if (timeTrack.date === day.format("YYYY-MM-DD")) {
          totalTime += timeTrack.duration;
        }
      });
      const totalTimeStringFormat =
          moment.utc(moment.duration(totalTime, "hours").asMilliseconds()).format("HH:mm");
      return totalTime
        ? <div className={classNames(
          { timetrack: totalTime >= 7 && totalTime <= 9 },
          { timetrackbellow: totalTime < 7 },
          { timetrackabove: totalTime > 9 }
        )}>
            {totalTimeStringFormat}
          </div>
        : <div></div>;
    };

    const dayCell = (day: moment.Moment) => {
      return (
          <div className={"day"}>
            <div className={classNames({ today: moment(today).isSame(day, "day") })}>
              {moment(day).format("D").toString()}
            </div>
          </div>
      );
    };

    return <div className="container">
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <IconButton onClick={() => handleMonthChange(false)}>
          <ArrowLeft/>
        </IconButton>
        <h3 style={{ margin: 0, width: 200, display: "flex", justifyContent: "center" }}>
          {moment(startDay, "DD-MM-YYYY").format("MMMM-YYYY")}
        </h3>
        <IconButton onClick={() => handleMonthChange(true)}>
          <ArrowRight/>
        </IconButton>
      </Box>
      {renderWeekdaysForCalendar()}
      <Box className={"blabla"} sx={{ borderColor: "transparent", borderRadius: "10px" }}>
        {calendar.map((days, index) => {
          return (<Box
              className={classNames({ weekDay: true }, { "last-week": index === calendar.length - 1 })}
              sx={{ display: "flex", flexDirection: "row" }}>
            {days.days.map((day) => {
              return (
                  <div className={classNames({ "calendar-cells": true },
                    { "not-in-month": !moment(day).isSame(startDay, "month") },
                    { weekend: moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7 },
                    { weekend: calendarPublicVacations.includes(moment(day).format("YYYY-MM-DD")) },
                    { "selected-container": moment(day).isSame(props.selectedDate, "day") })}
                       onClick={() => props.handleDateChange(day)}>
                    {dayCell(day)}
                    {publicVacations.get(day.format("YYYY-MM-DD"))
                      ? <Tooltip title={publicVacations.get(day.format("YYYY-MM-DD"))}>
                          <IconButton>
                            <HolidayVillageIcon fontSize={"small"}/>
                          </IconButton>
                        </Tooltip>
                      : <> </>}
                    {getTimeTrackingsForADay(day)}
                  </div>
              );
            })}
          </Box>);
        })}
      </Box>
    </div>;
  }, [startDay, props.selectedDate, props.timeTrackings, calendarPublicVacations]);
  return (
      <CalendarStyled>
        {renderCalendar}
      </CalendarStyled>
  );
};
export default Calendar;
