import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { ProjectTaskDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/projecttask";

export const addProjectTask = (projectTaskDto: Partial<ProjectTaskDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, projectTaskDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllProjectTasksByProjectId = (
  projectId: number,
  source: CancelTokenSource): Promise<Array<ProjectTaskDto>> => {
  return axios
    .get(`${baseUrl}/all-by-projectid`, { cancelToken: source.token, params: { projectId } })
    .then(response => response.data);
};

export const getAllActiveProjectTasksByProjectId = (
  projectId: number,
  source: CancelTokenSource): Promise<Array<ProjectTaskDto>> => {
  return axios
    .get(`${baseUrl}/all-active-by-projectid`, { cancelToken: source.token, params: { projectId } })
    .then(response => response.data);
};

export const getAllProjectTasks = (
  source: CancelTokenSource): Promise<Array<ProjectTaskDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getProjectTask = (id: number, source: CancelTokenSource): Promise<ProjectTaskDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editProjectTask = (projectTaskDto: Partial<ProjectTaskDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, projectTaskDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const deleteProjectTask = (id: number, source: CancelTokenSource) => {
  return axios
    .delete(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};
