import React, { FC } from "react";
import { PopperProps } from "../interfaces/interface";
import { Button, DialogActions, DialogTitle, Paper, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";

const YNPopper: FC<PopperProps> = (props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null|HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState<boolean>(false);
  const { t } = useTranslation("company-manager");

  const togglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenPopper(!openPopper);
  };

  return (
      <React.Fragment>
          <Button
              type="button"
              onClick={(event) => togglePopper(event)}
              color={props.color ? props.color : "primary"}
              disabled={props.disabled}
              variant={props.variant}
              endIcon={props.icon}
              sx={{ mx: 1 }}
          >
              {props.message}
          </Button>
        <Popper open={openPopper} anchorEl={anchorEl}>
            <Paper sx={{ maxWidth: 400, overflow: "auto" }}>
                <DialogTitle>{t("header.areYouSure")}</DialogTitle>
                <DialogActions>
                    <Button onClick={(event) => {
                      if (props.onConfirm) {
                        props.onConfirm();
                      }
                      togglePopper(event);
                    }}>{t("button.yes")}</Button>
                    <Button onClick={togglePopper}>{t("button.no")}</Button>
                </DialogActions>
            </Paper>
        </Popper>
      </React.Fragment>
  );
};

export default YNPopper;
