import React from "react";
import { TableCell, TableRow } from "@mui/material";

export const emptyTableCell = () => {
  return <div>&nbsp;</div>;
};

export const emptyTableRows = (numOfRows: number, numOfCells: number) => {
  let arrayLength = 3;

  if (numOfRows >= 3) {
    arrayLength = 0;
  } else if (numOfRows < 3 && numOfRows >= 0) {
    arrayLength = arrayLength - numOfRows;
  }

  const arrayRows = Array.from({ length: arrayLength }, (_, index) => (index + 1) - 1);
  const arrayCells = Array.from({ length: numOfCells }, (_, index) => (index + 1) - 1);

  return arrayRows.map(raw => {
    return (
        <TableRow key={raw.valueOf()}>
          {
            arrayCells.map(cell => {
              return (
                  <TableCell key={cell.valueOf()}>

                  </TableCell>
              );
            })
          }
        </TableRow>
    );
  });
};
