import React, { useState } from "react";
import {
  Alert,
  Box,
  Button, Checkbox, Collapse,
  FormControl,
  FormControlLabel, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { StateFrom } from "../../../components/interfaces/enums";
import { useTranslation } from "react-i18next";
import {
  addCompanyAddress,
  editCompanyAddress,
  getCompanyAddress
} from "../services/CompanyAddressServices";
import Countries from "../../../components/interfaces/Countries.json";
import { AddressType } from "../../../ts-types/api.enums";
import { UpsertCompanyAddressDto } from "../../../ts-types/api.types";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";
const AddEditCompanyAddress = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [message, setMessage] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { state } = useLocation<any>();
  const history = useHistory();
  const [formData, updateFormData] = React.useState<UpsertCompanyAddressDto>({
    email: "",
    telephone: "",
    companyId: state.companyId,
    nameOne: state.companyName,
    nameTwo: "",
    addressType: AddressType.BILLING_ADDRESS,
    country: "",
    city: "",
    zip: "",
    streetName: "",
    isDefaultAddress: false
  });
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setMessage(t("header.editCompanyAddress"));
        loadCompanyAddress();
        break;
      case StateFrom.DETAILS:
        setMessage(t("header.companyAddressDetails"));
        loadCompanyAddress();
        break;
      case StateFrom.ADD:
        setMessage(t("header.addCompanyAddress"));
        updateFormData({
          ...formData,
          companyId: state.companyId
        });
        break;
    }
  }, []);

  const loadCompanyAddress = () => {
    getCompanyAddress(state.id, cancelTokenSource)
      .then(CompanyAddressR => {
        updateFormData(CompanyAddressR);
      });
  };

  const onFormSubmit = () => {
    switch (state.from) {
      case StateFrom.ADD:
        addCompanyAddress(formData, cancelTokenSource)
          .then(() => history.push("/upsert-company",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId }))
          .catch((e) => handleError(e.response.data));
        break;
      case StateFrom.EDIT:
        editCompanyAddress(formData, state.id, cancelTokenSource)
          .then(() => history.push("/upsert-company",
            { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId }))
          .catch((e) => handleError(e.response.data));
        break;
    }
  };

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleIsDefault = () => {
    if (!formData.isDefaultAddress) {
      updateFormData({ ...formData, isDefaultAddress: true });
    } else {
      updateFormData({ ...formData, isDefaultAddress: false });
    }
  };

  const handleError = (error: any) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.INVALID_EMAIL];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }

      setErrorMessage(errMsgs);
    }
  };

  return (
        <Box>
            <h2>{message}</h2>
          <Collapse in={!!errorMessage}>
            <Alert
              severity={"error"}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage(undefined);
                  }}
                >
                  <CloseIcon fontSize="inherit"/>
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
            <FormControl>
                <Stack direction={ "row"}>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        name={"nameOne"}
                        value={formData?.nameOne}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("header.nameTwo")}
                        name={"nameTwo"}
                        onChange={handleChange}
                        value={formData?.nameTwo}/>
                    <FormControl>
                        <InputLabel sx={{ width: 250, m: 2 }}>{t("header.addressType")}</InputLabel>
                        <Select
                            sx={{ width: 250, m: 2 }}
                            name={"addressType"}
                            type="text"
                            label={t("common.addressType")}
                            value={formData?.addressType}
                            onChange={handleChange}>
                            <MenuItem key={1} value={AddressType.BILLING_ADDRESS}>
                                {t("option.billingAddress")}
                            </MenuItem>
                            <MenuItem key={2} value={AddressType.POST_ADDRESS}>
                                {t("option.postAddress")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                  <TextField
                      sx={{ width: 250, m: 2 }}
                      label={t("common.email")}
                      type="text"
                      name={"email"}
                      onChange={handleChange}
                      value={formData?.email}/>
                    <div>
                        <FormControlLabel control={
                            <Checkbox
                                checked={formData.isDefaultAddress}
                                value={formData.isDefaultAddress}
                                onChange={handleIsDefault}
                                name={"isDefaultAddress"}
                                inputProps={{ "aria-label": "controlled" }}/>}
                                          label={t("legend.default")} />
                    </div>
                </Stack>
                <Stack direction={ "row"}>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("common.city")}
                        name={"city"}
                        type="text"
                        onChange={handleChange}
                        value={formData?.city}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("legend.zip")}
                        name={"zip"}
                        type="text"
                        onChange={handleChange}
                        value={formData?.zip}/>
                    <TextField
                        sx={{ width: 250, m: 2 }}
                        label={t("common.address")}
                        type="text"
                        name={"streetName"}
                        onChange={handleChange}
                        value={formData?.streetName}/>
                  <TextField
                      sx={{ width: 250, m: 2 }}
                      label={t("common.telephone")}
                      type="text"
                      name={"telephone"}
                      onChange={handleChange}
                      value={formData?.telephone}/>
                    <FormControl>
                        <InputLabel sx={{ width: 250, m: 2 }}>
                            Country
                        </InputLabel>
                        <Select
                            size={"small"}
                            sx={{ minWidth: 250, m: 2, py: 1 }}
                            labelId="selectCountry"
                            id="selectCountry"
                            name="country"
                            label={t("header.country")}
                            value={formData.country ? formData.country : "//"}
                            onChange={handleChange}>
                            { Countries.map((country, index) => {
                              return (
                                        <MenuItem key={index}
                                                  value={country.isoCode}>
                                            {country.name} ({country.dialCode})
                                        </MenuItem>
                              );
                            }
                            )}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} justifyContent={"right"}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        type={"submit"}
                        disabled={state?.from === StateFrom.DETAILS}
                        onClick={onFormSubmit}>
                        {t("common.save")}
                    </Button>
                    <Button sx={{ m: 1 }}
                            variant={"outlined"}
                            onClick={() => history.push("/upsert-company",
                              { search: state.search, view: state.view, from: StateFrom.DETAILS, id: state.companyId })}>
                        {t("common.back")}
                    </Button>
                </Stack>
            </FormControl>
        </Box>
  );
};
export default AddEditCompanyAddress;
