import axios from "../../../custom-axios/axios";
import { CancelTokenSource } from "axios";
import { ProjectDto, ProjectStatisticsTableDto, ProjectTableDto } from "../../../ts-types/api.types";

const baseUrl = "/company-manager-app/api/project";

export const addProject = (projectDto: Partial<ProjectDto>, source: CancelTokenSource) => {
  return axios
    .post(`${baseUrl}/add`, projectDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const getAllProjects = (source: CancelTokenSource, search?: string): Promise<Array<ProjectTableDto>> => {
  return axios
    .get(`${baseUrl}/all`, { cancelToken: source.token, params: { search } })
    .then(response => response.data);
};
export const getAllProjectsStatistics = (
  includeClosed: boolean,
  includeInactive: boolean,
  source: CancelTokenSource,
  search?: string
): Promise<Array<ProjectStatisticsTableDto>> => {
  return axios
    .get(`${baseUrl}/table`, { cancelToken: source.token, params: { search, includeClosed, includeInactive } })
    .then(response => response.data);
};
export const getAllProjectsWithTasks = (source: CancelTokenSource): Promise<Array<ProjectDto>> => {
  return axios
    .get(`${baseUrl}/all-with-tasks`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getProject = (id: number, source: CancelTokenSource): Promise<ProjectDto> => {
  return axios
    .get(`${baseUrl}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const editProject = (projectDto: Partial<ProjectDto>, id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/edit/${id}`, projectDto, { cancelToken: source.token })
    .then(response => response.data);
};

export const disableProject = (id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/disable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const enableProject = (id: number, source: CancelTokenSource) => {
  return axios
    .put(`${baseUrl}/enable/${id}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getNewProjectNumberForParticularCompany = (companyId: number, source: CancelTokenSource) : Promise <string> => {
  return axios
    .get(`${baseUrl}/number`,
      { cancelToken: source.token, params: { companyId } })
    .then(response => response.data);
};
