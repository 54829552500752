import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { StateFrom } from "../../../components/interfaces/enums";
import axios, { AxiosError } from "axios";
import { addWorktime, deleteWorkTime, editWorkTime, getWorkTime } from "../services/WorkTimeServices";
import { UpsertWorkTimeDto } from "../types";
import { useTranslation } from "react-i18next";
import YNPopper from "../../../components/Poppers/YNPopper";
import DeleteIcon from "@mui/icons-material/Delete";
import { JobTitle } from "../../../ts-types/api.enums";
import { errorUtils } from "../../../utils/errorUtils";
import CloseIcon from "@mui/icons-material/Close";

const DatePickerClass = styled.div`
  .datePicker {
    width: 250px;
  }
`;

const AddEditWorkTime = () => {
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { state } = useLocation<any>();
  const activeView: number = state?.view ? state?.view : 1;
  const activeSearch: string = state?.search ? state?.search : "";
  const activeEmployeeId: number = state?.employeeId ? state?.employeeId : 0;
  const [validFrom, setValidFrom] = React.useState<string | null>(null);
  const [validTo, setValidTo] = React.useState<string | null>(null);
  const [jobTitle, setJobTitle] = React.useState<JobTitle>(JobTitle.P1);
  const [fte, setFte] = React.useState<number>(100);
  const [message, setMessage] = React.useState<string>("");
  const [employeeId, setEmployeeId] = React.useState<number>(0);
  const [workTimeId, setWorkTimeId] = React.useState<number>(0);
  const [credits, setCredits] = React.useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    switch (state.from) {
      case StateFrom.EDIT:
        setWorkTimeId(state.id);
        getWorkTime(state.id, cancelTokenSource)
          .then(r => {
            setEmployeeId(r.employeeId);
            setJobTitle(r.jobTitle);
            setValidFrom(r.validFrom);
            setValidTo(r.validTo);
            setFte(r.fte);
            setCredits(r.credits);
          });
        setMessage(t("header.editWorkTime"));
        break;
      case StateFrom.ADD:
        setMessage(t("header.addWorkTime"));
        setEmployeeId(state.employeeId);
        setJobTitle(JobTitle.P1);
        break;
    }
  }, [state.employeeId]);

  const handleValidFromDate = (date: string) => {
    setValidFrom(moment(date).format("YYYY-MM-DD"));
  };

  const handleValidToDate = (date: string) => {
    setValidTo(moment(date).format("YYYY-MM-DD"));
  };

  const handleJobTitleChange = (event: any) => {
    setJobTitle(event.target.value);
  };

  interface CustomAxiosError extends AxiosError {
    errorCode?: string;
    violations?: Array<{ errorCode: string }>;
  }

  const handleError = (error: CustomAxiosError) => {
    if (error) {
      let errMsgs;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.INVALID_DATE, errorUtils.NAME_FIELD_IS_EMPTY];

      const violations = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsgs) {
        if (errorCode && knownErrors.includes(errorCode)) {
          errMsgs = t(`error.${errorCode}`);
        } else {
          errMsgs = t("error.general");
        }
      }
      setErrorMessage(errMsgs);
    }
  };

  const formSubmit = React.useCallback(() => {
    const newWorktime: UpsertWorkTimeDto = {
      employeeId,
      jobTitle,
      validFrom,
      validTo,
      fte,
      credits
    };
    switch (state.from) {
      case StateFrom.ADD:
        addWorktime(newWorktime, cancelTokenSource)
          .then(() => history.push("/upsert-employee", {
            search: activeSearch,
            view: activeView,
            employeeId,
            from: StateFrom.DETAILS,
            id: activeEmployeeId
          })).catch(handleError);
        break;
      case StateFrom.EDIT:
        editWorkTime(newWorktime, workTimeId, cancelTokenSource)
          .then(() => history.push("/upsert-employee", {
            search: activeSearch,
            view: activeView,
            employeeId,
            from: StateFrom.DETAILS,
            id: activeEmployeeId
          })).catch(handleError);
        break;
    }
  }, [employeeId, jobTitle, validFrom, validTo, fte, credits]);

  return (
      <>
        <h3>{message}</h3>
        <Collapse in={!!errorMessage}>
          <Alert
            severity={"error"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage(undefined);
                }}
              >
                <CloseIcon fontSize="inherit"/>
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction={"row"}>
              <Box sx={{ width: 250, m: 2 }}>
                <DatePickerClass>
                  <DesktopDatePicker
                      className="datePicker"
                      label={t("common.validFrom")}
                      inputFormat="DD/MM/YYYY"
                      value={validFrom}
                      onChange={handleValidFromDate}
                      renderInput={(params) => <TextField {...params} />}
                  />
                </DatePickerClass>
              </Box>
              <Box sx={{ width: 250, m: 2 }}>
                <DatePickerClass>
                  <DesktopDatePicker
                      className="datePicker"
                      label={t("common.validTo")}
                      inputFormat="DD/MM/YYYY"
                      value={validTo}
                      onChange={handleValidToDate}
                      renderInput={(params) => <TextField {...params} />}
                  />
                </DatePickerClass>
              </Box>
            </Stack>
          </LocalizationProvider>
          <Stack direction={"row"}>
            <FormControl>
              <InputLabel sx={{ width: 250, m: 2 }}>Job Title</InputLabel>
              <Select
                  sx={{ width: 250, m: 2 }}
                  name={"civilStatus"}
                  type="text"
                  label={t("common.jobTitle")}
                  value={jobTitle}
                  onChange={handleJobTitleChange}>
                <MenuItem key={1} value={JobTitle.P1}>
                  Professional 1
                </MenuItem>
                <MenuItem key={2} value={JobTitle.P2}>
                  Professional 2
                </MenuItem>
                <MenuItem key={3} value={JobTitle.M1}>
                  Medior 1
                </MenuItem>
                <MenuItem key={4} value={JobTitle.M2}>
                  Medior 2
                </MenuItem>
                <MenuItem key={5} value={JobTitle.S1}>
                  Senior 1
                </MenuItem>
                <MenuItem key={6} value={JobTitle.S2}>
                  Senior 2
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
                sx={{ width: 250, m: 2 }}
                fullWidth={true}
                type={"number"}
                name={"fte"}
                value={fte}
                label={t("common.fte")}
                onChange={(e) => setFte(parseInt(e.target.value))}
            />
            <TextField
                sx={{ width: 250, m: 2 }}
                fullWidth={true}
                type={"number"}
                name={"credits"}
                value={credits}
                label={t("common.credits")}
                onChange={(e) => setCredits(parseInt(e.target.value))}
            />
          </Stack>
          <Stack direction={"row"} justifyContent={"right"}>
            <Button
                sx={{ m: 1 }}
                type={"button"}
                variant={"outlined"}
                onClick={formSubmit}>
              {t("common.save")}
            </Button>
            <Box sx={{ m: 1 }}>
              {state.from !== StateFrom.ADD
                ? <YNPopper
                      message={t("common.delete")}
                      color={"error"}
                      variant={"contained"}
                      icon={<DeleteIcon/>}
                      disabled={state.role === "ROLE_EMPLOYEE" || state.from === StateFrom.DETAILS}
                      onConfirm={() => {
                        deleteWorkTime(state.id, cancelTokenSource)
                          .then(() => history.push("/upsert-employee", { id: employeeId, from: StateFrom.DETAILS }));
                      }}/>
                : <> </>}
            </Box>
            <Button
                sx={{ m: 1 }}
                type={"button"}
                variant={"outlined"}
                onClick={() => history.push("/upsert-employee",
                  { search: activeSearch, view: activeView, employeeId, from: StateFrom.DETAILS, id: activeEmployeeId })}>
              {t("common.back")}
            </Button>
          </Stack>
        </FormControl>
      </>
  );
};
export default AddEditWorkTime;
