import React, { ChangeEvent, FC } from "react";
import axios from "axios";
import { Box, Button, CircularProgress, FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { AdminProps } from "../../components/interfaces/interface";
import { useAuthContext } from "../../context/AuthContext";
import { disablePerson, enablePerson, getAllPersonsTable } from "./services/PersonServices";
import { StateFrom, Role } from "../../components/interfaces/enums";
import YNPopper from "../../components/Poppers/YNPopper";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import { useTranslation } from "react-i18next";
import { emptyTableCell } from "../../utils/tableUtils";
import DoneIconComponent from "../../components/StyleComponents/DoneIconComponent";
import { PersonTableDto } from "../../ts-types/api.types";
import classNames from "classnames";
import { Check } from "@mui/icons-material";

const PersonPage: FC<AdminProps> = (props): JSX.Element => {
  const [persons, setPersons] = React.useState<Array<PersonTableDto>>([]);
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const history = useHistory();
  const { user } = useAuthContext();
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadAllPersons(false);
  }, []);

  const loadAllPersons = React.useCallback((includeInactive: boolean) => {
    setDataLoaded(false);
    getAllPersonsTable(search, includeInactive, cancelTokenSource)
      .then((data) => {
        setPersons(data);
      })
      .finally(() => setDataLoaded(true));
  }, [search, cancelTokenSource, persons]);

  const disPerson = (id: number) => {
    disablePerson(id, cancelTokenSource)
      .then(() => loadAllPersons(includeInactive));
  };

  const enaPerson = (id: number) => {
    enablePerson(id, cancelTokenSource)
      .then(() => loadAllPersons(includeInactive));
  };

  const handleIncludeInactive = (event: any, checked: boolean) => {
    setIncludeInactive(checked);
    loadAllPersons(checked);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchText = event.target.value;
    setSearch(searchText);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = setTimeout(() => {
      loadAllPersons(includeInactive);
    }, 1000);

    setTypingTimeout(timeoutId);
  };

  const renderPersonTable = (): JSX.Element => {
    const actions = ({ rowData }: any) => {
      const personId = rowData.id;
      return (
        <Box width={400}>
          <Button
            sx={{ m: 1 }}
            variant={"outlined"}
            onClick={() =>
              history.push("/upsert-person",
                {
                  id: personId,
                  view: props.view,
                  search: props.search,
                  from: StateFrom.DETAILS
                })}>
            {t("common.details")}
          </Button>
          <Button
            sx={{ m: 1 }}
            variant={"outlined"}
            disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
            onClick={() =>
              history.push("/upsert-person",
                {
                  id: personId,
                  view: props.view,
                  search: props.search,
                  from: StateFrom.EDIT
                })}>
            {t("common.edit")}
          </Button>
          {rowData.active
            ? <YNPopper
              message={t("common.disable")}
              color={"error"}
              variant={"contained"}
              icon={<DeleteIcon/>}
              disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
              onConfirm={() => disPerson(rowData.id)}/>
            : <YNPopper
              message={t("common.enable")}
              color={"success"}
              variant={"contained"}
              icon={<Check/>}
              disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
              onConfirm={() => enaPerson(rowData.id)}/>
          }
        </Box>
      );
    };

    const personsRowGetter = ({ index }: any) => {
      Object.assign(persons[index], { index: index + 1 });

      return persons[index];
    };

    const personsRowRenderer = ({ className, columns, index, key, style, rowData }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };
      const isInactive = rowData.active === false;
      const rowClasses = classNames(className, {
        inactiveRow: isInactive
      });
      return (
                <div
                    {...a11yProps}
                    className={rowClasses}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };

    const statusCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <DoneIconComponent/>;
      }
      return emptyTableCell();
    };

    return (
            <VirtualizedTable
                rowCount={persons.length}
                rowGetter={personsRowGetter}
                rowRenderer={personsRowRenderer}
                columns={[
                  {
                    width: 75,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 250,
                    flexGrow: 1,
                    label: t("common.firstName"),
                    dataKey: "firstName"
                  },
                  {
                    width: 250,
                    flexGrow: 1,
                    label: t("common.lastName"),
                    dataKey: "lastName"
                  },
                  {
                    width: 350,
                    flexGrow: 1,
                    label: t("common.email"),
                    dataKey: "email"
                  },
                  {
                    width: 250,
                    flexGrow: 1,
                    label: t("common.mobile"),
                    dataKey: "mobile"
                  },
                  {
                    width: 100,
                    label: t("common.hasUser"),
                    dataKey: "userId",
                    cellRenderer: statusCellRenderer
                  },
                  {
                    width: 100,
                    label: t("option.employee"),
                    dataKey: "employeeId",
                    cellRenderer: statusCellRenderer
                  },
                  {
                    width: 330,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />

    );
  };

  const rowHeight = 48;
  const tableHeight = (persons.length * rowHeight) + 25;
  return (
        <Box>

            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3><i>{t("common.persons")}</i></h3>
                <Stack alignSelf={"center"} direction={"row"}>
                  <TextField
                    sx={{ marginX: 5, width: "300px" }}
                    label={t("common.search")}
                    id="outlined-size-small"
                    size="small"
                    onChange={handleSearchChange}
                  />
                  <FormControlLabel
                    control={<Switch onChange={(e, check) => handleIncludeInactive(e, check)} checked={includeInactive}/>}
                    label={t("common.inactive")} />
                    <Button
                        variant={"contained"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-person", { search: props.search, view: props.view, from: StateFrom.ADD })}>
                      {t("button.addPerson")}
                    </Button>
                </Stack>
            </Stack>
          {dataLoaded
            ? <>
            <Box style={{ height: tableHeight }}>
            {renderPersonTable()}
            </Box>
                </>
            : <CircularProgress />
            }
        </Box>
  );
};
export default PersonPage;
