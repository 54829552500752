import { Identifiable } from "../components/interfaces/interface";

export function keyById<Key, Value extends Identifiable<Key>> (items: Array<Value>): Map<Key, Value> {
  const map: Map<Key, Value> = new Map();

  items.forEach((item: Value) => {
    map.set(item.id, item);
  });

  return map;
}
