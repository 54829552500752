import React, { FC } from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import VirtualizedTable from "../../../components/VirtualizedTable/VirtualizedTable";
import { getAllEmployeesForSpecificTeamLeader } from "../services/EmployeeServices";
import { StateFrom } from "../../../components/interfaces/enums";
import { EmployeeTableDto } from "../../../ts-types/api.types";

interface Props {
    employeeId: number;
    view: string;
    search: string;
    from: string;
    personId?: number;
}

const EmployeeWorkTimeTable: FC<Props> = (props): JSX.Element => {
  const [employees, setEmployees] = React.useState<Array<EmployeeTableDto>>([]);

  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation("company-manager");

  React.useEffect(() => {
    loadEmployees();
  }, [props.search]);

  const loadEmployees = React.useCallback(async () => {
    setDataLoaded(false);

    try {
      await getAllEmployeesForSpecificTeamLeader(props.employeeId, cancelTokenSource)
        .then(r => {
          setEmployees(r);
        });
    } finally {
      setDataLoaded(true);
    }
  }, [props.search, employees, cancelTokenSource]);

  const renderEmployeeTable = (): JSX.Element => {
    const actions = ({ rowData }: any) => {
      return (
                <>
                    <Button
                        sx={{ my: 1, mr: 1 }}
                        variant={"outlined"}
                        type={"submit"}
                        onClick={() => {
                          history.push("/upsert-employee",
                            {
                              id: rowData.id,
                              view: props.view,
                              search: props.search,
                              from: StateFrom.DETAILS
                            });
                          window.location.reload();
                        }
                    }>
                        {t("common.details")}
                    </Button>
                </>
      );
    };

    const employeesRowGetter = ({ index }: any) => {
      Object.assign(employees[index], { index: index + 1 });

      return employees[index];
    };

    const employeesRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={className}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };
    return (
            <VirtualizedTable
                rowCount={employees.length}
                rowGetter={employeesRowGetter}
                rowRenderer={employeesRowRenderer}
                columns={[
                  {
                    width: 75,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.shortName"),
                    dataKey: "shortName"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.firstName"),
                    dataKey: "firstName"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.lastName"),
                    dataKey: "lastName"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.civilStatus"),
                    dataKey: "civilStatus"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.dateOfBirth"),
                    dataKey: "dateOfBirth"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.dateOfEntry"),
                    dataKey: "dateOfEntry"
                  },
                  {
                    width: 100,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const maxTableHeight = (10 * rowHeight) + 25;
  const tableHeight = Math.min((employees.length * rowHeight) + 25, maxTableHeight);
  return (
        <Box>
            {dataLoaded
              ? <>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                        <h3><i>{t("common.employees")}</i></h3>
                    </Stack>
                    <Box height={tableHeight}>
                        {renderEmployeeTable()}
                    </Box>
                </>
              : <CircularProgress />
            }
        </Box>
  );
};

export default EmployeeWorkTimeTable;
