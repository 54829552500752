import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Box, Button, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { StateFrom, Role } from "../../components/interfaces/enums";
import { AdminProps } from "../../components/interfaces/interface";
import VirtualizedTable from "../../components/VirtualizedTable/VirtualizedTable";
import YNPopper from "../../components/Poppers/YNPopper";
import { disableCompany, enableCompany, getAllCompaniesTable } from "./services/CompanyServices";
import { useAuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { CompanyTableDto } from "../../ts-types/api.types";
import classNames from "classnames";
import { Check } from "@mui/icons-material";

const CompanyPage: FC<AdminProps> = (props): JSX.Element => {
  const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
  const [companies, setCompanies] = React.useState<Array<CompanyTableDto>>([]);

  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation("company-manager");
  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

  React.useEffect(() => {
    loadCompanies(false);
  }, [props.search]);

  const loadCompanies = React.useCallback((includeInactive: boolean) => {
    getAllCompaniesTable(includeInactive, props.search, cancelTokenSource)
      .then(r => setCompanies(r));
  }, [companies, cancelTokenSource, props.search]);

  const disCompany = (id: number) => {
    disableCompany(id, cancelTokenSource)
      .then(() => loadCompanies(includeInactive));
  };

  const enaCompany = (id: number) => {
    enableCompany(id, cancelTokenSource)
      .then(() => loadCompanies(includeInactive));
  };

  const handleIncludeInactive = (event: any, checked: boolean) => {
    setIncludeInactive(checked);
    loadCompanies(checked);
  };

  const renderCompanyTable = (): JSX.Element => {
    const actions = ({ rowData }: any) => {
      const companyId = rowData.id;
      return (
                <Box width={320}>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        onClick={() =>
                          history.push("/upsert-company",
                            { id: companyId, view: props.view, search: props.search, from: StateFrom.DETAILS })}>
                      {t("common.details")}
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant={"outlined"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-company",
                            { id: companyId, view: props.view, search: props.search, from: StateFrom.EDIT })}>
                      {t("common.edit")}
                    </Button>
                  {rowData.active
                    ? <YNPopper
                      message={t("common.disable")}
                      color={"error"}
                      variant={"contained"}
                      icon={<DeleteIcon/>}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onConfirm={() => disCompany(rowData.id)}/>
                    : <YNPopper
                      message={t("common.enable")}
                      color={"success"}
                      variant={"contained"}
                      icon={<Check/>}
                      disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                      onConfirm={() => enaCompany(rowData.id)}/>
                  }
                </Box>
      );
    };

    const companiesRowGetter = ({ index }: any) => {
      Object.assign(companies[index], { index: index + 1 });

      return companies[index];
    };

    const companiesRowRenderer = ({ className, columns, index, key, style, rowData }: any) => {
      const isInactive = rowData.active === false;
      const rowClasses = classNames(className, {
        inactiveRow: isInactive
      });
      const a11yProps = { "aria-rowindex": index + 1 };

      return (
                <div
                    {...a11yProps}
                    className={rowClasses}
                    key={key}
                    role='row'
                    style={style}
                >
                    {columns}
                </div>
      );
    };
    const HeaderWithTooltip = ({ label, tooltip } : any) => {
      return (
              <div>
                  {
                      <Tooltip title={t(label)} placement="top" componentsProps={{
                        tooltip: {
                          sx: {
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "0"
                          }
                        }
                      }}>
                          <span data-tip={tooltip}>#{t(label + "Tooltip")}</span>
                      </Tooltip>
                  }
              </div>
      );
    };

    return (
            <VirtualizedTable
                rowCount={companies.length}
                rowGetter={companiesRowGetter}
                rowRenderer={companiesRowRenderer}
                columns={[
                  {
                    width: 75,
                    label: "#",
                    dataKey: "index"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.name"),
                    dataKey: "name"
                  },
                  {
                    width: 200,
                    flexGrow: 1,
                    label: t("common.shortName"),
                    dataKey: "shortName"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.phoneOffice"),
                    dataKey: "phoneOffice"
                  },
                  {
                    width: 300,
                    flexGrow: 1,
                    label: t("common.email"),
                    dataKey: "email"
                  },
                  {
                    width: 80,
                    label: "header.companyProjects",
                    dataKey: "numOfProjects",
                    headerRenderer: (HeaderWithTooltip)
                  },
                  {
                    width: 80,
                    label: "header.companyPersons",
                    dataKey: "numOfPersons",
                    headerRenderer: (HeaderWithTooltip)
                  },
                  {
                    width: 80,
                    label: "header.companyBanks",
                    dataKey: "numOfBanks",
                    headerRenderer: (HeaderWithTooltip)
                  },
                  {
                    width: 80,
                    label: "header.companyAddresses",
                    dataKey: "numOfAddresses",
                    headerRenderer: (HeaderWithTooltip)
                  },
                  {
                    width: 350,
                    label: t("common.actions"),
                    dataKey: "id",
                    cellRenderer: (actions)
                  }
                ]}
            />
    );
  };

  const rowHeight = 48;
  const tableHeight = (companies.length * rowHeight) + 25;

  return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
                <h3><i>{t("common.companies")}</i></h3>
                <Stack alignSelf={"center"} direction={"row"}>
                  <FormControlLabel
                    control={<Switch onChange={(e, check) => handleIncludeInactive(e, check)} checked={includeInactive}/>}
                    label={t("common.inactive")} />
                    <Button
                        variant={"contained"}
                        disabled={user?.roles?.includes(Role.ROLE_EMPLOYEE)}
                        onClick={() =>
                          history.push("/upsert-company",
                            { search: props.search, view: props.view, from: StateFrom.ADD })}>
                      {t("button.addCompany")}
                    </Button>
                </Stack>
            </Stack>
            <Box style={{ height: tableHeight }}>
                {renderCompanyTable()}
            </Box>
        </Box>
  );
};
export default CompanyPage;
